/*=============================================
=            Feature Hero Item Full            =
=============================================*/

.feature-hero {
  height: 538px;

  @include media-breakpoint-down(md) {
    height: 650px;
  }

  &__item-full {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 553px;

    @include media-breakpoint-down(md) {
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      min-height: 517px;
    }

    &__content {
      width: 100%;
      position: absolute;
      padding-inline-start: 26px;
    }

    &-left {
      height: 100%;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      
      @include media-breakpoint-down(md) {
        flex: 0;
  
        .link {
          display: none;
        }
      }

      h1 {
        font-size: 64px;
        font-weight: 400;
        line-height: 73px;
        margin-bottom: 26px;
        font-family: Neue Haas Grotesk Display Pro;
        text-transform: uppercase;
    
        @include media-breakpoint-down(xl) {
          font-size: 39px;
          line-height: 39px;
        }
      }

      h2 {
        font-size: 64px;
        font-weight: 400;
        line-height: 73px;
        margin-bottom: 26px;
        font-family: Neue Haas Grotesk Display Pro;
        text-transform: uppercase;
    
        @include media-breakpoint-down(xl) {
          font-size: 39px;
          line-height: 39px;
        }
      }
    }
  }

  .rte p {
    @include media-breakpoint-down(lg) {
      font-size: 39px;
      line-height: 39px;
    }
  }

  .link--secondary-large-white {
    width: fit-content;
  }
}

html[dir="rtl"] {
  .feature-hero__item-full-left h1 {
    font-family: $font-family--ar;
    font-size: 77px;
    font-weight: 500;
    line-height: 68px;

    @include media-breakpoint-down(xl) {
      font-size: 56px;
      font-weight: 500;
      line-height: 58px;
    }
  }

  .feature-hero__item-full-left h2 {
    font-family: $font-family--ar;
    font-size: 77px;
    font-weight: 500;
    line-height: 68px;

    @include media-breakpoint-down(xl) {
      font-size: 56px;
      font-weight: 500;
      line-height: 58px;
    }
  }

  .link--secondary-large-white {
    margin-block-start: 80px;
  }
}



/*=====  End of Feature Hero  ======*/

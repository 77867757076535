/*=============================================
=            Location Item            =
=============================================*/

.location {

  label.location__label {
    position: static;
  }

  select.location__select {
    padding: 14px;
    border-radius: 0;
    background-size: 19px;
    background-position-x: calc($container-width--wide - 13px);
    line-height: 1.4;
  }

  &__map {
    &-container {
      margin: 25px 0;
    }
  }

  &__title {
    color: $text--secondary;
    font-size: 17px;
    font-weight: 600;
    line-height: 48px;
    margin-top: 16.5px;
  }

  &__address, &__phone, &__times {
    width: 100%;
    gap: 12px;
    color: $text--primary;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
  }

  &__icon {
    height: 24px;
    width: 24px;
  }

}

html[dir="rtl"] {
  .location select.location__select {
    background-position-x: 20px;
    font-family: $font-family--ar;
  }
}


/*=====  End of Location Item  ======*/

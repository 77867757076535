/*=============================================
=            Heading Component            =
=============================================*/

.heading-component {
  padding-block: 166px 125px;

  @include media-breakpoint-down(xl) {
    padding-inline: 24px;
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding-block: 34px 45px;
    gap: 66px;
  }

  &__title {
    font-size: 54px;
    font-weight: 700;
    line-height: 70px;
    margin-bottom: 32px;

    &-blue {
      color: $text--secondary;
    }

    @include media-breakpoint-down(lg) {
      text-align: center;
      font-size: 28px;
      line-height: 33.6px;
      margin-bottom: 5px;
    }

    @include rtl {
      font-family: "Din", sans-serif;
      font-weight: 500;

      @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 35px;
        font-weight: 700;
      }
    }
  }

  &__text {
    color: #2c2c2e;
    font-size: 24px;
    line-height: 28.8px;
    max-width: 464px;
    margin-bottom: 54px;

    @include media-breakpoint-down(lg) {
      text-align: center;
      font-size: 16px;
      line-height: 19.2px;
      margin-bottom: 23px;
    }

    @include rtl {
      font-family: "Din", sans-serif;
      font-size: 22px;
      line-height: 32.34px;

      @include media-breakpoint-down(lg) {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }

  &__left {
    flex: 1;

    @include media-breakpoint-down(lg) {
      align-items: center;
    }
  }

  &__right {
    flex: 0.8;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: contain;

    @include media-breakpoint-down(lg) {
      max-width: 351.92px;
      margin: auto;
    }

    &-mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &-desktop {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  &__stores {
    gap: 31.82px;
    margin-bottom: 24.7px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 17.4px;
      gap: 10px;
    }
  }

  &__store {
    height: 68.35px;

    @include transition;

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    @include media-breakpoint-down(lg) {
      height: 42.58px;
    }
  }

  &__stats {
    gap: 57px;
  }

  &__stat {
    &-title {
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      color: #2c2c2e;

      @include media-breakpoint-down(lg) {
        font-size: 28px;
        line-height: 33.6px;
      }
    }

    &-text {
      font-size: 16px;
      line-height: 19.2px;
      color: #91969c;

      @include media-breakpoint-down(lg) {
        font-size: 14px;
        line-height: 16.8px;
      }

      @include rtl {
        font-family: "Din", sans-serif;
        line-height: 23.52px;

        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 20.58px;
        }
      }
    }
  }
}

/*=====  End of Heading Component  ======*/

/*=============================================
=            Feedback Form            =
=============================================*/

.feedback {
  padding: 56px 26px 56px;

  @include media-breakpoint-down(md) {
    padding: 32px 26px;
  }

  &-wrapper {
    background-color: #F7F9FB;
  }

  &__inner {
    flex-direction: row-reverse;
    gap: 48px;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }

  .rte {
    p{
      padding-bottom: 24px;
    }
    p:last-child{
      padding-bottom: 0;
    }
  }

  &__heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.5px;
    color: $text--secondary;
    padding-bottom: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
      font-size: 25px;
      font-weight: 500;
      line-height: 29px;
    }
  }

  &__overlay {
    &__failure {
      width: 100%;
      padding: 15px 24px;
      display: flex;
      align-items: center;
      border: 1.5px solid var(--secondary-500, #F55C5C);
      border-radius: 12px;
      background: #FFF5F5;

      &-image {
        width: 34px;
        height: 34px;
        margin-right: 14px;
      }

      &-text {
        color: var(--secondary-600, #E54E4E);
        font-family: $font-family--base;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &__success {
      z-index: 99999;
      position: absolute;
      padding: 50px;
      inset-inline-start: 0;
      inset-block-start: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.50);
      backdrop-filter: blur(10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 13px;
      grid-area: 14px;
      grid-area: 26px;
  
      &-cross {
        width: 20px;
        position: absolute;
        inset-inline-end: 35px;
        inset-block-start: 25px;
        cursor:pointer;
      }
  
      &-image {
        width: 150px;
      }
  
      &-title {
        color: #35475E;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
      }
  
      &-text {
        color: $primary;
        max-width: 450px;
        text-align: center;
        font-family: Neue Haas Grotesk Display Pro;
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;
      }
    }
    
  }

  &__right {
    min-height: 240px;
    flex: 1;
    position: relative;
    height: fit-content;
  }

  &__left {
    color: $text--primary;
    flex: 0.8;

    &-title {
      font-size: 29px;
      font-weight: 500;
      line-height: 41px;
      margin-bottom: 4px;

      @include media-breakpoint-down(sm) {
        color: $text--secondary;
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.5px;
    }

    a {
      text-underline-offset: 5px;
      text-decoration: underline;
    }
  }

  &__list {
    gap: 24px;
    margin-top: 28px;
    padding-bottom: 24px;
    
    ol,ul {
      list-style: auto;
    }

    &-item {
      font-style: normal;
      letter-spacing: 0.5px;
      padding-inline: 5px;
    }

    &-index {
      line-height: 25px !important;
    }

    &-content {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 25px !important;
    }
  }

  &__caption {
    margin-top: 20px;
  }

  // umbraco form styles
  .umbraco-forms-container {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .umbraco-forms-page {
    flex:1;
    padding: 56px 32px;
    background-color: $bg--white;

    @include media-breakpoint-down(md) {
      margin-right: 0;
      padding: 14px 20px;
    }
  }

  legend {
    margin-block-end: 14px;
  }

  label {    
    color: #808082;
    font-family: $font-family--base;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    position: absolute;
    inset-block-start: 14px;
    inset-inline-start: 20px;
  }

  .form-group {
    position: relative;
  }

  input[type="text"], input[type="email"], select, textarea {
    border-radius: 12px;
    border: 1px solid #CFCFCF;
    padding: 14px 20px;
    padding-top: 33px;
    width: 100%;
    color: #808082;
    font-family: $font-family--base;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    resize: none;
  }

  input[type="submit"] {
    padding: 18px 54px;
    background-color: $bg--secondary;
    color: $text--white;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
    margin-top: 24px;

    @include transition;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:hover, &:focus {
      background-color: $bg--primary;
    }
  }

  select {
    width: 100%; 
    position: relative;
    -webkit-appearance: none;
    background-image: url('/assets/img/img/chevron-down-active.svg');
    background-position-x: calc($container-width--wide - 20px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 24px;
    z-index: 9999;
    cursor: pointer;
    padding-inline-end: 50px;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
  }

  ::placeholder { 
    font-family: $font-family--base;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  :-ms-input-placeholder {
    font-family: $font-family--base;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  ::-ms-input-placeholder { 
    font-family: $font-family--base;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .umbraco-forms-form {
    flex: 1;
  }

  .singlechoice label {
    position: relative;
    inset-block-start: unset; 
    inset-inline-start: unset;
  }

  .singlechoice .radiobuttonlist {
    display: flex;
    gap: 5px;
  }

  .phone-number-xl {
    color: #0070DF;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; 
    letter-spacing: 2px;
  
    &:before {
      content: url('/assets/img/img/phone-icon-2.svg');
      margin-right: 10px;
      vertical-align: middle;
      display: inline-block;
    }
    
    html[dir="rtl"] &::before {
      margin-right: -25px;
      margin-left: 10px;
      transform: rotate(245deg);
    }
  }

  .field-validation-error
  {
    color: #E54E4E;
      margin-top: 11px;
      display: block;
  }

  .form-control.input-validation-error
  {
      border-color: #E54E4E;
  }
}

html[dir="rtl"] {
  .feedback {
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    select,
    textarea {
      font-family: $font-family--ar;
      font-size: 14px;
    }

    ::placeholder {
      font-family: $font-family--ar;
      font-size: 14px;
    }

    input[type="text"] {
      font-weight: 700;
    }
    
    input[type="submit"] {
      font-family: $font-family--ar;
      font-weight: 700;
      line-height: 22px;
    }
  }
}

/*=====  End of Feedback Form  ======*/

html[dir="rtl"] {
  .form-group {
    label {
      font-family: 'Din', sans-serif;
    }

    input {
      direction: rtl;
    }
  }
}

.field-validation-error {
  color: #E54E4E;
  margin-top: 11px;
  display: block;
}

.form-control.input-validation-error {
  border-color: #E54E4E;
}
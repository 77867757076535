/*=============================================
=            Reports Component            =
=============================================*/

.reports-component {
  background-color: #F7F9FB;

  &__inner {
    margin: 0 auto;
    padding-block: 56px;
    gap: 24px;
  }

  &__tabs {
    gap: 10px;
    box-sizing: border-box;
    border: 1px solid #E4EBF2;
    border-radius: 6px;
    width: fit-content;
    padding: 4px;
    background-color: $bg--white;
    overflow: auto;
    margin-bottom: 24px;

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
    }
  }

  &__label {
    cursor: pointer;
    min-width: fit-content;
    transition: background-color, border-color 0.2s ease-in-out ;

    &:focus {
      border-color: $black;
    }
  }

  &__radio {
    width: 0; 
    height: 0; 
    position: absolute; 
    left: -9999px;
  }

  &__radio + &__label {
    margin: 0; 
    padding: 17px 55px; 
    box-sizing: border-box;
    position: relative; 
    display: inline-block;
    border-radius: 6px;
    font-size: 17px;
    font-weight: 500;
    line-height: 25px; 
    letter-spacing: 0.5px;
    border: 1px solid transparent;

    @include media-breakpoint-down(md) {
      font-size: 13px;
      line-height: 25px;
      padding: 7.5px 32px; 
    }

    @include media-breakpoint-down(md) {
      padding: 7.5px 24px; 
    }
  }

  &__radio:hover + &__label {
    border-color: $secondary;    
  }

  &__radio:checked + &__label {
    background-color: $bg--secondary;
    color: #FFF;
    z-index: 1;
    font-size: 17px;
    font-weight: 600;
    line-height: 25px; 
    letter-spacing: 0.5px;

    @include media-breakpoint-down(md) {
      font-size: 13px;
    }
  }

  &__cards {
    display: none;
    gap: 25px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
      gap: 14px;
    }
  }

  &__header {
    display: none;
    gap: 22px;

    @include media-breakpoint-down(md) {
      gap: 10px;
    }
  }

  &__text {
    font-family: $font-family--alt;
    color: $text--primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.5px;
    
    @include media-breakpoint-down(sm) {
      font-family: $font-family--base;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.5px;
    }
  }

  &__heading {
    color: $text--secondary;
    font-size: 26px;
    font-weight: 500;
    line-height: 35px; 
    letter-spacing: 0.5px;

    @include media-breakpoint-down(sm) {
      font-family: $font-family--base;
      color: $text--secondary;
    }
  }

  &__accordion {
    display: none;
    width: 100%;

    &-item {
      width: 100%;
      padding: 12px 24px;
      border: 1px solid transparent;

      @include transition;
      
      &:hover, &:focus {
        border-color: $secondary;
      }

      &:nth-child(odd){
        background-color: #F7F9FB;
      }
    }
  }

  &__close {
    position: absolute;
    inset-inline-end: 32px;
    inset-block-start: 32px;
    display: none;
    cursor: pointer;
    z-index: 2;

    &:hover, &:focus {
      .reports-component__close-icon{
        color: $text--secondary;
      }
    }

    @include media-breakpoint-down(sm) {
      inset-inline-end: 21px;
      inset-block-start: 19px;
    }

    &-icon {
      font-size: 28px;
      color: #808082;

      @include transition;
    }
  }

  &__card {
    position: relative;
    background-color: $bg--white;
    padding: 48px 103px 38px 84px;
    gap: 24px;
    cursor: pointer;

    &:hover, &:focus {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transform: scale(1.01);
    }

    &-heading {
      font-family: $font-family--alt;
      font-size: 17px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-align: center;
      color: $text--primary;

    @include media-breakpoint-down(sm) {
      font-family: $font-family--base;
      font-size: 17px;
      font-weight: 500;
      line-height: 25px; 
      letter-spacing: 0.5px;
    }
    }

    &--active {
      padding: 32px;
      grid-column: span 3;
      cursor: default;

      @include media-breakpoint-down(lg) {
        grid-column: span 2;
      }

      @include media-breakpoint-down(md) {
        grid-column: span 1;
      }

      .reports-component__accordion {
        display: block;
      }

      .reports-component__close {
        display: block;
      }

      .reports-component__card-heading {
        font-family: $font-family--base;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;
        margin-inline-end: auto;
      }
    }
  }

  &__link {
    color: $text--primary;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px; 
    letter-spacing: 0.5px;
    width: 100%;
    height: 100%;
    flex: 1;
    gap: 4px;
  }

  &__icon {
    height: 24px;
    width: 24px;
  }

  &__download {
    color: $text--secondary;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.5px;
    cursor: pointer;
    margin-inline-start: auto;

    &-text {
      
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

html[dir="rtl"] {
  .reports-component__card--active,
  .reports-component__card-heading,
  .reports-component__text {
    font-family: $font-family--ar;
    line-height: 33px;
  }
}

/*=====  End of Reports Component  ======*/

/*=============================================
=            Footer            =
=============================================*/

.footer {
  font-family: 'Lato', sans-serif;
  background-color: #222F44;
  color: $text--white;

  &__inner {
    margin: 0 auto;
    max-width: 1178px;
    padding: 53px 0 47px;
    width: 100%;
    gap: 30px;

    @include media-breakpoint-down(md) {
      padding: 34px 0 36px;
      flex-direction: column;
      gap: 28px;
    }
  }

  &__logo {
    &-container {
      width: 137px;
      margin-bottom: 22.5px;

      @include media-breakpoint-down(md) {
        margin: auto;
      }
    }
  }

  &__variable {
    margin-block: 23px 15px;

    &-bottom {
      margin-block: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      &-image {
        width: 20px;
        height: 20px;
      }

      &-label {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }

    &-label {
      color: #FFF;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      position: relative;
      padding-bottom: 5px;

      &::after {
        content: "";
        position: absolute;
        inset-inline-start: 0;
        inset-block-end: -3px;
        height: 5px;
        width: 46px;
        background-color: #546279;
        border-radius: 110px;
      }
    }
  }

  &__column {
    padding: 0 26px;
    display: flex;
    flex-direction: column;

    &:nth-of-type(3) {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &:nth-of-type(4) {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &-accordion {
    padding: 0;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &__item {
      position: relative;

      &:last-child {
        .footer-accordion__item-top {
          border-bottom: 1px solid #3A4557;
        }
      }

      &-inner {
        display: none;
        padding: 10px 26px;

        &--active {
          display: block;
        }
      }

      &-top {
        position: relative;
        width: 100%;
        cursor: pointer;
        border-top: 1px solid #3A4557;

        &--active {
          border-bottom: 1px solid #3A4557;
        }
      }

      &-title {
        position: relative;
        width: 100%;
        padding: 0 26px;
        font-size: 15px;
        font-weight: 500;
        line-height: 48px;

        &::after {
          content: '+';
          position: absolute;
          right: 26px;
          top: 50%;
          transform: translateY(-50%);
          color: #6B798F;
          font-size: 30px;
        }

        &--active {
          &::after {
            content: '-';
          }
        }
      }
    }
  }

  &__subtitle {
    padding-bottom: 5px;
    margin-bottom: 19px;
    font-size: 17px;
    font-weight: 600;
    line-height: 48px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      inset-inline-start: 0;
      inset-block-end: -3px;
      height: 5px;
      width: 46px;
      background-color: #546279;
      border-radius: 110px;
    }

    @include media-breakpoint-down(md) {
      font-size: 15px;
      font-weight: 500;
      line-height: 48px;
    }
  }

  &__caption {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    margin-block-end: 17px;
  }

  &__stores {
    gap: 15px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__store {
    width: 152px;
  }

  &__phone {
    font-family: $font-family--base;
    font-size: 26px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 2px;
    border-bottom: 1px solid transparent;

    @include transition;

    &:hover,
    &:focus {
      border-color: $white;
    }
  }

  &__times {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    opacity: 0.8;
  }

  .rte a,
  .rte p {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    opacity: 0.8;
  }

  .rte a {
    @include transition;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &__times {
    margin-bottom: 30px;
  }

  &__links {
    font-size: 15px;
    font-weight: 400;
    line-height: 37px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__link {
    display: block;
    opacity: 0.8;
    width: 100%;

    @include transition;

    &:hover,
    &:focus {
      opacity: 1;
    }

    @include media-breakpoint-down(md) {
      font-size: 13px;
      font-weight: 400;
      line-height: 35px;
    }
  }

  &__bottom {
    background-color: #141C28;
    padding: 11px 0;

    @include media-breakpoint-down(md) {
      padding: 8px 0;
    }

    &-inner {
      margin: 0 auto;
      max-width: 1178px;
      padding: 0 26px 0;
      width: 100%;

      @include media-breakpoint-down(md) {
        justify-content: space-between;
      }
    }

    &-left {
      font-size: 13px;
      font-weight: 400;
      line-height: 48px;
    }

    &-right {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__socials {
    gap: 18px;

    &-text {
      font-size: 13px;
      font-weight: 400;
      line-height: 48px;
      margin-inline-end: 11px;
    }
  }

  &__mobile-socials,
  &__mobile-stores {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__mobile-socials {
    flex-wrap: wrap;
  }

  &__mobile-stores-wrapper {
    gap: 17px;
    flex-wrap: wrap;
    justify-content: initial;
  }

  &__social {
    @include transition;

    &:hover,
    &:focus {
      transform: scale(1.10);
    }

    &-icon {
      height: 100%;
      width: 100%;
      height: 23px;
    }
  }

  &__end {
    width: 100%;
    background-color: $bg--off-grey;
    color: $text--black;

    &-inner {
      margin: 0 auto;
      max-width: 1178px;
      padding: 21px 26px 19px;
      width: 100%;
      gap: 71px;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        gap: 30px;
      }

      .rte {
        p {
          font-size: 12px;
          line-height: 14.4px;
        }
      }
    }

    &-left, &-right {
      flex: 1;
    }

    &-left[dir="rtl"] {
      line-height: 17.64px;
      font-family: $font-family--ar;
    }

    &-right[dir="rtl"] {
      line-height: 17.64px;
      font-family: $font-family--ar;
    }
  }
}

html[dir="rtl"] {
  .footer {
    font-family: $font-family--ar;
    line-height: 33px;

    &__variable {
      &-bottom {
        &-label {
          font-family: 'Lato', sans-serif;
        }
      }
    }

    &__subtitle {
      &::after {
        left: auto;
        right: 0;
      }
    }

    &__socials-text {
      line-height: 33px;
    }

    &-accordion__item-title {
      &::after {
        right: auto;
        left: 26px;
      }
    }
  }
}

/*=====  End of Footer  ======*/
/*=============================================
=            Search Results            =
=============================================*/

.search {
  padding: 0 26px;

  &__input {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    padding: 13px 0;
    padding-inline: 23px 66px;
    width: 100%;
    background-color: $bg--white;
    font-family: 'Go', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    -webkit-appearance: none;
    background-image: url('/assets/img/img/search-icon.svg');
    background-position-x: calc($container-width--wide - 15px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 24px;
    z-index: 9999;
    height: 55px;

    ::placeholder { 
      color: #1E1E1E;
      opacity: 1; 
    }
    
    :-ms-input-placeholder {
      color: #1E1E1E;
    }
    
    ::-ms-input-placeholder { 
      color: #1E1E1E;
    }

    &-container {
      width: 36.77%;
      min-width: 400px;
      max-width: 662px;
      margin: auto;
      padding: 56px 26px 28px;

      @include media-breakpoint-down(md) {
        min-width: auto;
        width: 100%;
        padding-top: 33px;
      }
    }
  }

  &__header {
    padding: 0 26px;
    gap: 16px;
    margin-bottom: 24px;
    padding-top: 33px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      gap: 10px;
    }
  }

  &__heading {
    color: $text--secondary;
    font-size: 26px;
    font-weight: 700;
    line-height: 35px; 
    letter-spacing: 0.5px;

    @include media-breakpoint-down(md) {
      font-size: 25px;
      font-weight: 600;
      line-height: 29px;
      letter-spacing: 0.5px;
    }
  }

  &__results {
    font-size: 26px;
    font-weight: 500;
    line-height: 35px; 
    letter-spacing: 0.5px;

    @include media-breakpoint-down(md) {
      font-family: 'Lato', sans-serif;
      font-size: 17px;
      font-weight: 900;
      line-height: 25px; 
      letter-spacing: 0.5px;
    }
  }

  &__word {
    color: $text--secondary;
  }

  &-wrapper {
    padding-bottom: 76px;
    background-color: #F7F9FB;

    @include media-breakpoint-down(md) {
      padding-bottom: 32px;
    }
  }
  
  &__inner {
    gap: 28px;

    @include media-breakpoint-down(md) {
      gap: 26px;
    }
  }

  &__item {
    background-color: $bg--white;
    padding: 32px;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      padding: 20px 0 24px;
      padding-inline: 15px 38px;
    }

    &:hover, &:focus {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transform: scale(1.01);
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px; 
      letter-spacing: 0.5px;
      color: $text--primary;
    }

    &-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; 
      letter-spacing: 0.5px;
      color: $text--primary;
    }

    &-left {
      padding-inline-end: 93px;
      gap: 8px;

      @include media-breakpoint-down(md) {
        padding-inline-end: 0px;
      }
    }

    &-right {
      margin: auto;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-button {
      color: $text--secondary;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.5px;
      margin-inline-start: auto;
      margin-top: 12px;
      transform: translateX(12px);

      @include media-breakpoint-up(md) {
        display: none;
      }

      &-icon {
        margin-inline-start: 5px;
        font-size: 20px;
        transform: translateY(3px);
      }
    }
  }

  &__image {
    width: 24px;
    height: 24px;
  }

  &__pagination {
    padding: 0 26px;
    margin-top: 69px;
    gap: 16px;

    @include media-breakpoint-down(md) {
      gap: 8px;
      margin-top: 32px;
    }

    &-icon {
      font-size: 24px;
    }

    &-button {
      height: 40px;
      width: 40px;
      border-radius: 4px;
      border: 1px solid #BEBEBE;
      cursor: pointer;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;

      @include transition;

      @include media-breakpoint-down(md) {
        height: 34px;
        width: 34px;
      }

      &:hover, &:focus {
        border-color: $secondary;
      }

      &:disabled {
        opacity: 0.3;
        cursor: default;
      }

      &:disabled:hover {
        border-color: #BEBEBE;
      }

      &--active {
        border-color: $secondary;
        color: $text--secondary;
      }
    }

    &-dots {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      border-color: transparent;
    }
  }

  &__no-results {
    padding: 40px 26px 56px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding-top: 172px;
    }

    &--active {
      display: flex;
    }

    &-image {
      height: 100%;
      width: 100%;
      object-fit: contain;
      vertical-align: middle;

      &-container {
        width: 149px;
        margin-bottom: 34px;

        @include media-breakpoint-down(md) {
          width: 143px;
          margin-bottom: 26px;
        }
      }
    }

    &-title {
      color: $text--primary;
      font-size: 32px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 12px;

      @include media-breakpoint-down(md) {
        font-size: 25px;
        line-height: 29px; 
        letter-spacing: 0.5px;
      }
    }

    &-text {
      font-family: 'Go', sans-serif;
      font-size: 17px;
      font-weight: 400;
      line-height: normal;
      color: #808082;
      margin-bottom: 28px;

      @include media-breakpoint-down(md) {
        font-family: 'Lato', sans-serif;
        font-size: 13px;
        font-weight: 900;
        line-height: 19px; 
        letter-spacing: 0.5px;
        margin-bottom: 73px;
      }
    }

    .link {
      margin-bottom: 456px;
      padding: 18px 54px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 152px;
        width: 100%;
      }
    }
  }
}

html[dir="rtl"] {
  .search__input {
    position: relative;
    -webkit-appearance: none;
    background-image: url('/assets/img/img/search-icon.svg');
    background-position-x: 15px;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 24px;
    z-index: 9999;
    cursor: pointer;
  }
  
  .search__item-right, .search__pagination-next, .search__pagination-prev {
    transform: rotate(180deg);
  }
  
  .search__item-button-icon {
    transform: rotate(180deg) translateY(-2px);
  }

  .search__no-results {
    transform: scaleX(-1);
  }
}

/*=====  End of Search Results  ======*/

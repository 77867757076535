/*=============================================
=            News Room            =
=============================================*/

.news-room {
    max-width: 1178px;
    margin: 0 auto;
    padding-block: 56px;

    &__wrapper {
        background-color: #f7f9fb;
    }

    @include media-breakpoint-down(md) {
        padding: 24px 26px 62px;
    }

    &__header {
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            justify-content: end;
        }
    }

    &__heading {
        color: $text--secondary;
        font-size: 26px;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0.5px;

        @include media-breakpoint-down(md) {
            font-size: 25px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.5px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__select {
        font-family: 'Go', sans-serif !important;
        font-weight: 600;
        background-color: $bg--white;
        padding: 8px 0;
        padding-inline: 14px 51px;
        border-radius: 6px;
        position: relative;
        -webkit-appearance: none;
        background-image: url('/assets/img/img/chevron-down-gray.svg');
        background-position-x: calc(100% - 15px);
        background-position-y: 50%;
        background-repeat: no-repeat;
        z-index: 9999;
        cursor: pointer;
        box-shadow: 0px 0px 7px rgba(27, 29, 32, 0.2);
    }

    &__option {
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
        color: #2c2c2e;
        font-family: inherit;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 24px;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;

        &-container {
            width: 229px;
            height: 229px;
            margin: auto;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                width: 138px;
                height: 138px;
            }
        }
    }

    &__left {
        margin: auto;
        display: none;
        flex: 0.7;
        padding: 32px;
    }

    &__card {
        background-color: $bg--primary;
        background-color: $bg--white;
        color: $text--primary;
        flex-direction: row;
        padding-inline: 40px;

        @include transition;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        &:hover,
        &:focus {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            transform: scale(1.01);
        }

        &:nth-child(4n - 3),
        &:nth-child(4n) {
            grid-column: span 2;
            flex-direction: row-reverse;
            align-items: center;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                grid-column: span 1;
            }

            .news-room__left {
                display: block;
            }

            .news-room__title,
            .news-room__subtitle {
                margin-block-end: 12px;
            }
        }

        &:nth-child(4n - 3) {
            flex-direction: row;
            align-items: center;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            .news-room__image-container {
                width: 392px;
                height: 310px;
                margin: auto;
                overflow: hidden;
                
                @include media-breakpoint-down(md) {
                    width: 138px;
                    height: 138px;
                }
            }

            .news-room__title,
            .news-room__subtitle {
                margin-block-end: 12px;
            }
        }
    }

    &__title {
        font-size: 19px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0.5px;
        margin-bottom: 8px;

        @include media-breakpoint-down(md) {
            font-size: 25px;
            font-weight: 500;
            line-height: 29px;
        }
    }

    &__subtitle {
        color: $text--secondary;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;
        margin-bottom: 8px;
    }

    &__text {
        font-size: 17px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;
        color: #35475e;

        @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    &__content {
        color: $text--primary;
        padding-block: 32px;
        flex: 1;

        @include media-breakpoint-down(md) {
            padding: 20.5px 0 26px;
            padding-inline: 20px 30px;
        }

        .link {
            margin-inline-start: auto;
            font-weight: 500;
            padding: 12px 0 0 0;
            transform: translateX(22px);

            @include media-breakpoint-down(md) {
                transform: translateX(14px);
            }
        }
    }

    &__link {
        color: $text--secondary;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.5px;
        margin: auto;
        margin-top: 25px;

        @include media-breakpoint-up(md) {
            display: none;
        }

        &-icon {
            font-size: 16px;
        }
    }
}

html[dir='rtl'] {
    .news-room__select {
        position: relative;
        -webkit-appearance: none;
        background-image: url('/assets/img/img/chevron-down-gray.svg');
        background-position-x: 20px;
        background-position-y: 50%;
        background-repeat: no-repeat;
        z-index: 9999;
        cursor: pointer;
    }
}

/*=====  End of News Room  ======*/

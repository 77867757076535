@import url("https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,700&display=swap");
@import url("https://fonts.cdnfonts.com/css/go");
@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DIN-NEXT-ARABIC-LIGHT.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}
@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DIN-NEXT-ARABIC-REGULAR.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DIN-NEXT-ARABIC-BOLD.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Black.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-BlackItalic.ttf") format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Bold.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-BoldItalic.ttf") format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Light.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-LightItalic.ttf") format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Regular.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Italic.ttf") format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-Thin.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato-ThinItalic.ttf") format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
}
#embedContainer {
  max-width: 1440px !important;
  margin: 0 auto !important;
  width: 100% !important;
  padding: 60px !important;
}

.fade-in {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-out {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
html,
body {
  font-family: "Lato";
  font-weight: 400;
  overflow-x: hidden;
  color: #000;
  scroll-behavior: smooth;
  background-color: #F7F9FB;
}

[dir=rtl] body {
  font-family: "Din", sans-serif;
}

.container {
  margin: 0 auto;
  max-width: 1124px;
}
.container--full {
  width: 100%;
  margin: 0 auto;
}
.container--awkward {
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 200px;
}
@media (max-width: 991.98px) {
  .container--awkward {
    padding: 0 16px;
  }
}
.container--has-margin {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 767.98px) {
  .container--has-margin {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.background--grey.background--grey {
  background-color: #f7f9fb;
}

.hidden {
  display: none;
  visibility: hidden;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h5 {
  display: block;
  margin-block: 24px;
  font-weight: 400;
}
.rich-text h1:first-child,
.rich-text h2:first-child,
.rich-text h3:first-child,
.rich-text h4:first-child,
.rich-text h5:first-child {
  margin-block-start: 0;
}
.rich-text h2 {
  color: #273a53;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.rich-text h3 {
  color: #0070df;
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.rich-text p,
.rich-text ol,
.rich-text ul {
  font-size: 17px;
  color: #35475E;
  display: block;
  margin-block-end: 5px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
@media (max-width: 479.98px) {
  .rich-text p,
.rich-text ol,
.rich-text ul {
    font-size: 15px;
    line-height: 20px;
  }
}
.rich-text ul,
.rich-text ol,
.rich-text li {
  margin-inline-start: 16px;
}
.rich-text ul,
.rich-text ol {
  margin-block-start: 20px;
}
.rich-text ul {
  list-style: disc;
}
.rich-text ol {
  list-style: decimal;
}
.rich-text strong {
  font-weight: 700;
}
.rich-text a.underline {
  text-decoration: underline;
}
.rich-text table {
  margin-block-start: 24px;
  border-collapse: collapse;
  width: 100%;
  color: #273A53;
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.5px;
}
.rich-text th {
  font-size: 13px;
  color: #fff;
  background-color: #91a1b6;
}
.rich-text tbody tr {
  background-color: #fff;
}
.rich-text tbody tr:nth-child(odd) {
  background-color: #F1F3F4;
}
.rich-text th,
.rich-text td {
  padding: 15px 8px;
  text-align: start;
}
@media (max-width: 479.98px) {
  .rich-text th,
.rich-text td {
    font-size: 12px;
  }
}
.rich-text th:first-child,
.rich-text td:first-child {
  padding-inline-start: 20px;
}
.rich-text th:last-child,
.rich-text td:last-child {
  padding-inline-end: 5px;
}
.rich-text--blue h1,
.rich-text--blue h2,
.rich-text--blue h3,
.rich-text--blue h4,
.rich-text--blue h5,
.rich-text--blue p,
.rich-text--blue a,
.rich-text--blue ol,
.rich-text--blue ul,
.rich-text--blue table,
.rich-text--blue th {
  color: #fff;
}
.rich-text--blue th {
  background-color: #35475E;
}
.rich-text--blue tbody tr {
  background-color: #0070df;
}
.rich-text--blue tbody tr:nth-child(odd) {
  background-color: #207dda;
}

/* Branding on cookie banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}

.rtl {
  direction: rtl;
}

.icon-saudi_riyal::after {
  content: "";
  display: block;
  height: 37px;
  width: 33px;
  background: url("/assets/img/img/riyal.svg") no-repeat;
  background-size: contain;
  filter: brightness(0) saturate(100%) invert(31%) sepia(77%) saturate(4755%) hue-rotate(198deg) brightness(95%) contrast(101%);
}
.icon-saudi_riyal--s::after {
  height: 12px;
  width: 10.74px;
  filter: none;
  margin-bottom: 2px;
}
.icon-saudi_riyal--m::after {
  height: 13px;
  width: 11.63px;
  filter: none;
  margin-bottom: 2px;
}

.flex {
  display: flex;
  flex-wrap: nowrap;
}
.flex--wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex--justify-space-around {
  justify-content: space-around;
}
.flex--justify-between {
  justify-content: space-between;
}
.flex--justify-center {
  justify-content: center;
}
.flex--justify-end {
  justify-content: flex-end;
}
.flex--align-center {
  align-items: center;
}
.flex--align-start {
  align-items: flex-start;
}
.flex--row {
  flex-direction: row;
}
.flex--column {
  flex-direction: column;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: currentColor;
  border: none;
  background: none;
}
*:hover,
*::before:hover,
*::after:hover {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

address {
  font-style: normal;
}

a {
  text-decoration: none;
  opacity: 1;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
a:hover, a:focus {
  color: currentColor;
  opacity: 1;
}

img {
  display: block;
  width: 100%;
}
img.inline {
  width: unset;
}

@media (max-width: 479.98px) {
  .hidden--sm-down {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .hidden--md-down {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hidden--lg-up {
    display: none !important;
  }
}
.hidden-element {
  display: none;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.about-us-page {
  padding: 56px 32px;
  background-color: #f7f9fb;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
@media (max-width: 479.98px) {
  .about-us-page {
    padding: 32px 26px;
    font-size: 12px;
  }
}
.about-us-page h2,
.about-us-page h3 {
  margin-bottom: 24px !important;
  color: #273a53;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.about-us-page p {
  color: #273a53;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.about-us-page__full-width, .about-us-page__half-width {
  background-color: #fff;
  padding: 56px 40px;
}
@media (max-width: 767.98px) {
  .about-us-page__full-width, .about-us-page__half-width {
    padding: 24px;
  }
}
.about-us-page__full-width {
  grid-column: span 2;
}
.about-us-page__half-width {
  grid-column: span 1;
}
@media (max-width: 767.98px) {
  .about-us-page__half-width {
    grid-column: span 2;
  }
}
.about-us-page__partners-grid .logo-grid {
  display: flex;
  justify-content: center;
  gap: 70px;
  padding: 0;
}
@media (max-width: 767.98px) {
  .about-us-page__partners-grid .logo-grid {
    margin-block-start: 32px;
  }
}
.about-us-page__partners-grid .logo-grid__image {
  max-width: 120px;
}
@media (max-width: 767.98px) {
  .about-us-page__partners-grid .logo-grid__image {
    width: 100%;
  }
}
.about-us-page__awards-grid .logo-grid {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 50px;
  padding: 0;
}
@media (max-width: 479.98px) {
  .about-us-page__awards-grid .logo-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .about-us-page__awards-grid .logo-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}
.about-us-page__awards-grid .logo-grid__item {
  max-width: 175px;
}
@media (max-width: 479.98px) {
  .about-us-page__awards-grid .logo-grid__image {
    width: 100%;
    height: 110px;
  }
}
@media (max-width: 767.98px) {
  .about-us-page__awards-grid .logo-grid__image {
    width: 100%;
  }
}

html[dir=rtl] .about-us-page h2,
html[dir=rtl] .about-us-page h3 {
  font-size: 24px;
  line-height: 49px;
  margin-bottom: 8px;
}
html[dir=rtl] .about-us-page p {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
}

/*=============================================
=            Accordion            =
=============================================*/
.accordion {
  padding: 0;
}
.accordion__heading {
  padding: 0;
  color: #0070df;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .accordion__heading {
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;
  }
}
.accordion-wrapper {
  padding: 56px 24px;
  background-color: #F7F9FB;
}
@media (max-width: 767.98px) {
  .accordion-wrapper {
    padding-block: 40px;
  }
}
.accordion-wrapper-tabbed {
  padding-block: 0;
}
.accordion__inner {
  gap: 24px;
}
.accordion__item {
  background-color: #fff;
}
.accordion__item-inner {
  gap: 20px;
  padding-bottom: 21px;
  padding-inline: 39px 37px;
  display: none;
}
@media (max-width: 767.98px) {
  .accordion__item-inner {
    padding-block-end: 20px;
    padding-inline: 20px 20px;
  }
}
.accordion__item-inner--active {
  display: block;
  max-width: 855px;
}
.accordion__item-top {
  width: 100%;
  font-family: "Lato", sans-serif;
  padding: 21px 0 18px;
  padding-inline: 36px 60px;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #273a53;
}
@media (max-width: 767.98px) {
  .accordion__item-top {
    font-size: 14px;
    padding: 20px 0 20px;
    padding-inline: 20px 50px;
  }
}
.accordion__item-top::after {
  content: url("/assets/img/img/chevron-down.svg");
  position: absolute;
  right: 37px;
  top: 20px;
}
@media (max-width: 767.98px) {
  .accordion__item-top::after {
    right: 24px;
  }
}
.accordion__item-top--active {
  padding-bottom: 10px;
  color: #0070df;
}
@media (max-width: 767.98px) {
  .accordion__item-top--active {
    padding-bottom: 13px;
  }
}
.accordion__item-top--active::after {
  content: url("/assets/img/img/chevron-up-active.svg");
}
.accordion__item-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #273a53;
}
.accordion .rte p {
  font-family: "Lato", sans-serif;
  line-height: 22px;
  font-weight: 500;
  font-size: 14px;
}

html[dir=rtl] .accordion__item-top {
  font-family: DIN;
}
html[dir=rtl] .accordion__item-top::after {
  right: auto;
  left: 37px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .accordion__item-top::after {
    left: 24px;
  }
}
html[dir=rtl] .accordion .rte p {
  font-family: DIN;
}

/*=====  End of Accordion  ======*/
/*=============================================
=            App Download CTA            =
=============================================*/
.download-cta {
  background: linear-gradient(180deg, transparent 19%, rgb(24, 205, 183) 16.5%);
  color: #fff;
  margin-top: 13px;
  margin-bottom: 110px;
  height: 598px;
}
@media (max-width: 767.98px) {
  .download-cta {
    height: auto;
    margin-bottom: 0;
    background: linear-gradient(180deg, transparent 27.1%, rgb(24, 205, 183) 27.1%);
  }
}
.download-cta__inner {
  padding: 0 26px;
  margin: 0 auto;
  width: 100%;
  gap: 40px;
}
@media (max-width: 991.98px) {
  .download-cta__inner {
    gap: 32px;
    min-height: 565px;
  }
}
@media (max-width: 767.98px) {
  .download-cta__inner {
    flex-direction: column;
  }
}
.download-cta__left {
  position: relative;
  height: 600px;
}
@media (max-width: 991.98px) {
  .download-cta__left {
    height: 565px;
  }
}
@media (max-width: 479.98px) {
  .download-cta__left {
    transform: translateY(-47px);
  }
}
.download-cta__image {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 2s, transform 2s ease-out;
  height: 100%;
  width: 100%;
  height: 600px;
  object-fit: contain;
}
@media (max-width: 991.98px) {
  .download-cta__image {
    height: 565px;
    min-width: 282px;
  }
}
.download-cta__image--visible {
  opacity: 1;
  transform: translateY(65px);
}
.download-cta__right {
  padding-block-start: 41px;
  padding-block-end: 36px;
  max-width: 520px;
  margin-top: 113px;
}
@media (max-width: 767.98px) {
  .download-cta__right {
    padding-block-start: 0px;
    padding-block-end: 26px;
    margin-top: 0px;
  }
}
.download-cta__subtitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 2px;
  color: #FFE000;
  margin-bottom: 5px;
}
.download-cta__title {
  max-width: 440px;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 17px;
}
@media (max-width: 991.98px) {
  .download-cta__title {
    font-size: 36px;
    line-height: 41px;
  }
}
.download-cta__text {
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 27px;
}
.download-cta__code {
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
.download-cta__code-container {
  border-radius: 24px;
  background-color: #fff;
  overflow: hidden;
  width: 163px;
  height: 155px;
  padding: 11px;
}
@media (max-width: 767.98px) {
  .download-cta__code-container {
    display: none;
  }
}
.download-cta__stores {
  flex-wrap: wrap;
  gap: 15px;
  padding-inline: 8px;
}
@media (min-width: 768px) {
  .download-cta__stores {
    display: none;
  }
}
.download-cta__store {
  height: 100%;
  width: 145px;
}

html[dir=rtl] .download-cta__text {
  line-height: 24px;
}

/*=====  End of App Download CTA  ======*/
/*=============================================
=            Banner Component            =
=============================================*/
.banner {
  padding-block: 56px;
}
@media (max-width: 1249.98px) {
  .banner {
    padding-inline: 24px;
  }
}
.banner__inner {
  gap: 24px;
  padding: 53px 60px 53px 77px;
  border-radius: 24px;
  background: linear-gradient(85.7deg, #421e84 8.9%, #0070f9 136.34%);
  color: #fff;
}
@media (max-width: 767.98px) {
  .banner__inner {
    justify-content: center;
    padding: 36px 22px 29px;
  }
}
@media (max-width: 991.98px) {
  .banner__right {
    display: none;
  }
}
.banner__left {
  gap: 53px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767.98px) {
  .banner__left {
    width: 100%;
    flex-direction: column;
    gap: 26px;
  }
}
.banner__content {
  gap: 32px;
  margin-top: auto;
}
.banner__title {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}
@media (max-width: 767.98px) {
  .banner__title {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 28.8px;
    max-width: 279px;
  }
}
html[dir=rtl] .banner__title {
  font-family: "Din", sans-serif;
  line-height: 58.8px;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .banner__title {
    font-size: 21.75px;
    line-height: 31.98px;
  }
}
.banner__image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.banner__image-container {
  height: 188.01px;
  width: 188.01px;
  min-height: 188.01px;
  min-width: 188.01px;
  border-radius: 44.41px;
  box-shadow: 0px 7.3px 31.03px 8.54px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 38px;
}
@media (max-width: 767.98px) {
  .banner__image-container {
    height: 89px;
    width: 89px;
    min-height: 89px;
    min-width: 89px;
    padding: 18px;
    border-radius: 23px;
  }
}
.banner__qr {
  height: 194px;
  width: 194px;
  object-fit: contain;
  aspect-ratio: 1/1;
}
.banner__qr-container {
  padding: 14px;
  border-radius: 24px;
  background-color: #fff;
}
@media (max-width: 991.98px) {
  .banner__qr-container {
    display: none;
  }
}
.banner__stores {
  gap: 24px;
}
@media (max-width: 767.98px) {
  .banner__stores {
    justify-content: center;
    gap: 11px;
  }
}
.banner__store {
  height: 51px;
  min-width: fit-content;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.banner__store:hover, .banner__store:focus {
  opacity: 0.8;
}
@media (max-width: 767.98px) {
  .banner__store {
    height: 45px;
  }
}

/*=====  End of Banner Component  ======*/
/*=============================================
=            button            =
=============================================*/
.button {
  padding: 14px 20px;
  cursor: pointer;
  padding: 14px 20px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  gap: 8px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.button--underline .button__label {
  text-decoration: underline;
}
.button--primary {
  background-color: #273a53;
  color: #fff;
}
.button--primary:hover, .button--primary:focus {
  background-color: #F26101;
}
.button--secondary {
  background-color: #0070df;
  color: #fff;
}
.button--secondary:hover, .button--secondary:focus {
  background-color: #F26101;
}
.button--white {
  display: block;
  font-family: "Lato";
  background-color: #fff;
  width: fit-content;
  color: #0070df;
  padding: 17px 76px;
  border-radius: 12px;
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: center;
}
.button--white:hover, .button--white:focus {
  background-color: #273a53;
  color: #fff;
}
.button--outline {
  background-color: #fff;
  color: #273a53;
  border: 2px solid #273a53;
}
.button--outline:hover, .button--outline:focus {
  color: #0070df;
  border-color: #0070df;
}

html[dir=rtl] .button--white {
  font-family: "Din", sans-serif;
  font-weight: 500;
  line-height: 24px;
}

/*=====  End of button  ======*/
/*=============================================
=            Card Grid            =
=============================================*/
.card-grid {
  max-width: 1178px;
  margin: 0 auto;
  padding: 56px 26px 41px;
}
@media (max-width: 767.98px) {
  .card-grid {
    padding: 48px 26px 59px;
  }
}
.card-grid__header {
  color: #273a53;
  font-size: 35px;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .card-grid__header {
    font-size: 25px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
  }
}
.card-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 26px;
}
@media (max-width: 767.98px) {
  .card-grid__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.card-grid__card {
  background-color: #273a53;
  transition: all 0.5s ease 0s;
}
.card-grid__card:hover {
  box-shadow: 0px 8px 16px 2px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
}
.card-grid__card:hover .card-grid__image {
  scale: 1.07;
}
.card-grid__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.card-grid__image-container {
  overflow: hidden;
  height: 276px;
}
@media (max-width: 767.98px) {
  .card-grid__image-container {
    height: 206px;
  }
}
.card-grid__title {
  font-size: 19px;
  font-weight: 600;
}
.card-grid__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  opacity: 0.7;
  text-align: justify;
}
.card-grid__content {
  color: #fff;
  padding: 28px 40px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
@media (max-width: 767.98px) {
  .card-grid__content {
    padding: 20.5px 37px 20px 20px;
  }
}
.card-grid .link {
  padding-block: 0px;
  margin-inline-start: auto;
  font-weight: 500;
}
.card-grid .link .zmdi {
  transform: translateY(3px);
}

html[dir=rtl] .card-grid__header {
  font-size: 44px;
  font-weight: 500;
  line-height: 38px;
}
html[dir=rtl] .card-grid .link {
  padding-block-end: 5px;
}
html[dir=rtl] .card-grid .link .zmdi-arrow-right {
  transform: scaleX(-1) translateY(17px);
}

/*=====  End of Card Grid  ======*/
/*=============================================
=            App Download CTA            =
=============================================*/
.cta-with-text {
  max-width: 1150px;
  margin-block-end: 56px;
}
@media (max-width: 524.98px) {
  .cta-with-text {
    flex-direction: column-reverse;
    position: relative;
    inset-block-start: -42px;
    margin-block-end: 0px;
  }
}
.cta-with-text__top {
  padding-block-start: 56px;
  padding-inline-end: 145px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #35475E;
  margin-inline: 24px;
}
@media (max-width: 767.98px) {
  .cta-with-text__top {
    padding-block: 48px 8px;
    padding-inline-end: 20px;
    margin-block-end: 24px;
  }
}
@media (max-width: 479.98px) {
  .cta-with-text__top {
    margin-block-end: 0px;
  }
}
@media (max-width: 524.98px) {
  .cta-with-text__top {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
}
.cta-with-text__bottom {
  margin-block-start: 40px;
  background-color: #18cdb7;
  padding: 27px 40px;
  margin-inline: 24px;
  gap: 24px;
}
@media (max-width: 767.98px) {
  .cta-with-text__bottom {
    flex-direction: column;
    padding: 48px 28px;
    margin-block-start: 0px;
  }
}
@media (max-width: 524.98px) {
  .cta-with-text__bottom {
    max-width: 450px;
  }
}
.cta-with-text__title {
  max-width: 450px;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #fff;
}
@media (max-width: 767.98px) {
  .cta-with-text__title {
    text-align: center;
  }
}

html[dir=rtl] .cta-with-text__top {
  font-weight: 400;
  padding-inline-end: 40px;
}
html[dir=rtl] .cta-with-text__title {
  font-weight: 500;
  max-width: 502px;
}

/*=====  End of App Download CTA  ======*/
/*=============================================
=            Feature CTA            =
=============================================*/
.feature-cta {
  position: relative;
  padding: 120px 0 0;
}
@media (max-width: 991.98px) {
  .feature-cta {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 0 48px;
  }
}
.feature-cta__left {
  width: 54.9%;
}
@media (max-width: 991.98px) {
  .feature-cta__left {
    width: 100%;
    height: 332px;
  }
}
.feature-cta__image {
  height: 100%;
  max-height: 430px;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.feature-cta__right {
  color: #fff;
  background-color: #0070df;
  width: 51.45%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 56px 40px;
}
@media (max-width: 991.98px) {
  .feature-cta__right {
    position: static;
    width: 100%;
    padding: 40px 26px;
  }
}
.feature-cta__left, .feature-cta__right {
  transition: transform 0.1s;
}
.feature-cta__inner {
  max-width: 536px;
  gap: 30px;
}
.feature-cta__title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}
@media (max-width: 991.98px) {
  .feature-cta__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
  }
}
.feature-cta__content {
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  margin-block-end: 56px;
  text-align: justify;
}

html[dir=rtl] .feature-cta__right {
  right: auto;
  left: 0;
}
html[dir=rtl] .feature-cta__title {
  font-size: 36px;
  font-weight: 500;
  line-height: 49px;
}
html[dir=rtl] .feature-cta__content {
  line-height: 24px;
}

/*=====  End of Feature CTA  ======*/
/*=============================================
=            Feature List            =
=============================================*/
.feature-list {
  max-width: 1150px;
}
.feature-list__inner {
  margin-inline: 24px;
  color: #fff;
}
@media (max-width: 767.98px) {
  .feature-list__inner {
    flex-direction: column;
    margin-block-end: 0;
  }
}
.feature-list__left {
  background-color: #fff;
  padding-block: 56px;
  padding-inline: 40px 98px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .feature-list__left {
    padding: 48px 24px;
  }
}
.feature-list__right {
  width: 100%;
  background-color: #0070df;
  padding: 56px 40px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .feature-list__right {
    padding: 40px 20px;
  }
}
.feature-list__right-image {
  width: 131px;
  height: 131px;
  margin-block-end: 40px;
}
.feature-list__right-title {
  max-width: 360px;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-block-end: 17px;
}
@media (max-width: 767.98px) {
  .feature-list__right-title {
    max-width: 265px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
  }
}
.feature-list__right-content {
  max-width: 430px;
  font-size: 18px;
  font-weight: 900;
  line-height: 27px;
  margin-block-end: 40px;
}
.feature-list__right-button-title {
  max-width: 400px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-block: 32px 24px;
}
@media (max-width: 767.98px) {
  .feature-list__right-button-title {
    max-width: 270px;
    text-align: center;
  }
}
.feature-list__ticks-container {
  gap: 32px;
}
.feature-list__ticks-tick {
  width: 24px;
  height: 24px;
}
.feature-list__ticks-title {
  color: #0070df;
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.feature-list__ticks-bullets {
  gap: 24px;
}
@media (max-width: 767.98px) {
  .feature-list__ticks-bullets {
    padding-inline-end: 10px;
  }
}
.feature-list__ticks-bullet {
  gap: 10px;
  color: #273A53;
  font-size: 14px;
}
.feature-list__ticks-bullet:last-child {
  padding-bottom: 40px;
}
@media (max-width: 767.98px) {
  .feature-list__ticks-bullet:last-child {
    padding-bottom: 48px;
  }
}
.feature-list__icons-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.feature-list__icons-title {
  color: #0070df;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.feature-list__icons-text {
  position: relative;
  top: -3px;
}
.feature-list__icons-icon {
  width: 22px;
  height: 100%;
}
.feature-list__icons-items-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding-bottom: 0;
}
@media (max-width: 767.98px) {
  .feature-list__icons-items-container {
    padding-inline-end: 20px;
  }
}
.feature-list__icons-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.feature-list__icons-item-title {
  color: #0070df;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.feature-list__icons-item-content {
  color: #273A53;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.feature-list .rte {
  position: relative;
  inset-block-start: -3px;
}

html[dir=rtl] .feature-list__icons-item {
  padding-inline-end: 85px;
}
html[dir=rtl] .feature-list__icons-item:nth-child(2) {
  padding-inline-end: 120px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .feature-list__icons-item {
    padding-inline-end: 40px;
  }
}
html[dir=rtl] .feature-list__icons-item-title {
  margin-block-end: 8px;
}
html[dir=rtl] .feature-list__icons-items-container {
  gap: 5px;
}
html[dir=rtl] .feature-list__icons-icon {
  position: relative;
  top: -3px;
}

/*=====  End of Feature List  ======*/
/*=============================================
=            Feature Hero            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.feature-hero {
  color: #fff;
}
.feature-hero__wrapper {
  position: relative;
}
@media (max-width: 767.98px) {
  .feature-hero {
    padding: 0;
  }
}
.feature-hero__inner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}
.feature-hero__item {
  height: 530px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 767.98px) {
  .feature-hero__item {
    position: relative;
    flex-direction: column;
    justify-content: space-between;
  }
}
.feature-hero__content {
  height: 100%;
  padding: 30px 0;
}
@media (max-width: 1249.98px) {
  .feature-hero__content {
    padding: 41px 0 0;
    padding-inline: 21px;
  }
}
@media (max-width: 991.98px) {
  .feature-hero__content {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
.feature-hero__content-full {
  width: 100%;
}
.feature-hero__content-full picture {
  width: 100%;
}
.feature-hero__content-full img {
  object-fit: cover;
  height: 538px;
}
@media (max-width: 767.98px) {
  .feature-hero__content-full img {
    height: 650px;
  }
}
@media (max-width: 991.98px) {
  .feature-hero {
    padding: 0;
  }
}
.feature-hero__image {
  height: 538px;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
@media (max-width: 767.98px) {
  .feature-hero__image {
    height: 550px;
  }
}
.feature-hero__arrow {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  color: #CAD2DA;
  border: 1px solid #CAD2DA;
  z-index: 99;
  font-size: 25px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.feature-hero__arrow:hover, .feature-hero__arrow:focus {
  color: #91a1b6;
  border-color: #91a1b6;
}
.feature-hero__arrow-icon {
  transform: translateY(1px);
}
.feature-hero__arrow-left {
  position: absolute;
  left: 77px;
}
@media (max-width: 1439.98px) {
  .feature-hero__arrow-left {
    left: 5px;
  }
}
.feature-hero__arrow-right {
  position: absolute;
  right: 77px;
}
@media (max-width: 1439.98px) {
  .feature-hero__arrow-right {
    right: 5px;
  }
}
.feature-hero__controls {
  position: absolute !important;
  top: 50%;
  left: 0;
  width: 100%;
}
@media (max-width: 1439.98px) {
  .feature-hero__controls {
    display: none;
  }
}
.feature-hero__slides {
  white-space: normal !important;
}
.feature-hero .glide {
  position: static;
}
.feature-hero .glide__bullets {
  margin: 20px auto 41px;
  justify-self: flex-end;
  position: absolute !important;
  bottom: 0;
  left: 50% !important;
  transform: translateX(-50%);
  z-index: 200;
}
@media (max-width: 1249.98px) {
  .feature-hero .glide__bullets {
    bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .feature-hero .glide__bullets {
    position: absolute;
    left: 50%;
    bottom: 26px;
    margin-bottom: 0;
  }
}
@media (max-width: 374.98px) {
  .feature-hero .glide__bullets {
    left: 10px;
  }
}
.feature-hero .glide__bullets .glide__bullet {
  width: 19px;
  height: 4px;
  border-radius: 10px;
  opacity: 0.8;
  margin-right: 6px;
  background: #d9d9d9;
  transform: matrix(1, 0, 0, -1, 0, 0);
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.feature-hero .glide__bullets .glide__bullet--active {
  width: 33px;
  background-color: #6E7C87;
}

/*=====  End of Feature Hero  ======*/
/*=============================================
=            Feature Hero Item Full            =
=============================================*/
.feature-hero__item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 553px;
  color: #0070df;
}
.feature-hero__item-inner {
  width: 100%;
  height: 100%;
  max-width: 1178px;
}
@media (max-width: 767.98px) {
  .feature-hero__item-inner {
    flex-direction: column;
  }
}
.feature-hero__item-left {
  height: 100%;
  width: 100%;
  flex: 1;
  margin: 0 auto;
}
.feature-hero__item-left h1,
.feature-hero__item-left h2 {
  font-size: 55px;
  font-weight: 700;
  line-height: 58px;
}
@media (max-width: 767.98px) {
  .feature-hero__item-left h1,
.feature-hero__item-left h2 {
    font-size: 33px;
    line-height: 39px;
  }
}
.feature-hero__item-left .link {
  margin-bottom: 21px;
}
@media (max-width: 767.98px) {
  .feature-hero__item-left {
    text-align: center;
    align-items: center;
    justify-content: center;
    height: auto;
    flex: none;
  }
}
@media (max-width: 767.98px) {
  .feature-hero__item-left {
    flex: 0;
    width: 100%;
  }
  .feature-hero__item-left .link {
    display: none;
  }
}
.feature-hero__item-right {
  flex: 1;
  height: 100%;
}
@media (max-width: 767.98px) {
  .feature-hero__item-right {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .feature-hero__item {
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    min-height: 517px;
  }
}

/*=====  End of Feature Hero  ======*/
/*=============================================
=            Feature Hero Item Full            =
=============================================*/
.feature-hero {
  height: 538px;
}
@media (max-width: 767.98px) {
  .feature-hero {
    height: 650px;
  }
}
.feature-hero__item-full {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 553px;
}
@media (max-width: 767.98px) {
  .feature-hero__item-full {
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    min-height: 517px;
  }
}
.feature-hero__item-full__content {
  width: 100%;
  position: absolute;
  padding-inline-start: 26px;
}
.feature-hero__item-full-left {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .feature-hero__item-full-left {
    flex: 0;
  }
  .feature-hero__item-full-left .link {
    display: none;
  }
}
.feature-hero__item-full-left h1 {
  font-size: 64px;
  font-weight: 400;
  line-height: 73px;
  margin-bottom: 26px;
  font-family: Neue Haas Grotesk Display Pro;
  text-transform: uppercase;
}
@media (max-width: 1249.98px) {
  .feature-hero__item-full-left h1 {
    font-size: 39px;
    line-height: 39px;
  }
}
.feature-hero__item-full-left h2 {
  font-size: 64px;
  font-weight: 400;
  line-height: 73px;
  margin-bottom: 26px;
  font-family: Neue Haas Grotesk Display Pro;
  text-transform: uppercase;
}
@media (max-width: 1249.98px) {
  .feature-hero__item-full-left h2 {
    font-size: 39px;
    line-height: 39px;
  }
}
@media (max-width: 991.98px) {
  .feature-hero .rte p {
    font-size: 39px;
    line-height: 39px;
  }
}
.feature-hero .link--secondary-large-white {
  width: fit-content;
}

html[dir=rtl] .feature-hero__item-full-left h1 {
  font-family: "Din", sans-serif;
  font-size: 77px;
  font-weight: 500;
  line-height: 68px;
}
@media (max-width: 1249.98px) {
  html[dir=rtl] .feature-hero__item-full-left h1 {
    font-size: 56px;
    font-weight: 500;
    line-height: 58px;
  }
}
html[dir=rtl] .feature-hero__item-full-left h2 {
  font-family: "Din", sans-serif;
  font-size: 77px;
  font-weight: 500;
  line-height: 68px;
}
@media (max-width: 1249.98px) {
  html[dir=rtl] .feature-hero__item-full-left h2 {
    font-size: 56px;
    font-weight: 500;
    line-height: 58px;
  }
}
html[dir=rtl] .link--secondary-large-white {
  margin-block-start: 80px;
}

/*=====  End of Feature Hero  ======*/
/*=============================================
=            Features Component            =
=============================================*/
.features__inner {
  gap: 171px;
  padding-block-start: 101px;
  padding-block-end: 56px;
}
@media (max-width: 1249.98px) {
  .features__inner {
    padding-inline: 56px;
    gap: 60px;
  }
}
@media (max-width: 991.98px) {
  .features__inner {
    flex-direction: column-reverse;
  }
}
@media (max-width: 767.98px) {
  .features__inner {
    padding-block-start: 48px;
    padding-block-end: 48px;
    padding-inline: 48px;
    gap: 32px;
  }
}
.features__header {
  color: #000;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
}
html[dir=rtl] .features__header {
  font-family: "Din", sans-serif;
  font-weight: 500;
  font-size: 45px;
  line-height: 66px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .features__header {
    text-align: center;
    font-size: 24px;
    line-height: 35px;
  }
}
@media (max-width: 767.98px) {
  .features__header {
    text-align: center;
    font-size: 24px;
    line-height: 29px;
  }
}
.features__left {
  min-width: 394px;
}
@media (max-width: 767.98px) {
  .features__left {
    min-width: auto;
    width: 100%;
  }
}
.features__right {
  gap: 40px;
}
.features__items {
  gap: 60px;
}
@media (max-width: 767.98px) {
  .features__items {
    gap: 24px;
  }
}
.features__item {
  gap: 16px;
}
@media (max-width: 767.98px) {
  .features__item {
    flex-direction: column;
  }
}
.features__item-icon {
  height: 40px;
  width: 40px;
}
.features__item-text {
  font-size: 32px;
  line-height: 38.4px;
  font-weight: 300;
}
html[dir=rtl] .features__item-text {
  font-family: "Din", sans-serif;
  font-size: 30px;
  line-height: 44px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .features__item-text {
    font-size: 18px;
    line-height: 26px;
  }
}
@media (max-width: 767.98px) {
  .features__item-text {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}
.features__bullets {
  width: 100%;
}
.features__bullet {
  width: 100%;
  gap: 46px;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-inline-start: 7px;
  background: linear-gradient(to bottom, #dfe1e3 50%) no-repeat;
  background-size: 4px 100%;
  background-position: 20px 0;
  padding-inline-start: 7px;
}
@media (max-width: 767.98px) {
  .features__bullet {
    gap: 32px;
    padding-inline-start: 5px;
    background-position: 17px 0;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
html[dir=rtl] .features__bullet {
  background-position: calc(100% - 21px) 0;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .features__bullet {
    background-position: calc(100% - 18px) 0;
  }
}
.features__bullet:first-of-type {
  padding-top: 0;
  background: linear-gradient(to bottom, transparent 50%, #dfe1e3 50%) no-repeat;
  background-size: 4px 100%;
  background-position: 20px 0;
}
@media (max-width: 767.98px) {
  .features__bullet:first-of-type {
    background-position: 17px 0;
  }
}
html[dir=rtl] .features__bullet:first-of-type {
  background-position: calc(100% - 21px) 0;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .features__bullet:first-of-type {
    background-position: calc(100% - 18px) 0;
  }
}
.features__bullet:last-of-type {
  gap: 37px;
  padding-inline-start: 0;
  padding-bottom: 0;
  background: linear-gradient(to bottom, #dfe1e3 50%, transparent 50%) no-repeat;
  background-size: 4px 100%;
  background-position: 20px 0;
}
@media (max-width: 767.98px) {
  .features__bullet:last-of-type {
    background-position: 17px 0;
  }
}
html[dir=rtl] .features__bullet:last-of-type {
  background-position: calc(100% - 21px) 0;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .features__bullet:last-of-type {
    background-position: calc(100% - 18px) 0;
  }
}
.features__bullet:last-of-type .features__bullet-dot-container::after {
  content: none;
}
.features__bullet:last-of-type .features__bullet-dot {
  background-color: #0070df;
  height: 47px;
  width: 47px;
}
@media (max-width: 767.98px) {
  .features__bullet:last-of-type .features__bullet-dot {
    height: 38px;
    width: 38px;
  }
}
.features__bullet:nth-child(1) .features__box-gradient {
  width: 82px;
}
@media (max-width: 767.98px) {
  .features__bullet:nth-child(1) .features__box-gradient {
    width: 42px;
  }
}
.features__bullet:nth-child(2) .features__box-gradient {
  width: 122.75px;
}
@media (max-width: 767.98px) {
  .features__bullet:nth-child(2) .features__box-gradient {
    width: 65px;
  }
}
.features__bullet:nth-child(3) .features__box-gradient {
  width: 133px;
}
@media (max-width: 767.98px) {
  .features__bullet:nth-child(3) .features__box-gradient {
    width: 79px;
  }
}
.features__bullet:nth-child(4) .features__box-gradient {
  width: 162px;
}
@media (max-width: 767.98px) {
  .features__bullet:nth-child(4) .features__box-gradient {
    width: 91px;
  }
}
.features__bullet-dot {
  border: 2px solid #0070df;
  background-color: #fff;
  color: #0070df;
  border-radius: 50%;
  height: 31px;
  width: 31px;
  min-height: 31px;
  min-width: 31px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .features__bullet-dot {
    height: 29px;
    width: 29px;
    min-height: 29px;
    min-width: 29px;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 500;
  }
}
.features__bullet-dot-container {
  position: relative;
}
.features__bullet-dot-inner {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
@media (max-width: 767.98px) {
  .features__bullet-icon {
    height: 20px;
    width: 20px;
  }
}
.features__box {
  gap: 8px;
  width: 100%;
  padding: 19px 23px;
  border-radius: 13px;
  color: #4b5563;
  box-shadow: 0px 3.24px 14.9px 0px rgba(0, 0, 0, 0.1294117647);
}
@media (max-width: 767.98px) {
  .features__box {
    gap: 5px;
    padding: 8px 15px 9px;
  }
}
.features__box-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2c2c2e;
}
html[dir=rtl] .features__box-text {
  font-family: "Din", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20.58px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .features__box-text {
    font-size: 10px;
    line-height: 14.7px;
    font-weight: 400;
  }
}
@media (max-width: 767.98px) {
  .features__box-text {
    font-size: 11px;
    line-height: 13px;
    font-weight: 500;
  }
}
.features__box-bottom {
  gap: 7px;
}
.features__box-gradient {
  border-radius: 693px;
  height: 20.81px;
  width: 120px;
}
@media (max-width: 767.98px) {
  .features__box-gradient {
    height: 13px;
  }
}
.features__box-currency {
  font-size: 13.87px;
  line-height: 11px;
  color: #a3a9b0;
}
html[dir=rtl] .features__box-currency {
  font-family: "Din", sans-serif;
  font-size: 14px;
  line-height: 19.63px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .features__box-currency {
    font-size: 10px;
    line-height: 14px;
  }
}
@media (max-width: 767.98px) {
  .features__box-currency {
    font-size: 8.92px;
    line-height: 7px;
  }
}

/*=====  End of Features Component  ======*/
.finance-component {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1181px;
  padding-block: 56px;
}
.finance-component__left, .finance-component__right {
  flex: 1;
  min-width: 0;
}
.finance-component__right .personal-calc__inner, .finance-component__right .home-calc__inner {
  display: flex;
  flex-direction: column-reverse;
  background-color: transparent;
}
.finance-component__right .personal-calc__right-inner, .finance-component__right .home-calc__right-inner {
  width: 100%;
}
.finance-component__right .personal-calc__left-inner, .finance-component__right .home-calc__left-inner {
  width: 100%;
  max-width: none;
  padding: 56px 40px;
}

@media (max-width: 991.98px) {
  .finance-component__right .personal-calc__payment-containers, .finance-component__right .home-calc__payment-containers {
    width: 100%;
  }
  .finance-component__right .personal-calc__right, .finance-component__right .home-calc__right {
    padding-block: 0px;
  }
  .finance-component__right .personal-calc__right-inner, .finance-component__right .home-calc__right-inner {
    max-width: none;
  }
}
@media (max-width: 767.98px) {
  .finance-component {
    flex-direction: column;
    gap: 0;
    padding-block: 0;
    padding-block-end: 56px;
  }
  .finance-component__left, .finance-component__right {
    flex: none;
    width: 100%;
  }
  .finance-component__right .personal-calc__inner, .finance-component__right .home-calc__inner {
    flex-direction: column-reverse;
  }
  .finance-component__right .personal-calc__right, .finance-component__right .home-calc__right {
    margin-block: 0px;
  }
  .finance-component__right .personal-calc__right-inner, .finance-component__right .home-calc__right-inner {
    width: 100%;
  }
  .finance-component__right .personal-calc__left-inner, .finance-component__right .home-calc__left-inner {
    width: 100%;
    max-width: none;
    padding-inline: 20px;
  }
}
/*=============================================
=            Footer            =
=============================================*/
.footer {
  font-family: "Lato", sans-serif;
  background-color: #222F44;
  color: #fff;
}
.footer__inner {
  margin: 0 auto;
  max-width: 1178px;
  padding: 53px 0 47px;
  width: 100%;
  gap: 30px;
}
@media (max-width: 767.98px) {
  .footer__inner {
    padding: 34px 0 36px;
    flex-direction: column;
    gap: 28px;
  }
}
.footer__logo-container {
  width: 137px;
  margin-bottom: 22.5px;
}
@media (max-width: 767.98px) {
  .footer__logo-container {
    margin: auto;
  }
}
.footer__variable {
  margin-block: 23px 15px;
}
.footer__variable-bottom {
  margin-block: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.footer__variable-bottom-image {
  width: 20px;
  height: 20px;
}
.footer__variable-bottom-label {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.footer__variable-label {
  color: #FFF;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  position: relative;
  padding-bottom: 5px;
}
.footer__variable-label::after {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-block-end: -3px;
  height: 5px;
  width: 46px;
  background-color: #546279;
  border-radius: 110px;
}
.footer__column {
  padding: 0 26px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767.98px) {
  .footer__column:nth-of-type(3) {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .footer__column:nth-of-type(4) {
    display: none;
  }
}
.footer-accordion {
  padding: 0;
}
@media (min-width: 768px) {
  .footer-accordion {
    display: none;
  }
}
.footer-accordion__item {
  position: relative;
}
.footer-accordion__item:last-child .footer-accordion__item-top {
  border-bottom: 1px solid #3A4557;
}
.footer-accordion__item-inner {
  display: none;
  padding: 10px 26px;
}
.footer-accordion__item-inner--active {
  display: block;
}
.footer-accordion__item-top {
  position: relative;
  width: 100%;
  cursor: pointer;
  border-top: 1px solid #3A4557;
}
.footer-accordion__item-top--active {
  border-bottom: 1px solid #3A4557;
}
.footer-accordion__item-title {
  position: relative;
  width: 100%;
  padding: 0 26px;
  font-size: 15px;
  font-weight: 500;
  line-height: 48px;
}
.footer-accordion__item-title::after {
  content: "+";
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  color: #6B798F;
  font-size: 30px;
}
.footer-accordion__item-title--active::after {
  content: "-";
}
.footer__subtitle {
  padding-bottom: 5px;
  margin-bottom: 19px;
  font-size: 17px;
  font-weight: 600;
  line-height: 48px;
  position: relative;
}
.footer__subtitle::after {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-block-end: -3px;
  height: 5px;
  width: 46px;
  background-color: #546279;
  border-radius: 110px;
}
@media (max-width: 767.98px) {
  .footer__subtitle {
    font-size: 15px;
    font-weight: 500;
    line-height: 48px;
  }
}
.footer__caption {
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  margin-block-end: 17px;
}
.footer__stores {
  gap: 15px;
}
@media (max-width: 767.98px) {
  .footer__stores {
    display: none;
  }
}
.footer__store {
  width: 152px;
}
.footer__phone {
  font-family: "Lato";
  font-size: 26px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 2px;
  border-bottom: 1px solid transparent;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.footer__phone:hover, .footer__phone:focus {
  border-color: #fff;
}
.footer__times {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  opacity: 0.8;
}
.footer .rte a,
.footer .rte p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  opacity: 0.8;
}
.footer .rte a {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.footer .rte a:hover, .footer .rte a:focus {
  opacity: 1;
}
.footer__times {
  margin-bottom: 30px;
}
.footer__links {
  font-size: 15px;
  font-weight: 400;
  line-height: 37px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.footer__link {
  display: block;
  opacity: 0.8;
  width: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.footer__link:hover, .footer__link:focus {
  opacity: 1;
}
@media (max-width: 767.98px) {
  .footer__link {
    font-size: 13px;
    font-weight: 400;
    line-height: 35px;
  }
}
.footer__bottom {
  background-color: #141C28;
  padding: 11px 0;
}
@media (max-width: 767.98px) {
  .footer__bottom {
    padding: 8px 0;
  }
}
.footer__bottom-inner {
  margin: 0 auto;
  max-width: 1178px;
  padding: 0 26px 0;
  width: 100%;
}
@media (max-width: 767.98px) {
  .footer__bottom-inner {
    justify-content: space-between;
  }
}
.footer__bottom-left {
  font-size: 13px;
  font-weight: 400;
  line-height: 48px;
}
@media (max-width: 767.98px) {
  .footer__bottom-right {
    display: none;
  }
}
.footer__socials {
  gap: 18px;
}
.footer__socials-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 48px;
  margin-inline-end: 11px;
}
@media (min-width: 768px) {
  .footer__mobile-socials, .footer__mobile-stores {
    display: none;
  }
}
.footer__mobile-socials {
  flex-wrap: wrap;
}
.footer__mobile-stores-wrapper {
  gap: 17px;
  flex-wrap: wrap;
  justify-content: initial;
}
.footer__social {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.footer__social:hover, .footer__social:focus {
  transform: scale(1.1);
}
.footer__social-icon {
  height: 100%;
  width: 100%;
  height: 23px;
}
.footer__end {
  width: 100%;
  background-color: #f7f9fb;
  color: #000;
}
.footer__end-inner {
  margin: 0 auto;
  max-width: 1178px;
  padding: 21px 26px 19px;
  width: 100%;
  gap: 71px;
}
@media (max-width: 767.98px) {
  .footer__end-inner {
    flex-direction: column;
    gap: 30px;
  }
}
.footer__end-inner .rte p {
  font-size: 12px;
  line-height: 14.4px;
}
.footer__end-left, .footer__end-right {
  flex: 1;
}
.footer__end-left[dir=rtl] {
  line-height: 17.64px;
  font-family: "Din", sans-serif;
}
.footer__end-right[dir=rtl] {
  line-height: 17.64px;
  font-family: "Din", sans-serif;
}

html[dir=rtl] .footer {
  font-family: "Din", sans-serif;
  line-height: 33px;
}
html[dir=rtl] .footer__variable-bottom-label {
  font-family: "Lato", sans-serif;
}
html[dir=rtl] .footer__subtitle::after {
  left: auto;
  right: 0;
}
html[dir=rtl] .footer__socials-text {
  line-height: 33px;
}
html[dir=rtl] .footer-accordion__item-title::after {
  right: auto;
  left: 26px;
}

/*=====  End of Footer  ======*/
/*=============================================
=            Heading Component            =
=============================================*/
.heading-component {
  padding-block: 166px 125px;
}
@media (max-width: 1249.98px) {
  .heading-component {
    padding-inline: 24px;
  }
}
@media (max-width: 991.98px) {
  .heading-component {
    flex-direction: column;
    padding-block: 34px 45px;
    gap: 66px;
  }
}
.heading-component__title {
  font-size: 54px;
  font-weight: 700;
  line-height: 70px;
  margin-bottom: 32px;
}
.heading-component__title-blue {
  color: #0070df;
}
@media (max-width: 991.98px) {
  .heading-component__title {
    text-align: center;
    font-size: 28px;
    line-height: 33.6px;
    margin-bottom: 5px;
  }
}
html[dir=rtl] .heading-component__title {
  font-family: "Din", sans-serif;
  font-weight: 500;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .heading-component__title {
    font-size: 24px;
    line-height: 35px;
    font-weight: 700;
  }
}
.heading-component__text {
  color: #2c2c2e;
  font-size: 24px;
  line-height: 28.8px;
  max-width: 464px;
  margin-bottom: 54px;
}
@media (max-width: 991.98px) {
  .heading-component__text {
    text-align: center;
    font-size: 16px;
    line-height: 19.2px;
    margin-bottom: 23px;
  }
}
html[dir=rtl] .heading-component__text {
  font-family: "Din", sans-serif;
  font-size: 22px;
  line-height: 32.34px;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .heading-component__text {
    font-size: 18px;
    line-height: 20px;
  }
}
.heading-component__left {
  flex: 1;
}
@media (max-width: 991.98px) {
  .heading-component__left {
    align-items: center;
  }
}
.heading-component__right {
  flex: 0.8;
}
.heading-component__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
@media (max-width: 991.98px) {
  .heading-component__image {
    max-width: 351.92px;
    margin: auto;
  }
}
@media (min-width: 992px) {
  .heading-component__image-mobile {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .heading-component__image-desktop {
    display: none;
  }
}
.heading-component__stores {
  gap: 31.82px;
  margin-bottom: 24.7px;
}
@media (max-width: 991.98px) {
  .heading-component__stores {
    margin-bottom: 17.4px;
    gap: 10px;
  }
}
.heading-component__store {
  height: 68.35px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.heading-component__store:hover, .heading-component__store:focus {
  opacity: 0.8;
}
@media (max-width: 991.98px) {
  .heading-component__store {
    height: 42.58px;
  }
}
.heading-component__stats {
  gap: 57px;
}
.heading-component__stat-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: #2c2c2e;
}
@media (max-width: 991.98px) {
  .heading-component__stat-title {
    font-size: 28px;
    line-height: 33.6px;
  }
}
.heading-component__stat-text {
  font-size: 16px;
  line-height: 19.2px;
  color: #91969c;
}
@media (max-width: 991.98px) {
  .heading-component__stat-text {
    font-size: 14px;
    line-height: 16.8px;
  }
}
html[dir=rtl] .heading-component__stat-text {
  font-family: "Din", sans-serif;
  line-height: 23.52px;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .heading-component__stat-text {
    font-size: 14px;
    line-height: 20.58px;
  }
}

/*=====  End of Heading Component  ======*/
/*=============================================
=            Hero            =
=============================================*/
.hero {
  position: relative;
  background-color: #F7F9FB;
  min-height: 380px;
}
@media (max-width: 1249.98px) {
  .hero {
    justify-content: flex-end;
    align-items: end;
    margin-block-end: 120px;
    min-height: 343px;
  }
}
.hero__inner {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-550px, -50%);
}
@media (max-width: 1249.98px) {
  .hero__inner {
    width: calc(100% - 52px);
    inset-block-start: initial;
    inset-block-end: 0%;
    transform: translate(-50%, 50%);
    z-index: 1;
  }
}
.hero__background {
  height: 380px;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.hero__picture {
  width: 100vw;
}
.hero__title {
  font-size: 35px;
  font-weight: 700;
  line-height: 38px;
  color: #0070df;
  max-width: 573px;
}
@media (max-width: 1249.98px) {
  .hero__title {
    text-align: center;
    font-size: 26px;
    line-height: 32px;
  }
}
.hero__text-container {
  background-color: #ffffff;
  width: 450px;
  padding: 32px;
  padding-block-start: 36px;
  padding-block-end: 41px;
}
@media (max-width: 1249.98px) {
  .hero__text-container {
    max-width: 100%;
    padding: 57px 63px;
  }
}
.hero__content {
  max-width: 370px;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #35475E;
  max-width: 573px;
  margin-block-start: 8px;
}
@media (max-width: 1249.98px) {
  .hero__content {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
}
.hero__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.hero--wide {
  height: 539px;
}
@media (max-width: 524.98px) {
  .hero--wide {
    min-height: 210px;
    height: 210px;
    margin-block-end: 148px;
  }
}
.hero--wide .hero__title {
  color: #0070F9;
}
@media (max-width: 1249.98px) {
  .hero--wide .hero__inner {
    width: calc(100% - 48px);
  }
}
.hero--wide .hero__background {
  height: 539px;
}
@media (max-width: 524.98px) {
  .hero--wide .hero__background {
    height: 210px;
  }
}
.hero--wide .hero__text-container {
  width: 575px;
  padding: 56px 40px;
}
@media (max-width: 524.98px) {
  .hero--wide .hero__text-container {
    margin: auto;
    padding: 48px 24px;
    gap: 15px;
  }
}
.hero--wide .hero__title {
  font-weight: 900;
}
@media (max-width: 524.98px) {
  .hero--wide .hero__title {
    font-size: 17px;
    line-height: 20.4px;
  }
}
.hero--wide .hero__content {
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
@media (max-width: 524.98px) {
  .hero--wide .hero__content {
    margin-inline: auto;
    max-width: 250px;
  }
}

html[dir=rtl] .hero__inner {
  transform: translate(550px, -50%);
}
@media (max-width: 1249.98px) {
  html[dir=rtl] .hero__inner {
    transform: translate(50%, 50%);
  }
}
html[dir=rtl] .hero__title {
  font-family: "Din", sans-serif;
  font-size: 27px;
  font-weight: 700;
  line-height: 34px;
}
html[dir=rtl] .hero__content {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
@media (max-width: 1249.98px) {
  html[dir=rtl] .hero--wide {
    margin-block-end: 0px;
  }
}
@media (max-width: 524.98px) {
  html[dir=rtl] .hero--wide {
    margin-block-end: 50px;
    min-height: 325px;
    height: 325px;
  }
}
@media (max-width: 1249.98px) {
  html[dir=rtl] .hero--wide .hero__inner {
    transform: translate(50%, 5%);
  }
}
html[dir=rtl] .hero--wide .hero__text-container {
  padding-inline-end: 70px;
}
@media (max-width: 524.98px) {
  html[dir=rtl] .hero--wide .hero__text-container {
    gap: 8px;
    padding: 48px 24px;
  }
}
@media (max-width: 524.98px) {
  html[dir=rtl] .hero--wide .hero__background {
    min-height: 325px;
    height: 325px;
  }
}
html[dir=rtl] .hero--wide .hero__title {
  font-size: 35.15px;
  font-weight: 700;
  line-height: 44.18px;
}
@media (max-width: 524.98px) {
  html[dir=rtl] .hero--wide .hero__title {
    font-size: 17px;
    line-height: 25px;
  }
}
html[dir=rtl] .hero--wide .hero__content {
  font-size: 17.07px;
  font-weight: 500;
  line-height: 38.37px;
}
@media (max-width: 524.98px) {
  html[dir=rtl] .hero--wide .hero__content {
    font-size: 15px;
    font-weight: 500;
    line-height: 22.05px;
    margin-block-start: 0;
  }
}

/*=====  End of Hero  ======*/
/*=============================================
=            Home Calculator            =
=============================================*/
.home-calc {
  gap: 28px;
}
@media (max-width: 991.98px) {
  .home-calc {
    gap: 24px;
  }
}
.home-calc__inner {
  margin: 0 auto;
  background-color: #0070df;
}
@media (max-width: 991.98px) {
  .home-calc__inner {
    flex-direction: column;
    padding: 0;
  }
}
.home-calc__left {
  flex: 1;
  background-color: #0070df;
  color: #fff;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .home-calc__left {
    justify-content: center;
  }
}
.home-calc__left-inner {
  padding-inline: 0 81px;
  max-width: 560px;
}
@media (max-width: 991.98px) {
  .home-calc__left-inner {
    padding-inline: 26px;
  }
}
.home-calc__right {
  flex: 1;
  color: #273a53;
  position: relative;
  background-color: #0070df;
}
@media (max-width: 991.98px) {
  .home-calc__right {
    padding: 32px 0;
    padding-inline: 0px;
    background-color: #f7f9fb;
  }
}
@media (max-width: 479.98px) {
  .home-calc__right {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-block: 20px;
    top: 0px !important;
  }
}
.home-calc__right-inner {
  padding: 56px 40px;
  width: fit-content;
  background-color: #FFF;
  height: calc(100% + 185px);
  display: flex;
  flex-direction: column;
  gap: 56px;
}
@media (max-width: 991.98px) {
  .home-calc__right-inner {
    padding-inline: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    margin: auto;
    max-width: 400px;
    top: 0px !important;
    margin-bottom: 0px !important;
    padding-inline: 20px;
    gap: 36px;
  }
}
.home-calc__title {
  font-size: 29px;
  font-weight: 700;
  line-height: 32px;
  font-family: "Lato", sans-serif;
}
@media (max-width: 991.98px) {
  .home-calc__title {
    text-align: center;
  }
}
@media (max-width: 479.98px) {
  .home-calc__title {
    font-size: 31px;
    font-weight: 600;
    line-height: 34px;
  }
}
.home-calc__title-label {
  margin-inline-end: auto;
}
@media (max-width: 991.98px) {
  .home-calc__title-label {
    font-size: 16px;
  }
}
.home-calc__text {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  margin-block: 56px;
  opacity: 0.7;
  text-align: justify;
}
@media (max-width: 1249.98px) {
  .home-calc__text {
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: 18px;
  }
}
.home-calc__field {
  width: 100%;
}
.home-calc__field-inner {
  margin-top: 10px;
}
.home-calc__field-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  font-family: Lato;
}
@media (max-width: 991.98px) {
  .home-calc__field-title {
    font-size: 16px;
  }
}
.home-calc__input[type=range] {
  width: 100%;
  cursor: pointer;
}
.home-calc__input[type=radio] {
  cursor: pointer;
}
.home-calc__property-value, .home-calc__salary-value {
  max-width: 100px;
}
.home-calc__label {
  margin-inline-end: 32px;
}
@media (max-width: 991.98px) {
  .home-calc__label {
    font-size: 14px;
  }
}
.home-calc__number {
  width: 135px;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: end;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  margin-inline-end: 5px;
  border: 2px solid transparent;
  border-radius: 8px;
  transform: translateY(-2px);
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 991.98px) {
  .home-calc__number {
    font-size: 15px;
  }
}
.home-calc__number:hover, .home-calc__number:focus {
  border-color: #18CDB7;
}
.home-calc__number::-webkit-inner-spin-button, .home-calc__number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.home-calc__payment-amount {
  align-items: center;
  gap: 5.85px;
}
.home-calc__min-value, .home-calc__max-value {
  display: flex;
  gap: 7.22px;
  align-items: center;
}
.home-calc__min-value i, .home-calc__max-value i {
  margin-top: 3px;
}
.home-calc__range-top, .home-calc__range-bottom {
  width: 100%;
}
.home-calc__range-top {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}
@media (max-width: 991.98px) {
  .home-calc__range-top {
    font-size: 15px;
  }
}
.home-calc__range-bottom {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  opacity: 0.6;
}
@media (max-width: 991.98px) {
  .home-calc__range-bottom {
    font-size: 13px;
    line-height: 38px;
  }
}
.home-calc input[type=range] {
  background: linear-gradient(to right, #18CDB7 0%, #18CDB7 50%, #1B82E9 50%, #1B82E9 100%);
  border-radius: 8px;
  height: 6px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  position: relative;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.home-calc input[type=range]:focus {
  border-color: #fff;
}
.home-calc input[type=range]::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: pointer;
  background: #fff;
  background-clip: content-box !important;
  background-image: url("/assets/img/img/pin.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 1249.98px) {
  .home-calc input[type=range]::-webkit-slider-thumb {
    width: 32px;
    height: 32px;
  }
}
.home-calc input[type=radio] {
  position: absolute;
  opacity: 0;
}
.home-calc input[type=radio] + .home-calc__label {
  cursor: pointer;
  opacity: 0.65;
}
.home-calc input[type=radio] + .home-calc__label:before {
  content: "";
  background: #0070DF;
  border-radius: 100%;
  border: 2px solid #fff;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-inline-end: 8px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  opacity: 0.65;
}
.home-calc input[type=radio]:checked + .home-calc__label {
  opacity: 1;
}
.home-calc input[type=radio]:checked + .home-calc__label:before {
  background-color: #fff;
  box-shadow: inset 0 0 0 3px #0070DF;
  opacity: 1;
}
.home-calc input[type=radio]:focus + .home-calc__label:before {
  border-color: #18CDB7;
}
.home-calc__circle {
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
  border: 17px solid #F2F7FB;
  border-radius: 50%;
  animation: spin 30s linear infinite;
}
.home-calc__circle-container {
  height: 385px;
  width: 385px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 991.98px) {
  .home-calc__circle-container {
    max-width: 335px;
    margin: auto;
  }
}
@media (max-width: 479.98px) {
  .home-calc__circle-container {
    height: 275px;
    width: 275px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.home-calc__payment {
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.home-calc__payment-containers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 17px;
  margin: 0 auto;
  align-items: center;
  min-width: 500px;
}
@media (max-width: 1249.98px) {
  .home-calc__payment-containers {
    min-width: 0;
    gap: 16px;
  }
}
.home-calc__payment-upper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-end;
}
@media (max-width: 991.98px) {
  .home-calc__payment-upper {
    align-items: center;
  }
}
.home-calc__payment-lower {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
@media (max-width: 479.98px) {
  .home-calc__payment-lower {
    gap: 0;
  }
}
.home-calc__payment-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 250px;
}
@media (max-width: 991.98px) {
  .home-calc__payment-container {
    gap: 5px;
    padding-block-end: 15px;
  }
}
.home-calc__payment-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 40px;
}
@media (max-width: 991.98px) {
  .home-calc__payment-title {
    font-size: 14px;
  }
}
.home-calc__payment-number {
  font-size: 49px;
  font-weight: 700;
  line-height: 70px;
  font-family: Neue Haas Grotesk Display Pro;
  color: #0070df;
}
@media (max-width: 991.98px) {
  .home-calc__payment-number {
    font-size: 47px;
    line-height: 36px;
  }
}
.home-calc__payment-currency {
  font-size: 36px;
  font-weight: 500;
  line-height: 70px;
  color: #0070df;
  font-family: Neue Haas Grotesk Display Pro;
  margin-top: 4px;
}
@media (max-width: 991.98px) {
  .home-calc__payment-currency {
    font-size: 20px;
    line-height: 35px;
    margin-block-start: 8px;
  }
}
.home-calc__payment-text, .home-calc__payment-terms {
  max-width: 475px;
}
@media (max-width: 991.98px) {
  .home-calc__payment-text, .home-calc__payment-terms {
    margin: 0;
    max-width: none;
  }
}
.home-calc__payment-text {
  line-height: 20px;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.home-calc__payment-terms {
  color: #000;
  line-height: 30px;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  opacity: 0.3;
  margin-top: -50px;
}
@media (max-width: 991.98px) {
  .home-calc__payment-terms {
    margin-top: -25px;
  }
}
.home-calc__payment-rate {
  margin-top: 47px;
}
.home-calc__payment-rate-icon {
  height: 33px;
  width: 33px;
  margin-inline-end: 3px;
}
@media (max-width: 991.98px) {
  .home-calc__payment-rate-icon {
    height: 26px;
    width: 26px;
  }
}
.home-calc__payment-rate-amount {
  color: #273A53;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
@media (max-width: 479.98px) {
  .home-calc__payment-rate-amount {
    font-size: 30px;
  }
}
.home-calc__payment-rate-text {
  color: #273A53;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
@media (max-width: 991.98px) {
  .home-calc__payment-rate-text {
    font-size: 12px;
    line-height: 22px;
    font-weight: 700;
  }
}
.home-calc__payment-symbol {
  color: #273A53;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
@media (max-width: 991.98px) {
  .home-calc__payment-symbol {
    font-size: 16px;
    margin-top: 8px;
  }
}
.home-calc__error {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #6B798F;
}
.home-calc__error-icon {
  width: 34px;
  height: 33px;
}
.home-calc__error-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
  display: none;
}
.home-calc__error-wrapper--active {
  display: flex;
}
.home-calc__error-container {
  gap: 8px;
  border: 1.5px solid #FBBC04;
  background-color: #fff;
  padding: 14px 35px 19px;
  border-radius: 12px;
  width: 500px;
  margin: 0 30px;
}

html[dir=rtl] .home-calc {
  gap: 10px;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .home-calc__title {
    font-size: 29px;
    margin-bottom: 40px;
  }
}
html[dir=rtl] .home-calc__property-value-label, html[dir=rtl] .home-calc__salary-value-label {
  margin-top: 8px;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .home-calc__field-title, html[dir=rtl] .home-calc__title-label, html[dir=rtl] .home-calc__range-bottom, html[dir=rtl] .home-calc__range-top, html[dir=rtl] .home-calc__number {
    font-size: 17px;
  }
}
html[dir=rtl] .home-calc__number {
  transform: translateY(2px);
}
html[dir=rtl] .home-calc__title, html[dir=rtl] .home-calc__title-label, html[dir=rtl] .home-calc__label, html[dir=rtl] .home-calc__field-title, html[dir=rtl] .home-calc__range-top, html[dir=rtl] .home-calc__text {
  font-family: "Din", sans-serif;
  line-height: 38px;
}
html[dir=rtl] .home-calc__field-inner {
  margin-top: 0;
}
html[dir=rtl] .home-calc__text {
  font-size: 14px;
  line-height: 19px;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .home-calc__text {
    font-size: 14px;
  }
}
html[dir=rtl] .home-calc__payment-currency {
  font-family: "Din", sans-serif;
  font-size: 24px;
  font-weight: 400;
}
html[dir=rtl] .home-calc__payment-text {
  font-family: "Din", sans-serif;
  font-size: 13px;
  line-height: 20px;
}
html[dir=rtl] .home-calc__payment-terms {
  font-size: 14px;
  line-height: 30px;
}
html[dir=rtl] .home-calc__payment-rate-text {
  font-family: DIN;
}
html[dir=rtl] .home-calc__payment-containers {
  min-width: 0px;
}
html[dir=rtl] .home-calc__wrapper {
  background: linear-gradient(270deg, #0070df 50%, transparent 50%);
}
@media (max-width: 991.98px) {
  html[dir=rtl] .home-calc__wrapper {
    background: linear-gradient(180deg, #0070df 50%, transparent 50%);
  }
}
html[dir=rtl] .home-calc__salary-value-label i::after, html[dir=rtl] .home-calc__min-value i::after, html[dir=rtl] .home-calc__max-value i::after {
  margin-bottom: 0px;
}
html[dir=rtl] input[type=radio] + .home-calc__label:before {
  top: 10px;
}
html[dir=rtl] input[type=range] {
  background: linear-gradient(to left, #18CDB7 0%, #18CDB7 50%, #1B82E9 50%, #1B82E9 100%);
}

/*=====  End of Home Calculator  ======*/
.lhs-tab {
  display: flex;
  flex-direction: column;
  gap: 56px;
  font-family: "Lato";
}
.lhs-tab__tabs {
  gap: 10px;
  border: 1px solid #E4EBF2;
  border-radius: 6px;
  width: fit-content;
  padding: 8px 10px;
  display: inline-flex;
  flex-direction: row;
  background-color: #fff;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.lhs-tab__card {
  display: flex;
  flex-direction: column;
  gap: 56px;
}
.lhs-tab__card-content {
  color: #273A53;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
}
.lhs-tab__card-content .container {
  margin: 0;
}
.lhs-tab__label {
  cursor: pointer;
  text-align: center;
  width: fit-content;
  min-width: 150px;
  transition: background-color, border-color 0.2s ease-in-out;
}
.lhs-tab__radio {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.lhs-tab__radio + .lhs-tab__label {
  margin: 0;
  padding: 17px 22px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  border: 1px solid transparent;
}
.lhs-tab__radio:hover + .lhs-tab__label {
  border-color: #0070df;
}
.lhs-tab__radio:checked + .lhs-tab__label {
  background-color: #0070df;
  color: #FFF;
  z-index: 1;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.5px;
  font-weight: 900;
}
.lhs-tab__tick {
  width: 24px;
  height: 24px;
}
.lhs-tab__ticklist-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.lhs-tab__ticklist-title {
  color: #0070df;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.lhs-tab__ticklist-bullets {
  gap: 21px;
  display: flex;
  flex-direction: column;
}
.lhs-tab__ticklist-bullet {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  color: #273A53;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
}
.lhs-tab__ticklist-bullet p {
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
}
.lhs-tab__ticklist-bullet ul {
  list-style-type: disc;
}
.lhs-tab__ticklist-bullet ul li {
  margin-left: 20px;
  line-height: 24px;
  font-size: 13px;
}
.lhs-tab__ticklist-bullet:last-child {
  padding-bottom: 0;
}
.lhs-tab__bottom-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.lhs-tab__bottom-title {
  color: #0070df;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.lhs-tab__bottom-image {
  width: 32px;
  height: 32px;
}
.lhs-tab__bottom-items-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding-bottom: 0;
}
.lhs-tab__bottom-item {
  display: flex;
  flex-direction: row;
  gap: 13px;
}
.lhs-tab__bottom-item-title {
  color: #0070df;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.lhs-tab__bottom-item-image {
  width: 32px;
  height: 32px;
}
.lhs-tab__bottom-item-content {
  color: #273A53;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}

@media (max-width: 991.98px) {
  .lhs-tab {
    padding: 32px 26px;
    font-size: 12px;
  }
  .lhs-tab__tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .lhs-tab__radio + .lhs-tab__label {
    width: 100%;
    text-align: center;
    padding: 10px 16px;
  }
  .lhs-tab__containers {
    flex-direction: column;
    gap: 32px;
  }
  .lhs-tab__card {
    gap: 32px;
  }
  .lhs-tab__rhs-items-container {
    gap: 16px;
  }
  .lhs-tab__rhs-item {
    gap: 10px;
  }
}
html[dir=rtl] .lhs-tab {
  font-family: DIN;
}
html[dir=rtl] .lhs-tab__ticklist-bullet {
  font-family: DIN;
}
html[dir=rtl] .lhs-tab__ticklist-bullet p {
  font-family: DIN;
}
html[dir=rtl] .lhs-tab__bottom-item-content {
  font-family: DIN;
}

/*=============================================
=            link            =
=============================================*/
.link {
  cursor: pointer;
  padding: 12px 27px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  gap: 8px;
  min-width: fit-content;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.link--underline .link__label {
  text-decoration: underline;
}
.link--primary {
  background-color: #273a53;
  color: #fff;
}
.link--white-rounded {
  background-color: #fff;
  color: #0070df;
  border-radius: 12px;
}
.link--white-rounded:hover, .link--white-rounded:focus {
  background-color: #273a53;
}
.link--secondary {
  background-color: #0070df;
  color: #fff;
}
.link--secondary-large {
  background-color: #0070df;
  color: #fff;
  padding: 19px 55.5px;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}
.link--secondary-large:hover, .link--secondary-large:focus {
  background-color: #273a53;
  color: #fff;
}
@media (max-width: 1249.98px) {
  .link--secondary-large {
    padding: 12px 27px;
    font-size: 14px;
  }
}
.link--secondary-large-white {
  background-color: #fff;
  color: #0070df;
  padding: 19px 55.5px;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}
.link--secondary-large-white:hover, .link--secondary-large-white:focus {
  background-color: #273a53;
  color: #fff;
}
@media (max-width: 1249.98px) {
  .link--secondary-large-white {
    padding: 12px 27px;
    font-size: 14px;
  }
}
.link--secondary:hover, .link--secondary:focus {
  background-color: #273a53;
  color: #fff;
}
.link--outline {
  background-color: #fff;
  color: #273a53;
  border: 2px solid #273a53;
}
.link--outline:hover, .link--outline:focus {
  color: #0070df;
  border-color: #0070df;
}
.link--simple-primary {
  color: #273a53;
  gap: 10px;
}
.link--simple-primary:hover, .link--simple-primary:focus {
  color: #0070df;
}
.link--simple-secondary {
  color: #0070df;
  gap: 10px;
}
.link--simple-secondary:hover, .link--simple-secondary:focus {
  color: #273a53;
}
.link--simple-white {
  color: #fff;
  gap: 10px;
}
.link--simple-white:hover, .link--simple-white:focus {
  color: #0070df;
}

html[dir=rtl] .link {
  font-size: 18px;
  font-weight: 500;
  line-height: 49px;
  padding-block: 0;
}
html[dir=rtl] .link .zmdi-arrow-right, html[dir=rtl] .link .zmdi-arrow-left {
  transform: scaleX(-1) translateY(3px);
}
html[dir=rtl] .link--secondary-large-white {
  line-height: 49px;
}
html[dir=rtl] .link__label {
  transform: translateY(-3px);
}

/*=====  End of link  ======*/
/*=============================================
=            Location Item            =
=============================================*/
.location label.location__label {
  position: static;
}
.location select.location__select {
  padding: 14px;
  border-radius: 0;
  background-size: 19px;
  background-position-x: calc(100% - 13px);
  line-height: 1.4;
}
.location__map-container {
  margin: 25px 0;
}
.location__title {
  color: #0070df;
  font-size: 17px;
  font-weight: 600;
  line-height: 48px;
  margin-top: 16.5px;
}
.location__address, .location__phone, .location__times {
  width: 100%;
  gap: 12px;
  color: #273a53;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}
.location__icon {
  height: 24px;
  width: 24px;
}

html[dir=rtl] .location select.location__select {
  background-position-x: 20px;
  font-family: "Din", sans-serif;
}

/*=====  End of Location Item  ======*/
/*=============================================
=            Logo Grid            =
=============================================*/
.logo-grid {
  max-width: 1178px;
  margin: 0 auto;
  padding: 56px 26px;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 37px;
}
@media (max-width: 767.98px) {
  .logo-grid {
    padding: 48px 26px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
}
.logo-grid__image {
  width: fit-content;
  object-fit: contain;
  vertical-align: middle;
  margin: auto;
}

/*=====  End of Logo Grid  ======*/
/*=============================================
=            App Download CTA            =
=============================================*/
.media-with-text {
  max-width: 1150px;
}
.media-with-text__inner {
  color: #fff;
  margin-inline: 24px;
}
@media (max-width: 767.98px) {
  .media-with-text__inner {
    flex-direction: column;
    margin-block-end: 0;
  }
}
.media-with-text__left {
  min-height: 100%;
  flex: 0.94;
}
.media-with-text__left img {
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .media-with-text__left img {
    max-height: 206px;
  }
}
.media-with-text__right {
  flex: 1;
  background-color: #0070df;
  padding: 56px 40px;
}
@media (max-width: 767.98px) {
  .media-with-text__right {
    padding: 24px;
  }
}
.media-with-text__title {
  font-size: 36px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
  margin-block-end: 8px;
}
@media (max-width: 767.98px) {
  .media-with-text__title {
    max-width: 265px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
  }
}
.media-with-text__sub-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-block-end: 24px;
}
@media (max-width: 767.98px) {
  .media-with-text__sub-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
}
.media-with-text__rte {
  max-width: 450px;
}
.media-with-text__rte p {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.media-with-text__button-title {
  max-width: 400px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-block: 32px 24px;
}
@media (max-width: 767.98px) {
  .media-with-text__button-title {
    max-width: 270px;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .media-with-text__download--desktop {
    display: none;
  }
}
.media-with-text__download--mobile {
  background-color: #0070df;
  margin-block-start: 40px;
  padding-block-end: 32px;
}
@media (min-width: 768px) {
  .media-with-text__download--mobile {
    display: none;
  }
}

html[dir=rtl] .media-with-text__title {
  font-size: 36px;
  font-weight: 500;
  line-height: 38.21px;
  margin-block-end: 8px;
}
html[dir=rtl] .media-with-text__sub-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 38.21px;
  margin-block-end: 12px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .media-with-text__sub-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 38.21px;
    margin-block-end: 14px;
  }
}
html[dir=rtl] .media-with-text__button-title {
  max-width: 430px;
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  margin-block: 18px 68px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .media-with-text__button-title {
    margin-block: 48px 24px;
  }
}
@media (max-width: 767.98px) {
  html[dir=rtl] .media-with-text__right {
    padding-block: 48px;
  }
}
html[dir=rtl] .media-with-text__rte {
  max-width: 450px;
}
html[dir=rtl] .media-with-text__rte p {
  line-height: 28px;
}
html[dir=rtl] .media-with-text__rte br {
  height: 12px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .media-with-text__download--mobile {
    padding-block-end: 48px;
  }
}

/*=====  End of App Download CTA  ======*/
/*=============================================
=            Mobile Menu            =
=============================================*/
.menu-mobile__icon {
  cursor: pointer;
}
.menu-mobile__inner-li {
  text-decoration: underline;
}
.menu-mobile__inner-li-title {
  cursor: none;
  text-decoration: none;
  font-weight: 600;
}
.menu-mobile__overlay {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: -3000px;
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.menu-mobile__overlay--active {
  opacity: 1;
  visibility: visible;
  right: 0;
  top: 100%;
  width: 100%;
  height: 100vh;
  z-index: 99999;
}
.menu-mobile__column-trigger {
  cursor: pointer;
  list-style: none;
  position: relative;
}
.menu-mobile__column-trigger summary::-webkit-details-marker {
  display: none;
}
.menu-mobile__column-trigger::after {
  content: "";
  color: #0070df;
  border-right: 1px solid;
  border-bottom: 1px solid;
  position: absolute;
  inset-inline-end: -16px;
  inset-block-start: 23px;
  height: 8px;
  width: 8px;
  transform: rotate(45deg) translatex(-2px) translatey(0px);
}
@media (min-width: 1250px) {
  .menu-mobile__container {
    display: none;
  }
}
.menu-mobile__menu {
  overflow: auto;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-left: auto;
  padding: 40px 42px;
  gap: 16px;
}
.menu-mobile__menu--divider {
  width: 100%;
  height: 1px;
  background: rgba(38, 25, 27, 0.1);
  background: #fff;
}
.menu-mobile__main {
  gap: 5px;
  letter-spacing: 0.5px;
}
.menu-mobile__main details[open] > summary::after {
  transform: rotate(225deg);
}
.menu-mobile__main-link {
  position: relative;
  width: fit-content;
  color: #273a53;
  font-family: "go";
  font-size: 15px;
  font-weight: 600;
  line-height: 53px;
  cursor: pointer;
}
.menu-mobile__main-link-alt::after {
  content: "";
}
.menu-mobile__main-li {
  padding-inline-start: 5px;
  font-family: "go";
  text-underline-offset: 2px;
  text-decoration-thickness: 0.5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 46px;
  cursor: pointer;
}
.menu-mobile__main .zmdi-chevron-down:before {
  margin-inline-start: 8px;
  color: #0070df;
  font-size: 20px;
}
.menu-mobile__main .zmdi-chevron-left:before {
  margin-inline-end: 5px;
  color: #0070df;
}
.menu-mobile__column {
  gap: 5px;
}
.menu-mobile__column-li {
  padding: 10px 0;
}
.menu-mobile__column-link {
  color: #273a53;
  display: inline-block;
  width: 100%;
}
.menu-mobile__icon {
  width: 24px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.menu-mobile__icon-bar {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.menu-mobile__icon-bar:nth-child(1) {
  top: 0px;
  width: 60%;
  right: 0;
  left: auto;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.menu-mobile__icon-rt:nth-child(1) {
  top: 0px;
  width: 60%;
  right: auto;
  left: 0;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.menu-mobile__icon-bar:nth-child(2) {
  top: 9px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.menu-mobile__icon-bar:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.menu-mobile__icon--open .menu-mobile__icon-bar:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 1px;
  left: 8px;
  right: auto;
  width: 100%;
  height: 2px;
  background-color: #0070df;
}
.menu-mobile__icon--open .menu-mobile__icon-bar:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.menu-mobile__icon--open .menu-mobile__icon-bar:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 18px;
  left: 8px;
  height: 2px;
  background-color: #0070df;
}

html[dir=rtl] .menu-mobile__main .zmdi-chevron-left {
  transform: rotate(180deg);
}
html[dir=rtl] .menu-mobile__main .zmdi-chevron-left:before {
  margin-inline-start: 5px;
  margin-inline-end: initial;
}
html[dir=rtl] .menu-mobile__menu-back-icon {
  transform: scale(-1);
}
html[dir=rtl] .menu-mobile__inner-li,
html[dir=rtl] .menu-mobile__main-link {
  font-family: "Din", sans-serif;
}

/*=====  End of Mobile Menu  ======*/
/*=============================================
=            navbar            =
=============================================*/
.navbar {
  color: #273a53;
  position: relative;
  padding: 20px 10px;
  font-family: Lato;
}
@media (max-width: 1249.98px) {
  .navbar {
    justify-content: space-between;
    padding-inline-start: 17px;
    padding-inline-end: 24px;
  }
}
@media (max-width: 479.98px) {
  .navbar {
    padding: 11.5px 24px 11.5px 17px;
  }
}
.navbar-wrapper {
  background-color: #fff;
}
.navbar__breadcrumbs {
  color: #fff;
  padding: 13.5px 6px;
  width: 100%;
  flex-wrap: wrap;
}
.navbar__breadcrumbs-wrapper {
  background-color: #0070df;
}
.navbar__breadcrumbs-wrapper--visible {
  display: flex;
}
.navbar__breadcrumb {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  color: rgba(155, 205, 255, 0.5);
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  margin-inline-start: 20px;
  padding-inline-start: 20px;
  border-inline-start: 1px solid #fff;
  position: relative;
}
@media (max-width: 767.98px) {
  .navbar__breadcrumb {
    display: none;
    padding-inline-start: 10px;
  }
}
@media (max-width: 767.98px) {
  .navbar__breadcrumb:first-child, .navbar__breadcrumb:last-child {
    display: inline-block;
  }
}
.navbar__breadcrumb:last-child {
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.5px;
  opacity: 1;
  color: #fff;
}
@media (max-width: 767.98px) {
  .navbar__breadcrumb:last-child {
    border: none;
  }
}
@media (max-width: 767.98px) and (max-width: 767.98px) {
  .navbar__breadcrumb:last-child::before {
    position: absolute;
    inset-inline-start: 0px;
    content: "|";
    font-size: 16px;
    color: rgba(155, 205, 255, 0.5);
  }
}
.navbar__breadcrumb:first-child {
  border: none;
  margin-inline-start: 0;
  padding-inline-start: 0;
}
@media (max-width: 767.98px) {
  .navbar__breadcrumb:first-child {
    padding-inline-end: 10px;
  }
}
@media (max-width: 767.98px) {
  .navbar__breadcrumb:first-child::before {
    position: absolute;
    inset-inline-end: 0px;
    content: "|";
    font-size: 16px;
  }
}
@media (max-width: 767.98px) {
  .navbar__breadcrumb:first-child::after {
    position: absolute;
    inset-inline-end: -15px;
    content: "...";
    font-size: 16px;
  }
}
.navbar__links {
  width: 100%;
  gap: 42px;
  font-size: 15px;
  font-weight: 700;
  line-height: 62px;
  margin: 0;
  margin-inline-start: 64px;
}
@media (max-width: 1249.98px) {
  .navbar__links {
    display: none;
  }
}
.navbar__links--hidden {
  opacity: 0;
  visibility: hidden;
}
.navbar__inner {
  padding: 11.5px 26px;
}
.navbar__inner-links {
  gap: 30px;
  width: 210px;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  position: absolute;
  z-index: 2;
  color: #273a53;
  padding: 30px 20px;
  padding-block-start: 15px;
  box-shadow: 5px 12px 24px -8px rgba(149, 157, 165, 0.3);
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.navbar__inner-links:focus-within {
  opacity: 1;
  visibility: visible;
}
.navbar__inner-link {
  font-weight: 500;
  line-height: 16px;
  width: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.navbar__inner-link:hover, .navbar__inner-link:focus {
  color: #0070df;
  text-shadow: 0 0 1px #0070df;
}
.navbar__link {
  cursor: pointer;
  min-width: fit-content;
}
.navbar__link-icon {
  margin-inline-start: 5px;
}
.navbar__link:hover .navbar__inner-links {
  opacity: 1;
  visibility: visible;
}
.navbar__link:focus .navbar__inner-links {
  opacity: 1;
  visibility: visible;
}
.navbar__link-icon {
  transform: translateY(2px);
  color: #0070df;
}
.navbar__logo {
  height: 100%;
  width: 100%;
  min-width: fit-content;
}
.navbar__left {
  height: 62px;
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 479.98px) {
  .navbar__left {
    height: 69px;
    width: 104px;
  }
}
.navbar__language {
  cursor: default;
}
@media (max-width: 1249.98px) {
  .navbar__language {
    margin-inline-end: 10px;
  }
}
.navbar__language--desktop {
  min-width: 62px;
  color: #6B798F;
  font-size: 12px;
  margin-inline-start: auto;
}
@media (max-width: 1249.98px) {
  .navbar__language--desktop {
    display: none;
  }
}
.navbar__language--mobile {
  text-align: center;
  margin-block-start: 50px;
  background-color: #f7f9fb;
  width: 100%;
  padding: 8px 10px;
}
@media (min-width: 1250px) {
  .navbar__language--mobile {
    display: none;
  }
}
.navbar__language--mobile .navbar__language-dropdown {
  top: unset;
  transform: translateY(5px) translateX(-8%);
}
.navbar__language--mobile-inner {
  display: block;
  width: 100%;
  font-family: "Din", sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.navbar__language-selected {
  font-weight: 600;
  font-size: 13px;
  pointer-events: none;
}
.navbar__language-icon {
  pointer-events: none;
  color: #91a1b6;
  transform: translateY(2px);
  margin-inline-start: 3px;
}
.navbar__language-icon--active {
  transform: translateY(2px) rotate(180deg);
}
.navbar__language-dropdown {
  font-size: 13px;
  gap: 15px;
  max-width: 180px;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  position: absolute;
  width: max-content;
  z-index: 2;
  color: #273a53;
  padding: 15px 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding-inline-end: 50px;
  line-height: 30px;
}
.navbar__language-dropdown li[lan-code=ar] {
  font-family: "DIN";
}
.navbar__language-dropdown li:hover {
  color: #0070df;
}
.navbar__language-dropdown--active {
  opacity: 1;
  visibility: visible;
}
.navbar__language-dropdown-title {
  font-weight: 700;
  pointer-events: none;
  cursor: default;
}
.navbar__search {
  position: relative;
  cursor: pointer;
  margin: auto;
  margin-right: 7px;
}
.navbar__search-label {
  cursor: pointer;
  margin: auto;
}
.navbar__search-label--hidden {
  opacity: 0;
  visibility: hidden;
}
.navbar__search-icon {
  transform: translateY(3px);
}
.navbar__search-icon--hidden {
  opacity: 0;
  visibility: hidden;
}
.navbar__search-input {
  width: 0px;
  height: 36px;
  opacity: 0;
  font-family: inherit;
  color: #273a53;
  padding: 5px 0;
  letter-spacing: 1px;
  margin-inline: 5px;
  border-radius: 4px;
  transition: opacity 0.5s ease-in-out;
}
.navbar__search-input--active {
  position: absolute;
  z-index: 1;
  right: 0;
  width: 285px;
  transform: translateY(1px);
  opacity: 1;
  padding: 5px 10px;
  border: 1px solid #cdcdcd;
}
@media (max-width: 479.98px) {
  .navbar__search-input--active {
    width: 115px;
  }
}
.navbar__search-cross {
  display: none;
  opacity: 0;
  position: absolute;
  inset-inline-end: 10px;
  inset-block-start: 50%;
  transform: translateY(-45%);
  width: 20px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.navbar__search-cross--active {
  display: block;
  opacity: 1;
}
.navbar__button {
  min-width: fit-content;
  margin-inline-end: 16px;
}
@media (max-width: 479.98px) {
  .navbar__button {
    display: none;
  }
}
.navbar__menu-button {
  cursor: pointer;
}
@media (min-width: 1250px) {
  .navbar__menu-button {
    display: none;
  }
}

#nav-bottom {
  height: 5px;
  background-color: #0070df;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  opacity: 0;
  z-index: 999999;
}

html[dir=rtl] .navbar {
  font-family: "Din", sans-serif;
}
html[dir=rtl] .navbar__language--mobile-inner {
  font-family: Go;
}

/*=====  End of navbar  ======*/
/*=============================================
=            News Detail            =
=============================================*/
.news-detail {
  padding: 0 26px;
  position: relative;
}
.news-detail__heading {
  color: #0070df;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
  padding-block-start: 56px;
  padding-block-end: 24px;
}
@media (max-width: 767.98px) {
  .news-detail__heading {
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;
  }
}
.news-detail__wrapper {
  padding-bottom: 76px;
  background-color: #F7F9FB;
}
@media (max-width: 767.98px) {
  .news-detail__wrapper {
    padding-bottom: 50px;
  }
}
.news-detail__close {
  position: absolute;
  top: 21px;
  right: 43px;
  cursor: pointer;
}
.news-detail__close-icon {
  font-size: 24px;
  color: #808082;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.news-detail__close:hover .news-detail__close-icon, .news-detail__close:focus .news-detail__close-icon {
  color: #0070df;
}
.news-detail__inner {
  gap: 24px;
}
.news-detail__headline {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #273a53;
  margin-bottom: 8px;
}
.news-detail__date {
  color: #0070df;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  margin-bottom: 13px;
}
.news-detail__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.news-detail__image-container {
  height: 100%;
  width: 100%;
  max-height: 337px;
  overflow: hidden;
}
.news-detail__item {
  background-color: #fff;
}
.news-detail__item-inner {
  gap: 20px;
  padding-bottom: 100px;
  padding-inline: 24px 40px;
}
@media (max-width: 767.98px) {
  .news-detail__item-inner {
    padding-bottom: 30px;
    padding-inline: 30px;
  }
}
.news-detail__item-top {
  width: 100%;
  padding: 53px 30px 36px;
}
.news-detail .rte {
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #273a53;
}

/*=====  End of News Detail  ======*/
/*=============================================
=            News Room            =
=============================================*/
.news-room {
  max-width: 1178px;
  margin: 0 auto;
  padding-block: 56px;
}
.news-room__wrapper {
  background-color: #f7f9fb;
}
@media (max-width: 767.98px) {
  .news-room {
    padding: 24px 26px 62px;
  }
}
.news-room__header {
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .news-room__header {
    margin-bottom: 20px;
  }
}
@media (max-width: 479.98px) {
  .news-room__header {
    justify-content: end;
  }
}
.news-room__heading {
  color: #0070df;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
}
@media (max-width: 767.98px) {
  .news-room__heading {
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.5px;
  }
}
@media (max-width: 479.98px) {
  .news-room__heading {
    display: none;
  }
}
.news-room__select {
  font-family: "Go", sans-serif !important;
  font-weight: 600;
  background-color: #fff;
  padding: 8px 0;
  padding-inline: 14px 51px;
  border-radius: 6px;
  position: relative;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/chevron-down-gray.svg");
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  z-index: 9999;
  cursor: pointer;
  box-shadow: 0px 0px 7px rgba(27, 29, 32, 0.2);
}
.news-room__option {
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  color: #2c2c2e;
  font-family: inherit;
}
.news-room__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 24px;
}
@media (max-width: 767.98px) {
  .news-room__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.news-room__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-room__image-container {
  width: 229px;
  height: 229px;
  margin: auto;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .news-room__image-container {
    width: 138px;
    height: 138px;
  }
}
.news-room__left {
  margin: auto;
  display: none;
  flex: 0.7;
  padding: 32px;
}
.news-room__card {
  background-color: #273a53;
  background-color: #fff;
  color: #273a53;
  flex-direction: row;
  padding-inline: 40px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767.98px) {
  .news-room__card {
    flex-direction: column;
  }
}
.news-room__card:hover, .news-room__card:focus {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: scale(1.01);
}
.news-room__card:nth-child(4n-3), .news-room__card:nth-child(4n) {
  grid-column: span 2;
  flex-direction: row-reverse;
  align-items: center;
}
@media (max-width: 767.98px) {
  .news-room__card:nth-child(4n-3), .news-room__card:nth-child(4n) {
    flex-direction: column;
    grid-column: span 1;
  }
}
.news-room__card:nth-child(4n-3) .news-room__left, .news-room__card:nth-child(4n) .news-room__left {
  display: block;
}
.news-room__card:nth-child(4n-3) .news-room__title,
.news-room__card:nth-child(4n-3) .news-room__subtitle, .news-room__card:nth-child(4n) .news-room__title,
.news-room__card:nth-child(4n) .news-room__subtitle {
  margin-block-end: 12px;
}
.news-room__card:nth-child(4n-3) {
  flex-direction: row;
  align-items: center;
}
@media (max-width: 767.98px) {
  .news-room__card:nth-child(4n-3) {
    flex-direction: column;
  }
}
.news-room__card:nth-child(4n-3) .news-room__image-container {
  width: 392px;
  height: 310px;
  margin: auto;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .news-room__card:nth-child(4n-3) .news-room__image-container {
    width: 138px;
    height: 138px;
  }
}
.news-room__card:nth-child(4n-3) .news-room__title,
.news-room__card:nth-child(4n-3) .news-room__subtitle {
  margin-block-end: 12px;
}
.news-room__title {
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}
@media (max-width: 767.98px) {
  .news-room__title {
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;
  }
}
.news-room__subtitle {
  color: #0070df;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}
.news-room__text {
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #35475e;
}
@media (max-width: 767.98px) {
  .news-room__text {
    font-size: 14px;
    line-height: 22px;
  }
}
.news-room__content {
  color: #273a53;
  padding-block: 32px;
  flex: 1;
}
@media (max-width: 767.98px) {
  .news-room__content {
    padding: 20.5px 0 26px;
    padding-inline: 20px 30px;
  }
}
.news-room__content .link {
  margin-inline-start: auto;
  font-weight: 500;
  padding: 12px 0 0 0;
  transform: translateX(22px);
}
@media (max-width: 767.98px) {
  .news-room__content .link {
    transform: translateX(14px);
  }
}
.news-room__link {
  color: #0070df;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin: auto;
  margin-top: 25px;
}
@media (min-width: 768px) {
  .news-room__link {
    display: none;
  }
}
.news-room__link-icon {
  font-size: 16px;
}

html[dir=rtl] .news-room__select {
  position: relative;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/chevron-down-gray.svg");
  background-position-x: 20px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  z-index: 9999;
  cursor: pointer;
}

/*=====  End of News Room  ======*/
/*=============================================
=            Not Found            =
=============================================*/
.not-found {
  min-height: 1051px;
}
@media (max-width: 479.98px) {
  .not-found {
    min-height: 745px;
  }
}
.not-found__inner {
  padding: 0 26px;
  color: #273a53;
  min-height: inherit;
  padding-top: 208px;
  text-align: center;
  gap: 28px;
}
@media (max-width: 479.98px) {
  .not-found__inner {
    padding-top: 172px;
  }
}
.not-found__image {
  height: 100%;
  width: 100%;
}
.not-found__image-container {
  max-width: 182px;
}
@media (max-width: 479.98px) {
  .not-found__image-container {
    max-width: 130px;
  }
}
.not-found__title {
  font-size: 30px;
  font-weight: 500;
  line-height: 32px;
}
@media (max-width: 479.98px) {
  .not-found__title {
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.5px;
  }
}
.not-found__text {
  color: #808082;
  font-family: Lato;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 479.98px) {
  .not-found__text {
    font-family: "Lato";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
}
.not-found__text a {
  border-bottom: 1px solid #808082;
}
.not-found__text a:hover, .not-found__text a:focus {
  color: #0070df;
  border-color: #0070df;
}
.not-found .link {
  font-family: "Go", sans-serif;
  padding: 18px 54px;
  font-size: 15px;
  line-height: 22px;
}
@media (max-width: 479.98px) {
  .not-found .link {
    width: 100%;
  }
}

/*=====  End of Not Found  ======*/
/*=============================================
=            Overview Tiles            =
=============================================*/
.overview-tiles {
  background-color: #fff;
}
.overview-tiles__inner {
  padding-block: 56px;
  gap: 64px;
}
@media (max-width: 1249.98px) {
  .overview-tiles__inner {
    padding-inline: 24px;
  }
}
@media (max-width: 767.98px) {
  .overview-tiles__inner {
    gap: 24px;
  }
}
.overview-tiles__heading {
  gap: 16px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .overview-tiles__heading {
    gap: 8px;
  }
}
.overview-tiles__header {
  color: #000;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
}
html[dir=rtl] .overview-tiles__header {
  font-family: "Din", sans-serif;
  font-size: 44px;
  line-height: 64.68px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .overview-tiles__header {
    font-size: 24px;
    line-height: 35.28px;
  }
}
@media (max-width: 767.98px) {
  .overview-tiles__header {
    font-size: 24px;
    line-height: 28.8px;
  }
}
.overview-tiles__subtitle {
  font-weight: 300;
  font-size: 24px;
  line-height: 28.8px;
}
@media (max-width: 767.98px) {
  .overview-tiles__subtitle {
    font-size: 18px;
    line-height: 21.6px;
  }
}
html[dir=rtl] .overview-tiles__subtitle {
  font-family: "Din", sans-serif;
  line-height: 35.28px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .overview-tiles__subtitle {
    font-size: 16px;
    line-height: 23.52px;
  }
}
.overview-tiles__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 21px;
}
@media (max-width: 991.98px) {
  .overview-tiles__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.overview-tiles__item {
  background-color: #f7f9fb;
  border-radius: 24px;
  min-height: 303px;
  padding: 32px;
  gap: 31px;
}
@media (max-width: 767.98px) {
  .overview-tiles__item {
    min-height: 225px;
  }
}
.overview-tiles__item-two {
  padding-bottom: 0;
}
.overview-tiles__item-two .overview-tiles__item-image {
  max-width: 332px;
  object-fit: contain;
  margin-top: auto;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-two .overview-tiles__item-image {
    max-width: 221px;
  }
}
.overview-tiles__item-three {
  position: relative;
  padding: 48px 40px 40px;
  background-color: #0070df;
  color: #fff;
  gap: 70px;
}
.overview-tiles__item-three::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("/assets/img/img/overview-tiles-pattern.png");
  background-repeat: no-repeat;
  background-size: cover;
}
html[dir=rtl] .overview-tiles__item-three::after {
  transform: scaleX(-1);
}
@media (max-width: 767.98px) {
  .overview-tiles__item-three {
    gap: 23px;
    padding: 19px 38px 28px;
    background-position: 100%;
  }
}
.overview-tiles__item-three .overview-tiles__item-title {
  max-width: 206px;
  text-align: start;
}
html[dir=rtl] .overview-tiles__item-three .overview-tiles__item-title {
  max-width: none;
}
@media (max-width: 991.98px) {
  .overview-tiles__item-three .overview-tiles__item-title {
    text-align: center;
    max-width: none;
  }
}
.overview-tiles__item-three .overview-tiles__item-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.overview-tiles__item-three .overview-tiles__item-image-container {
  height: 92px;
  width: 92px;
  min-height: 92px;
  min-width: 92px;
  border-radius: 28.58px;
  box-shadow: 0px 3.57px 15.18px 4.18px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 19px;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-three .overview-tiles__item-image-container {
    height: 59px;
    width: 59px;
    min-height: 59px;
    min-width: 59px;
    padding: 12px;
    border-radius: 18.33px;
  }
}
.overview-tiles__item-four {
  padding-bottom: 30px;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-four {
    padding-bottom: 27.46px;
    gap: 24px;
  }
}
.overview-tiles__item-content {
  gap: 8px;
}
.overview-tiles__item-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-title {
    font-size: 18px;
    line-height: 21.6px;
  }
}
html[dir=rtl] .overview-tiles__item-title {
  font-family: "Din", sans-serif;
  line-height: 35.28px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .overview-tiles__item-title {
    font-size: 16.35px;
    line-height: 24.04px;
  }
}
.overview-tiles__item-text {
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 991.98px) {
  .overview-tiles__item-text {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .overview-tiles__item-text {
    font-size: 16px;
    line-height: 19.2px;
  }
}
html[dir=rtl] .overview-tiles__item-text {
  font-family: "Din", sans-serif;
  font-size: 19px;
  line-height: 27.93px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .overview-tiles__item-text {
    font-size: 12.94px;
    line-height: 19.03px;
  }
}
.overview-tiles__item-store {
  width: auto;
  height: 51px;
  object-fit: contain;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.overview-tiles__item-store:hover, .overview-tiles__item-store:focus {
  opacity: 0.7;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-store {
    height: 38px;
  }
}
.overview-tiles__item-top {
  width: 100%;
  gap: 24px;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .overview-tiles__item-top {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.overview-tiles__item-bottom {
  gap: 24px;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .overview-tiles__item-bottom {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .overview-tiles__item-bottom {
    gap: 18px;
  }
}
.overview-tiles__item-rows {
  gap: 11px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-rows {
    gap: 8.88px;
  }
}
.overview-tiles__item-row {
  box-shadow: 0px 3.57px 15.18px 4.18px rgba(0, 0, 0, 0.0509803922);
  padding: 13px 31px 13px 13px;
  gap: 8px;
  border-radius: 16px;
  width: 100%;
  max-width: 238px;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-row {
    gap: 5.46px;
    max-width: 162px;
    padding: 9px 21.8px 9px 11px;
  }
}
.overview-tiles__item-row:nth-child(2) {
  margin-left: 120px;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-row:nth-child(2) {
    margin-left: 80px;
  }
}
.overview-tiles__item-row:nth-child(3) {
  margin-right: 90px;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-row:nth-child(3) {
    margin-right: 60px;
  }
}
.overview-tiles__item-row-icon {
  height: 24px;
  width: 24px;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-row-icon {
    height: 16.39px;
    width: 16.39px;
  }
}
.overview-tiles__item-row-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
  color: #2c2c2e;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-row-text {
    font-size: 10.93px;
    line-height: 13.12px;
  }
}
html[dir=rtl] .overview-tiles__item-row-text {
  font-family: "Din", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 23.52px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .overview-tiles__item-row-text {
    font-size: 10.09px;
    line-height: 16.02px;
  }
}
.overview-tiles__item-row-dash {
  background-color: #e7e7e7;
  border-radius: 1000px;
  height: 13px;
  width: 100%;
  max-width: 146px;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-row-dash {
    max-width: 99.73px;
    height: 8.88px;
  }
}
.overview-tiles__item-row-dash-short {
  min-width: 66px;
  max-width: 66px;
}
@media (max-width: 767.98px) {
  .overview-tiles__item-row-dash-short {
    min-width: 45.09px;
    max-width: 45.09px;
  }
}
.overview-tiles__application {
  background-color: #fff;
  box-shadow: 0px 3.57px 15.18px 4.18px rgba(0, 0, 0, 0.0509803922);
  border-radius: 16px;
  padding: 14px 17px;
  width: 100%;
  max-width: 352px;
  gap: 18px;
}
@media (max-width: 767.98px) {
  .overview-tiles__application {
    gap: 11.71px;
    padding: 9.11px 11px 14.96px;
  }
}
.overview-tiles__application-top {
  width: 100%;
}
html[dir=rtl] .overview-tiles__application-top {
  flex-direction: row-reverse;
}
.overview-tiles__application-bottom {
  gap: 8px;
}
.overview-tiles__application-circle {
  border-radius: 50%;
  background-color: #e7e7e7;
  border: 4px solid #fff;
  height: 41px;
  width: 41px;
}
@media (max-width: 767.98px) {
  .overview-tiles__application-circle {
    height: 26.67px;
    width: 26.67px;
  }
}
.overview-tiles__application-circle-tick {
  box-shadow: 0px 3.57px 15.18px 4.18px rgba(0, 0, 0, 0.0509803922);
  border: none;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .overview-tiles__application-circle-tick svg {
    height: 15.61px;
    width: 15.61px;
  }
}
.overview-tiles__application-dash {
  display: flex;
  background-color: #f4f4f4;
  flex: 1;
  height: 5px;
  width: 100%;
  min-width: 20px;
}
@media (max-width: 767.98px) {
  .overview-tiles__application-dash {
    height: 3.5px;
  }
}
.overview-tiles__application-line {
  height: 12px;
  border-radius: 1000px;
  background-color: #e7e7e7;
  width: 110px;
}
.overview-tiles__application-line-long.overview-tiles__application-line-long {
  width: 146px;
}
@media (max-width: 767.98px) {
  .overview-tiles__application-line-long.overview-tiles__application-line-long {
    height: 8.46px;
    width: 94.98px;
  }
}
@media (max-width: 767.98px) {
  .overview-tiles__application-line {
    height: 8.46px;
    width: 71.56px;
  }
}
.overview-tiles__application-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: #141414;
}
@media (max-width: 767.98px) {
  .overview-tiles__application-title {
    font-size: 10.41px;
    line-height: 12.49px;
  }
}
html[dir=rtl] .overview-tiles__application-title {
  font-family: "Din", sans-serif;
  line-height: 23.52px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .overview-tiles__application-title {
    font-size: 10.9px;
    line-height: 16.02px;
  }
}

/*=====  End of Overview Tiles  ======*/
/*=============================================
=            Feature Personal Calculator            =
=============================================*/
.personal-calc {
  gap: 28px;
}
@media (max-width: 991.98px) {
  .personal-calc {
    gap: 24px;
  }
}
.personal-calc__inner {
  margin: 0 auto;
  background-color: #0070df;
}
@media (max-width: 991.98px) {
  .personal-calc__inner {
    flex-direction: column;
    padding: 0;
  }
}
.personal-calc__left {
  flex: 1;
  background-color: #0070df;
  color: #fff;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .personal-calc__left {
    justify-content: center;
  }
}
.personal-calc__left-inner {
  padding-inline: 0 81px;
  max-width: 560px;
}
@media (max-width: 991.98px) {
  .personal-calc__left-inner {
    padding-inline: 26px;
  }
}
.personal-calc__right {
  flex: 1;
  color: #273a53;
  position: relative;
  background-color: #0070df;
}
@media (max-width: 991.98px) {
  .personal-calc__right {
    padding: 32px 0;
    padding-inline: 0px;
    background-color: #f7f9fb;
  }
}
@media (max-width: 479.98px) {
  .personal-calc__right {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-block: 20px;
    top: 0px !important;
  }
}
.personal-calc__right-inner {
  padding: 56px 40px;
  width: fit-content;
  background-color: #FFF;
  height: calc(100% + 185px);
  display: flex;
  flex-direction: column;
  gap: 56px;
}
@media (max-width: 991.98px) {
  .personal-calc__right-inner {
    padding-inline: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    margin: auto;
    max-width: 400px;
    top: 0px !important;
    margin-bottom: 0px !important;
    padding-inline: 20px;
    gap: 36px;
  }
}
.personal-calc__title {
  font-size: 29px;
  font-weight: 700;
  line-height: 32px;
  font-family: "Lato", sans-serif;
}
@media (max-width: 991.98px) {
  .personal-calc__title {
    text-align: center;
  }
}
@media (max-width: 479.98px) {
  .personal-calc__title {
    font-size: 31px;
    font-weight: 600;
    line-height: 34px;
  }
}
.personal-calc__title-label {
  margin-inline-end: auto;
}
@media (max-width: 991.98px) {
  .personal-calc__title-label {
    font-size: 16px;
  }
}
.personal-calc__property-value, .personal-calc__salary-value {
  max-width: 100px;
}
.personal-calc__payment-amount {
  align-items: center;
  gap: 5.85px;
}
.personal-calc__min-value, .personal-calc__max-value {
  display: flex;
  gap: 7.22px;
  align-items: center;
}
.personal-calc__min-value i, .personal-calc__max-value i {
  margin-top: 3px;
}
.personal-calc__number {
  width: 125px;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: end;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  margin-inline-end: 5px;
  border: 2px solid transparent;
  border-radius: 8px;
  transform: translateY(-2px);
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 991.98px) {
  .personal-calc__number {
    font-size: 15px;
  }
}
.personal-calc__number:hover, .personal-calc__number:focus {
  border-color: #18CDB7;
}
.personal-calc__number::-webkit-inner-spin-button, .personal-calc__number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.personal-calc__text {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  margin-block: 56px;
  opacity: 0.7;
  text-align: justify;
}
@media (max-width: 1249.98px) {
  .personal-calc__text {
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: 18px;
  }
}
.personal-calc__field {
  width: 100%;
}
.personal-calc__field-inner {
  margin-top: 10px;
}
.personal-calc__field-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  font-family: Lato;
}
@media (max-width: 991.98px) {
  .personal-calc__field-title {
    font-size: 16px;
  }
}
.personal-calc__input[type=range] {
  width: 100%;
  cursor: pointer;
}
.personal-calc__input[type=radio] {
  cursor: pointer;
}
.personal-calc__label {
  margin-inline-end: 32px;
}
@media (max-width: 991.98px) {
  .personal-calc__label {
    font-size: 14px;
  }
}
.personal-calc__range-top, .personal-calc__range-bottom {
  width: 100%;
}
.personal-calc__range-top {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}
@media (max-width: 991.98px) {
  .personal-calc__range-top {
    font-size: 15px;
  }
}
.personal-calc__range-bottom {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  opacity: 0.6;
}
@media (max-width: 991.98px) {
  .personal-calc__range-bottom {
    font-size: 13px;
    line-height: 38px;
  }
}
.personal-calc input[type=range] {
  background: linear-gradient(to right, #18CDB7 0%, #18CDB7 50%, #1B82E9 50%, #1B82E9 100%);
  border-radius: 8px;
  height: 6px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  position: relative;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.personal-calc input[type=range]:focus {
  border-color: #fff;
}
.personal-calc input[type=range]::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: pointer;
  background: #fff;
  background-clip: content-box !important;
  background-image: url("/assets/img/img/pin.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 1249.98px) {
  .personal-calc input[type=range]::-webkit-slider-thumb {
    width: 32px;
    height: 32px;
  }
}
.personal-calc input[type=radio] {
  position: absolute;
  opacity: 0;
}
.personal-calc input[type=radio] + .personal-calc__label {
  cursor: pointer;
  opacity: 0.65;
}
.personal-calc input[type=radio] + .personal-calc__label:before {
  content: "";
  background: #0070DF;
  border-radius: 100%;
  border: 2px solid #fff;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-inline-end: 8px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  opacity: 0.65;
}
.personal-calc input[type=radio]:checked + .personal-calc__label {
  opacity: 1;
}
.personal-calc input[type=radio]:checked + .personal-calc__label:before {
  background-color: #fff;
  box-shadow: inset 0 0 0 3px #0070DF;
  opacity: 1;
}
.personal-calc input[type=radio]:focus + .personal-calc__label:before {
  border-color: #18CDB7;
}
.personal-calc__circle {
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
  border: 17px solid #F2F7FB;
  border-radius: 50%;
  animation: spin 30s linear infinite;
}
.personal-calc__circle-container {
  height: 385px;
  width: 385px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 991.98px) {
  .personal-calc__circle-container {
    height: 100%;
    width: 100%;
    max-width: 335px;
    margin: auto;
  }
}
@media (max-width: 479.98px) {
  .personal-calc__circle-container {
    height: 275px;
    width: 275px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.personal-calc__payment {
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.personal-calc__payment-containers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 500px;
}
@media (max-width: 1249.98px) {
  .personal-calc__payment-containers {
    min-width: 0;
    gap: 34px;
  }
}
.personal-calc__payment-upper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-end;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-upper {
    align-items: center;
  }
}
.personal-calc__payment-lower {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
@media (max-width: 479.98px) {
  .personal-calc__payment-lower {
    gap: 0;
  }
}
.personal-calc__payment-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 250px;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-container {
    gap: 5px;
    padding-block-end: 15px;
  }
}
.personal-calc__payment-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 40px;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-title {
    font-size: 14px;
  }
}
.personal-calc__payment-number {
  font-size: 49px;
  font-weight: 700;
  line-height: 70px;
  font-family: Neue Haas Grotesk Display Pro;
  color: #0070df;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-number {
    font-size: 45px;
    line-height: 36px;
  }
}
@media (max-width: 479.98px) {
  .personal-calc__payment-number {
    font-size: 47px;
    line-height: 36px;
  }
}
.personal-calc__payment-currency {
  font-size: 36px;
  font-weight: 500;
  line-height: 70px;
  color: #0070df;
  font-family: Neue Haas Grotesk Display Pro;
  margin-top: 4px;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-currency {
    font-size: 20px;
    line-height: 35px;
  }
}
.personal-calc__payment-text, .personal-calc__payment-terms {
  max-width: 475px;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-text, .personal-calc__payment-terms {
    margin: 0;
    max-width: none;
  }
}
.personal-calc__payment-text {
  line-height: 20px;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.personal-calc__payment-terms {
  color: #000;
  line-height: 30px;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  opacity: 0.3;
  margin-top: -50px;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-terms {
    margin-top: -25px;
  }
}
.personal-calc__payment-max {
  margin-top: 56px;
  margin-bottom: 8px;
}
.personal-calc__payment-max-icon, .personal-calc__payment-rate-icon {
  height: 33px;
  width: 33px;
  margin-inline-end: 3px;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-max-icon, .personal-calc__payment-rate-icon {
    height: 29px;
    width: 26px;
  }
}
.personal-calc__payment-max-amount, .personal-calc__payment-rate-amount {
  color: #273A53;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-max-amount, .personal-calc__payment-rate-amount {
    font-size: 30px;
  }
}
.personal-calc__payment-max-text, .personal-calc__payment-rate-text {
  color: #273A53;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
@media (max-width: 991.98px) {
  .personal-calc__payment-max-text, .personal-calc__payment-rate-text {
    font-size: 12px;
    font-weight: 700;
  }
}
.personal-calc__payment-symbol {
  color: #273A53;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.personal-calc__payment-symbol:has(.icon-saudi_riyal) {
  margin-bottom: 4px;
}
.personal-calc__payment-symbol .icon-saudi_riyal::after {
  height: 28.12px;
  width: 24.27px;
  filter: brightness(0) saturate(100%) invert(17%) sepia(12%) saturate(2337%) hue-rotate(174deg) brightness(103%) contrast(86%);
}
@media (max-width: 991.98px) {
  .personal-calc__payment-symbol {
    font-size: 16px;
    margin-top: 8px;
  }
}
.personal-calc__error {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #6B798F;
}
.personal-calc__error-icon {
  width: 34px;
  height: 33px;
}
.personal-calc__error-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
  display: none;
}
.personal-calc__error-wrapper--active {
  display: flex;
}
.personal-calc__error-container {
  gap: 8px;
  border: 1.5px solid #FBBC04;
  background-color: #fff;
  padding: 14px 35px 19px;
  border-radius: 12px;
  width: 500px;
  margin: 0 30px;
}

html[dir=rtl] .personal-calc {
  gap: 10px;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .personal-calc__title {
    font-size: 29px;
    margin-bottom: 40px;
  }
}
html[dir=rtl] .personal-calc__property-value-label, html[dir=rtl] .personal-calc__salary-value-label {
  margin-top: 8px;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .personal-calc__field-title, html[dir=rtl] .personal-calc__title-label, html[dir=rtl] .personal-calc__range-bottom, html[dir=rtl] .personal-calc__range-top, html[dir=rtl] .personal-calc__number {
    font-size: 17px;
  }
}
html[dir=rtl] .personal-calc__number {
  transform: translateY(2px);
}
html[dir=rtl] .personal-calc__title, html[dir=rtl] .personal-calc__title-label, html[dir=rtl] .personal-calc__label, html[dir=rtl] .personal-calc__field-title, html[dir=rtl] .personal-calc__range-top, html[dir=rtl] .personal-calc__text {
  font-family: "Din", sans-serif;
  line-height: 38px;
}
html[dir=rtl] .personal-calc__field-inner {
  margin-top: 0px;
}
html[dir=rtl] .personal-calc__text {
  font-size: 16px;
  line-height: 19px;
}
@media (max-width: 991.98px) {
  html[dir=rtl] .personal-calc__text {
    font-size: 14px;
  }
}
html[dir=rtl] .personal-calc__payment-max-amount, html[dir=rtl] .personal-calc__payment-rate-amount {
  font-size: 38px;
  line-height: 40px;
  font-weight: 700;
}
@media (max-width: 479.98px) {
  html[dir=rtl] .personal-calc__payment-max-amount, html[dir=rtl] .personal-calc__payment-rate-amount {
    font-size: 26px;
  }
}
html[dir=rtl] .personal-calc__payment-symbol {
  font-family: DIN;
  font-size: 24px;
  line-height: 38px;
}
html[dir=rtl] .personal-calc__payment-max-text, html[dir=rtl] .personal-calc__payment-rate-text {
  color: #273A53;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: DIN;
}
html[dir=rtl] .personal-calc__payment-currency {
  font-family: "Din", sans-serif;
  font-size: 24px;
  font-weight: 400;
}
html[dir=rtl] .personal-calc__payment-text {
  font-family: "Din", sans-serif;
  font-size: 13px;
}
html[dir=rtl] .personal-calc__payment-terms {
  font-size: 14px;
}
html[dir=rtl] .personal-calc__wrapper {
  background: linear-gradient(270deg, #0070df 50%, transparent 50%);
}
@media (max-width: 991.98px) {
  html[dir=rtl] .personal-calc__wrapper {
    background: linear-gradient(180deg, #0070df 50%, transparent 50%);
  }
}
html[dir=rtl] .personal-calc__payment-container {
  gap: 1px;
}
html[dir=rtl] .personal-calc__payment-containers {
  min-width: 0px;
  gap: 42px;
}
@media (min-width: 480px) {
  html[dir=rtl] .personal-calc__payment {
    width: 167px;
  }
}
html[dir=rtl] .personal-calc__salary-value-label i::after, html[dir=rtl] .personal-calc__min-value i::after, html[dir=rtl] .personal-calc__max-value i::after {
  margin-bottom: 0px;
}
html[dir=rtl] input[type=radio] + .personal-calc__label:before {
  top: 10px;
}
html[dir=rtl] input[type=range] {
  background: linear-gradient(to left, #18CDB7 0%, #18CDB7 50%, #1B82E9 50%, #1B82E9 100%);
}

/*=====  End of Feature Personal Calculator  ======*/
.plain-text {
  padding: 56px 32px;
  background-color: #f7f9fb;
}
@media (max-width: 479.98px) {
  .plain-text {
    padding: 32px;
    font-size: 12px;
  }
}
.plain-text--inline {
  margin: 0;
  max-width: none;
}
.plain-text--white {
  color: #fff;
}
.plain-text__title {
  margin-block-end: 10px;
}
.plain-text__subtitle {
  margin-block-end: 56px;
}
.plain-text h1,
.plain-text h2,
.plain-text h3,
.plain-text h4,
.plain-text h5 {
  display: block;
  margin-block: 24px;
  font-weight: 400;
}
.plain-text h1:first-child,
.plain-text h2:first-child,
.plain-text h3:first-child,
.plain-text h4:first-child,
.plain-text h5:first-child {
  margin-block-start: 0;
}
.plain-text h2 {
  color: #273a53;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.plain-text h3 {
  color: #0070df;
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.plain-text p,
.plain-text ol,
.plain-text ul {
  font-size: 17px;
  color: #35475E;
  display: block;
  margin-block-end: 5px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
@media (max-width: 479.98px) {
  .plain-text p,
.plain-text ol,
.plain-text ul {
    font-size: 15px;
    line-height: 20px;
  }
}
.plain-text ul,
.plain-text ol,
.plain-text li {
  margin-inline-start: 16px;
}
.plain-text ul,
.plain-text ol {
  margin-block-start: 20px;
}
.plain-text ul {
  list-style: disc;
}
.plain-text ol {
  list-style: decimal;
}
.plain-text strong {
  font-weight: 700;
}
.plain-text a.underline {
  text-decoration: underline;
}
.plain-text table {
  margin-block-start: 24px;
  border-collapse: collapse;
  width: 100%;
  color: #273A53;
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.5px;
}
.plain-text th {
  font-size: 13px;
  color: #fff;
  background-color: #91a1b6;
}
.plain-text tbody tr {
  background-color: #fff;
}
.plain-text tbody tr:nth-child(odd) {
  background-color: #F1F3F4;
}
.plain-text th,
.plain-text td {
  padding: 15px 8px;
  text-align: start;
}
@media (max-width: 479.98px) {
  .plain-text th,
.plain-text td {
    font-size: 12px;
  }
}
.plain-text th:first-child,
.plain-text td:first-child {
  padding-inline-start: 20px;
}
.plain-text th:last-child,
.plain-text td:last-child {
  padding-inline-end: 5px;
}

html[dir=rtl] .plain-text table {
  font-family: "Din", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}
html[dir=rtl] .plain-text p {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
}

/*=====  End of RTE  ======*/
.plain-text-page {
  padding: 56px 32px;
  background-color: #f7f9fb;
}
@media (max-width: 479.98px) {
  .plain-text-page {
    padding: 32px 26px;
    font-size: 12px;
  }
}
.plain-text-page__link {
  width: 262px;
  gap: 10px;
  padding: 30px 20px;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.5s ease;
}
@media (max-width: 524.98px) {
  .plain-text-page__link {
    width: 100%;
  }
}
.plain-text-page__link-container {
  margin-block-start: 24px;
  gap: 28px;
}
.plain-text-page__link-title {
  max-width: 181px;
  color: #273a53;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
@media (max-width: 479.98px) {
  .plain-text-page__link-title {
    font-size: 15px;
    line-height: 20px;
  }
}
.plain-text-page__link-icon {
  width: 24px;
  height: 26px;
}
.plain-text-page__link:hover {
  transform: scale(1.1);
}
.plain-text-page .plain-text.container {
  padding: 0;
}

html[dir=rtl] .plain-text-page__link-title {
  font-family: "Din", sans-serif;
}

/*=============================================
=            Prompt Tiles            =
=============================================*/
.prompt-tiles {
  padding-block: 56px;
  gap: 64px;
}
@media (max-width: 1249.98px) {
  .prompt-tiles {
    padding-inline: 26px;
  }
}
@media (max-width: 767.98px) {
  .prompt-tiles {
    gap: 24px;
  }
}
.prompt-tiles__header {
  color: #000;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: center;
}
html[dir=rtl] .prompt-tiles__header {
  font-family: "Din", sans-serif;
  line-height: 70.56px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .prompt-tiles__header {
    font-size: 24px;
    line-height: 35.28px;
  }
}
@media (max-width: 767.98px) {
  .prompt-tiles__header {
    font-size: 24px;
    line-height: 28.8px;
  }
}
.prompt-tiles__tiles {
  gap: 24px;
}
@media (max-width: 767.98px) {
  .prompt-tiles__tiles {
    flex-direction: column;
    gap: 16px;
  }
}
.prompt-tiles__tile {
  flex: 1;
  border-radius: 32px;
  padding: 56px 29px;
  background-color: #fff;
  gap: 30px;
  align-self: normal;
}
@media (max-width: 767.98px) {
  .prompt-tiles__tile {
    gap: 12px;
    padding: 37px 29px;
  }
}
.prompt-tiles__tile-icon {
  height: 60px;
  width: 60px;
}
@media (max-width: 767.98px) {
  .prompt-tiles__tile-icon {
    height: 46px;
    width: 46px;
  }
}
.prompt-tiles__tile-title {
  font-size: 24px;
  line-height: 28.8px;
  text-align: center;
}
html[dir=rtl] .prompt-tiles__tile-title {
  font-family: "Din", sans-serif;
  line-height: 35px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .prompt-tiles__tile-title {
    line-height: 23px;
  }
}
@media (max-width: 767.98px) {
  .prompt-tiles__tile-title {
    font-size: 16px;
    line-height: 19px;
  }
}

/*=====  End of Prompt Tiles  ======*/
/*=============================================
=            Reports Component            =
=============================================*/
.reports-component {
  background-color: #F7F9FB;
}
.reports-component__inner {
  margin: 0 auto;
  padding-block: 56px;
  gap: 24px;
}
.reports-component__tabs {
  gap: 10px;
  box-sizing: border-box;
  border: 1px solid #E4EBF2;
  border-radius: 6px;
  width: fit-content;
  padding: 4px;
  background-color: #fff;
  overflow: auto;
  margin-bottom: 24px;
}
@media (max-width: 479.98px) {
  .reports-component__tabs {
    margin: 0 auto;
  }
}
.reports-component__label {
  cursor: pointer;
  min-width: fit-content;
  transition: background-color, border-color 0.2s ease-in-out;
}
.reports-component__label:focus {
  border-color: #000;
}
.reports-component__radio {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.reports-component__radio + .reports-component__label {
  margin: 0;
  padding: 17px 55px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  border: 1px solid transparent;
}
@media (max-width: 767.98px) {
  .reports-component__radio + .reports-component__label {
    font-size: 13px;
    line-height: 25px;
    padding: 7.5px 32px;
  }
}
@media (max-width: 767.98px) {
  .reports-component__radio + .reports-component__label {
    padding: 7.5px 24px;
  }
}
.reports-component__radio:hover + .reports-component__label {
  border-color: #0070df;
}
.reports-component__radio:checked + .reports-component__label {
  background-color: #0070df;
  color: #FFF;
  z-index: 1;
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.5px;
}
@media (max-width: 767.98px) {
  .reports-component__radio:checked + .reports-component__label {
    font-size: 13px;
  }
}
.reports-component__cards {
  display: none;
  gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: dense;
}
@media (max-width: 991.98px) {
  .reports-component__cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .reports-component__cards {
    grid-template-columns: repeat(1, 1fr);
    gap: 14px;
  }
}
.reports-component__header {
  display: none;
  gap: 22px;
}
@media (max-width: 767.98px) {
  .reports-component__header {
    gap: 10px;
  }
}
.reports-component__text {
  font-family: "DM Sans", sans-serif;
  color: #273a53;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
}
@media (max-width: 479.98px) {
  .reports-component__text {
    font-family: "Lato";
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
}
.reports-component__heading {
  color: #0070df;
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
@media (max-width: 479.98px) {
  .reports-component__heading {
    font-family: "Lato";
    color: #0070df;
  }
}
.reports-component__accordion {
  display: none;
  width: 100%;
}
.reports-component__accordion-item {
  width: 100%;
  padding: 12px 24px;
  border: 1px solid transparent;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.reports-component__accordion-item:hover, .reports-component__accordion-item:focus {
  border-color: #0070df;
}
.reports-component__accordion-item:nth-child(odd) {
  background-color: #F7F9FB;
}
.reports-component__close {
  position: absolute;
  inset-inline-end: 32px;
  inset-block-start: 32px;
  display: none;
  cursor: pointer;
  z-index: 2;
}
.reports-component__close:hover .reports-component__close-icon, .reports-component__close:focus .reports-component__close-icon {
  color: #0070df;
}
@media (max-width: 479.98px) {
  .reports-component__close {
    inset-inline-end: 21px;
    inset-block-start: 19px;
  }
}
.reports-component__close-icon {
  font-size: 28px;
  color: #808082;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.reports-component__card {
  position: relative;
  background-color: #fff;
  padding: 48px 103px 38px 84px;
  gap: 24px;
  cursor: pointer;
}
.reports-component__card:hover, .reports-component__card:focus {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: scale(1.01);
}
.reports-component__card-heading {
  font-family: "DM Sans", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #273a53;
}
@media (max-width: 479.98px) {
  .reports-component__card-heading {
    font-family: "Lato";
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.5px;
  }
}
.reports-component__card--active {
  padding: 32px;
  grid-column: span 3;
  cursor: default;
}
@media (max-width: 991.98px) {
  .reports-component__card--active {
    grid-column: span 2;
  }
}
@media (max-width: 767.98px) {
  .reports-component__card--active {
    grid-column: span 1;
  }
}
.reports-component__card--active .reports-component__accordion {
  display: block;
}
.reports-component__card--active .reports-component__close {
  display: block;
}
.reports-component__card--active .reports-component__card-heading {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  margin-inline-end: auto;
}
.reports-component__link {
  color: #273a53;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  width: 100%;
  height: 100%;
  flex: 1;
  gap: 4px;
}
.reports-component__icon {
  height: 24px;
  width: 24px;
}
.reports-component__download {
  color: #0070df;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  cursor: pointer;
  margin-inline-start: auto;
}
@media (max-width: 479.98px) {
  .reports-component__download-text {
    display: none;
  }
}

html[dir=rtl] .reports-component__card--active,
html[dir=rtl] .reports-component__card-heading,
html[dir=rtl] .reports-component__text {
  font-family: "Din", sans-serif;
  line-height: 33px;
}

/*=====  End of Reports Component  ======*/
.rte--inline {
  margin: 0;
  max-width: none;
}
.rte--white {
  color: #fff;
}
.rte__title {
  margin-bottom: 10px;
}
.rte__subtitle {
  margin-bottom: 56px;
}
.rte h1,
.rte h2,
.rte h3,
.rte h4,
.rte h5 {
  display: block;
  margin-bottom: 18px;
}
.rte p,
.rte ol,
.rte ul {
  display: block;
}
@media (max-width: 767.98px) {
  .rte p {
    font-size: 14px;
    line-height: 22px;
  }
}
.rte ul,
.rte ol,
.rte li {
  margin-inline-start: 10px;
}
.rte ul,
.rte ol {
  margin-top: 10px;
}
.rte ul {
  list-style: disc;
}
.rte ol {
  list-style: decimal;
}
.rte strong {
  font-weight: 700;
}
.rte a.underline {
  text-decoration: underline;
}

/*=====  End of RTE  ======*/
/*=============================================
=            Search Results            =
=============================================*/
.search {
  padding: 0 26px;
}
.search__input {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  padding: 13px 0;
  padding-inline: 23px 66px;
  width: 100%;
  background-color: #fff;
  font-family: "Go", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/search-icon.svg");
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  z-index: 9999;
  height: 55px;
}
.search__input ::placeholder {
  color: #1E1E1E;
  opacity: 1;
}
.search__input :-ms-input-placeholder {
  color: #1E1E1E;
}
.search__input ::-ms-input-placeholder {
  color: #1E1E1E;
}
.search__input-container {
  width: 36.77%;
  min-width: 400px;
  max-width: 662px;
  margin: auto;
  padding: 56px 26px 28px;
}
@media (max-width: 767.98px) {
  .search__input-container {
    min-width: auto;
    width: 100%;
    padding-top: 33px;
  }
}
.search__header {
  padding: 0 26px;
  gap: 16px;
  margin-bottom: 24px;
  padding-top: 33px;
}
@media (max-width: 767.98px) {
  .search__header {
    margin-bottom: 0;
    gap: 10px;
  }
}
.search__heading {
  color: #0070df;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
}
@media (max-width: 767.98px) {
  .search__heading {
    font-size: 25px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.5px;
  }
}
.search__results {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
@media (max-width: 767.98px) {
  .search__results {
    font-family: "Lato", sans-serif;
    font-size: 17px;
    font-weight: 900;
    line-height: 25px;
    letter-spacing: 0.5px;
  }
}
.search__word {
  color: #0070df;
}
.search-wrapper {
  padding-bottom: 76px;
  background-color: #F7F9FB;
}
@media (max-width: 767.98px) {
  .search-wrapper {
    padding-bottom: 32px;
  }
}
.search__inner {
  gap: 28px;
}
@media (max-width: 767.98px) {
  .search__inner {
    gap: 26px;
  }
}
.search__item {
  background-color: #fff;
  padding: 32px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .search__item {
    padding: 20px 0 24px;
    padding-inline: 15px 38px;
  }
}
.search__item:hover, .search__item:focus {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: scale(1.01);
}
.search__item-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #273a53;
}
.search__item-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #273a53;
}
.search__item-left {
  padding-inline-end: 93px;
  gap: 8px;
}
@media (max-width: 767.98px) {
  .search__item-left {
    padding-inline-end: 0px;
  }
}
.search__item-right {
  margin: auto;
}
@media (max-width: 767.98px) {
  .search__item-right {
    display: none;
  }
}
.search__item-button {
  color: #0070df;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-inline-start: auto;
  margin-top: 12px;
  transform: translateX(12px);
}
@media (min-width: 768px) {
  .search__item-button {
    display: none;
  }
}
.search__item-button-icon {
  margin-inline-start: 5px;
  font-size: 20px;
  transform: translateY(3px);
}
.search__image {
  width: 24px;
  height: 24px;
}
.search__pagination {
  padding: 0 26px;
  margin-top: 69px;
  gap: 16px;
}
@media (max-width: 767.98px) {
  .search__pagination {
    gap: 8px;
    margin-top: 32px;
  }
}
.search__pagination-icon {
  font-size: 24px;
}
.search__pagination-button {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid #BEBEBE;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767.98px) {
  .search__pagination-button {
    height: 34px;
    width: 34px;
  }
}
.search__pagination-button:hover, .search__pagination-button:focus {
  border-color: #0070df;
}
.search__pagination-button:disabled {
  opacity: 0.3;
  cursor: default;
}
.search__pagination-button:disabled:hover {
  border-color: #BEBEBE;
}
.search__pagination-button--active {
  border-color: #0070df;
  color: #0070df;
}
.search__pagination-dots {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-color: transparent;
}
.search__no-results {
  padding: 40px 26px 56px;
  text-align: center;
}
@media (max-width: 479.98px) {
  .search__no-results {
    padding-top: 172px;
  }
}
.search__no-results--active {
  display: flex;
}
.search__no-results-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
.search__no-results-image-container {
  width: 149px;
  margin-bottom: 34px;
}
@media (max-width: 767.98px) {
  .search__no-results-image-container {
    width: 143px;
    margin-bottom: 26px;
  }
}
.search__no-results-title {
  color: #273a53;
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 12px;
}
@media (max-width: 767.98px) {
  .search__no-results-title {
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.5px;
  }
}
.search__no-results-text {
  font-family: "Go", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  color: #808082;
  margin-bottom: 28px;
}
@media (max-width: 767.98px) {
  .search__no-results-text {
    font-family: "Lato", sans-serif;
    font-size: 13px;
    font-weight: 900;
    line-height: 19px;
    letter-spacing: 0.5px;
    margin-bottom: 73px;
  }
}
.search__no-results .link {
  margin-bottom: 456px;
  padding: 18px 54px;
}
@media (max-width: 479.98px) {
  .search__no-results .link {
    margin-bottom: 152px;
    width: 100%;
  }
}

html[dir=rtl] .search__input {
  position: relative;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/search-icon.svg");
  background-position-x: 15px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  z-index: 9999;
  cursor: pointer;
}
html[dir=rtl] .search__item-right, html[dir=rtl] .search__pagination-next, html[dir=rtl] .search__pagination-prev {
  transform: rotate(180deg);
}
html[dir=rtl] .search__item-button-icon {
  transform: rotate(180deg) translateY(-2px);
}
html[dir=rtl] .search__no-results {
  transform: scaleX(-1);
}

/*=====  End of Search Results  ======*/
/*=============================================
=            Shareholder Logo Grid            =
=============================================*/
.shareholder-logo-grid {
  margin-bottom: 56px;
  padding: 56px 40px;
  background-color: #fff;
}
.shareholder-logo-grid__header h2,
.shareholder-logo-grid__header h3 {
  margin-bottom: 24px;
  color: #273a53;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.shareholder-logo-grid__header p {
  color: #273a53;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.shareholder-logo-grid__grid {
  display: flex;
  justify-content: center;
  gap: 70px;
  padding: 0;
}
@media (max-width: 767.98px) {
  .shareholder-logo-grid__grid {
    margin-block-start: 32px;
  }
}
.shareholder-logo-grid__grid__image {
  max-width: 120px;
}
@media (max-width: 767.98px) {
  .shareholder-logo-grid__grid__image {
    width: 100%;
  }
}

html[dir=rtl] .shareholder-logo-grid__header {
  font-family: "Din", sans-serif;
  line-height: 33px;
}
html[dir=rtl] .shareholder-logo-grid__header h2,
html[dir=rtl] .shareholder-logo-grid__header h3 {
  font-size: 24px;
  line-height: 49px;
  margin-bottom: 8px;
}
html[dir=rtl] .shareholder-logo-grid__header p {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
}

/*=====  End of Shareholder Logo Grid  ======*/
.sitemap-page {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 27px;
  row-gap: 28px;
  align-items: start;
  font-family: "Lato";
}
@media (max-width: 767.98px) {
  .sitemap-page {
    grid-template-columns: 1fr;
  }
}
.sitemap-page-container {
  padding: 56px 32px;
  background-color: #f7f9fb;
}
@media (max-width: 479.98px) {
  .sitemap-page-container {
    padding: 32px 26px;
    font-size: 12px;
  }
}
.sitemap-page__title {
  color: #0070df;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
  margin-block-end: 32px;
}
.sitemap-page__item {
  max-height: fit-content;
  padding: 20px;
  background-color: #fff;
}
.sitemap-page__item-title {
  color: #0070df;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.sitemap-page__item-subtitle {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  margin-block-start: 5px;
  margin-block-end: 12px;
  color: #0070df;
  font-family: "Neue Haas Grotesk Display Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.sitemap-page__item-accordion-title {
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.sitemap-page__item-link {
  margin-block-end: 8px;
}
.sitemap-page summary::-webkit-details-marker {
  display: none;
}
.sitemap-page summary {
  cursor: pointer;
  list-style: none;
  padding-inline-start: 30px;
  position: relative;
  margin-block-end: 8px;
}
.sitemap-page summary[data-direction=ltr]::after {
  content: "";
  border-right: 1px solid;
  border-bottom: 1px solid;
  position: absolute;
  left: 5px;
  top: 10px;
  height: 8px;
  width: 8px;
  transform: rotate(-45deg) translatey(-2px);
}
.sitemap-page summary[data-direction=rtl]::before {
  content: "";
  border-right: 1px solid;
  border-bottom: 1px solid;
  position: absolute;
  right: 5px;
  top: 8px;
  height: 8px;
  width: 8px;
  transform: rotate(135deg) translatey(-2px);
}
.sitemap-page details[open] > summary::after {
  transform: rotate(45deg) translatey(-6px);
  left: 0px;
}
.sitemap-page details > div {
  padding-inline-start: 30px;
}

html[dir=rtl] .sitemap-page-subtitle, html[dir=rtl] .sitemap-page-accordion-title {
  font-family: "Din", sans-serif;
}

/*=============================================
=            Small App Download CTA            =
=============================================*/
.small-download {
  background-color: #F7F9FB;
  padding: 0px 32px;
}
@media (max-width: 767.98px) {
  .small-download {
    padding: 0px 24px;
  }
}
.small-download__inner {
  color: #fff;
}
.small-download__container {
  background-color: #18CDB7;
  padding: 26px 37px 21px 54px;
  gap: 30px;
}
@media (max-width: 767.98px) {
  .small-download__container {
    flex-direction: column;
    align-items: start;
    padding: 44px 19px 46px 24px;
  }
}
.small-download__left {
  max-width: 670px;
}
@media (max-width: 767.98px) {
  .small-download__left {
    max-width: none;
  }
}
.small-download__right {
  align-self: flex-start;
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;
  height: 100%;
}
@media (max-width: 767.98px) {
  .small-download__right {
    background-color: #18CDB7;
    padding: 0;
  }
}
.small-download__subtitle {
  color: #FFE000;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 2px;
}
@media (max-width: 767.98px) {
  .small-download__subtitle {
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 1.7px;
  }
}
.small-download__title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-top: 7px;
  margin-bottom: 1px;
}
@media (max-width: 767.98px) {
  .small-download__title {
    font-size: 23px;
    font-weight: 400;
    line-height: 38px;
  }
}
.small-download__content {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 767.98px) {
  .small-download__content {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
}
.small-download__image {
  height: 180px;
  width: 180px;
}
@media (max-width: 767.98px) {
  .small-download__image {
    display: none;
  }
}
.small-download__stores {
  gap: 12px;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .small-download__stores {
    display: none;
  }
}
.small-download__store {
  height: 100%;
  width: 125px;
}

html[dir=rtl] .small-download__title {
  font-family: "Din", sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 38px;
  margin-block-end: 10px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .small-download__title {
    font-size: 23px;
  }
}
html[dir=rtl] .small-download__content {
  font-family: "Din", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .small-download__content {
    line-height: 21px;
  }
}

/*=====  End of App Download CTA  ======*/
/*=============================================
=            Tabbed Component            =
=============================================*/
.tabbed-calculators {
  background-color: #fff;
}
.tabbed-calculators__tabs {
  box-sizing: border-box;
  width: 100%;
}
.tabbed-calculators__tabs-inner {
  width: 100%;
  z-index: 2;
}
.tabbed-calculators__tabs-left {
  width: 100%;
  flex: 1;
  padding-block: 56px;
  background-color: #0070df;
}
@media (max-width: 991.98px) {
  .tabbed-calculators__tabs-left {
    padding-inline: 0;
    padding-block-start: 40px;
    padding-block-end: 24px;
    justify-content: center;
  }
}
.tabbed-calculators__tabs-left-inner {
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 8px 7px;
  gap: 8px;
  margin-inline-end: 64px;
}
@media (max-width: 991.98px) {
  .tabbed-calculators__tabs-left-inner {
    margin-inline-end: 0;
    padding: 5px;
    width: 100%;
    max-width: 520px;
    margin-inline: 22px;
  }
}
.tabbed-calculators__tabs-right {
  flex: 1;
  z-index: -1;
}
@media (max-width: 991.98px) {
  .tabbed-calculators__tabs-right {
    display: none;
    padding: 32px 0;
    padding-inline: 26px;
  }
}
.tabbed-calculators__label {
  cursor: pointer;
  min-width: fit-content;
  transition: background-color, border-color 0.2s ease-in-out;
}
.tabbed-calculators__label:focus {
  border-color: #000;
}
@media (max-width: 991.98px) {
  .tabbed-calculators__label {
    justify-content: center;
    align-items: center;
  }
}
.tabbed-calculators__radio {
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}
.tabbed-calculators__radio + .tabbed-calculators__label {
  margin: 0;
  padding: 17px 55px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.5px;
  border: 1px solid transparent;
  color: #fff;
}
@media (max-width: 1249.98px) {
  .tabbed-calculators__radio + .tabbed-calculators__label {
    padding: 17px 36px;
  }
}
@media (max-width: 991.98px) {
  .tabbed-calculators__radio + .tabbed-calculators__label {
    padding: 0;
    display: flex;
    font-size: 13px;
    line-height: 25px;
    width: 100%;
    height: 40px;
  }
}
.tabbed-calculators__radio:hover + .tabbed-calculators__label {
  border-color: #fff;
}
.tabbed-calculators__radio:checked + .tabbed-calculators__label {
  background-color: #fff;
  color: #0070df;
  z-index: 1;
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.5px;
  border-color: #fff;
}
@media (max-width: 991.98px) {
  .tabbed-calculators__radio:checked + .tabbed-calculators__label {
    font-size: 13px;
  }
}
.tabbed-calculators__cards {
  display: none;
}

.tabbed-calc-card {
  z-index: 2;
  top: -191px;
  margin-bottom: -191px;
}
@media (max-width: 991.98px) {
  .tabbed-calc-card {
    top: initial;
    margin-bottom: 0;
  }
}

/*=====  End of Tabbed Component  ======*/
/*=============================================
=            Tabbed Component            =
=============================================*/
.tabbed-component {
  background-color: #F7F9FB;
  margin: 0 auto;
  padding: 56px 0;
  display: flex;
  flex-direction: column;
  gap: 56px;
  font-family: "Lato";
}
.tabbed-component__tabs {
  gap: 10px;
  box-sizing: border-box;
  border: 1px solid #E4EBF2;
  border-radius: 6px;
  width: fit-content;
  padding: 8px;
  background-color: #FFF;
}
.tabbed-component__card {
  display: none;
  flex-direction: column;
  gap: 56px;
}
.tabbed-component__card-content {
  color: #273A53;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
}
.tabbed-component__card-content .container {
  margin: 0;
}
.tabbed-component__rhs-container {
  flex: 1;
}
.tabbed-component__rhs-title {
  color: #0070df;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.tabbed-component__rhs-image {
  width: 32px;
  height: 32px;
}
.tabbed-component__rhs-items-container {
  padding-block: 28px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 0;
}
.tabbed-component__rhs-item {
  display: flex;
  flex-direction: row;
  gap: 13px;
}
.tabbed-component__rhs-item-title {
  color: #0070df;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.tabbed-component__rhs-item-image {
  width: 32px;
  height: 32px;
}
.tabbed-component__rhs-item-content {
  color: #273A53;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.tabbed-component__ticklist-container {
  flex: 1;
}
.tabbed-component__ticklist-title {
  color: #0070df;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.5px;
}
.tabbed-component__ticklist-bullets {
  padding-block: 28px;
  padding-bottom: 0;
  gap: 20px;
}
.tabbed-component__ticklist-bullet {
  display: flex;
  flex-direction: row;
  gap: 13px;
  margin-block-end: 18px;
  color: #273A53;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
}
.tabbed-component__ticklist-bullet p {
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
}
.tabbed-component__ticklist-bullet ul {
  list-style-type: disc;
}
.tabbed-component__ticklist-bullet ul li {
  margin-left: 20px;
  line-height: 24px;
  font-size: 13px;
}
.tabbed-component__ticklist-bullet:last-child {
  padding-bottom: 0;
}
.tabbed-component__tick {
  width: 24px;
  height: 24px;
}
.tabbed-component__containers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 56px;
}
.tabbed-component__label {
  cursor: pointer;
  text-align: center;
  transition: background-color, border-color 0.2s ease-in-out;
}
.tabbed-component__radio {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.tabbed-component__radio + .tabbed-component__label {
  margin: 0;
  padding: 17px 10px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  border: 1px solid transparent;
}
.tabbed-component__radio:hover + .tabbed-component__label {
  border-color: #0070df;
}
.tabbed-component__radio:checked + .tabbed-component__label {
  background-color: #0070df;
  color: #FFF;
  z-index: 1;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.5px;
  font-weight: 900;
}

@media (max-width: 767.98px) {
  .tabbed-component {
    padding: 48px 24px;
    font-size: 12px;
    gap: 48px;
  }
  .tabbed-component__tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .tabbed-component__radio + .tabbed-component__label {
    width: 100%;
    text-align: center;
    padding: 10px 16px;
  }
  .tabbed-component__containers {
    flex-direction: column;
    gap: 32px;
  }
  .tabbed-component__card {
    gap: 48px;
  }
  .tabbed-component__ticklist-title, .tabbed-component__rhs-title {
    font-weight: 900;
  }
  .tabbed-component__ticklist-bullets {
    padding-block-start: 24px;
  }
  .tabbed-component__rhs-items-container {
    gap: 16px;
    padding-block-start: 24px;
  }
  .tabbed-component__rhs-item {
    gap: 10px;
  }
  .tabbed-component__rhs-item-title {
    font-size: 15px;
  }
}
html[dir=rtl] .tabbed-component {
  font-family: "Din", sans-serif;
}
html[dir=rtl] .tabbed-component p, html[dir=rtl] .tabbed-component__ticklist-bullet ul li {
  font-family: "Din", sans-serif;
}

/*=====  End of Tabbed Component  ======*/
/*=============================================
=            Team Component            =
=============================================*/
.team {
  background-color: #F7F9FB;
  padding-inline: 26px;
}
.team__inner {
  padding-block: 56px;
  gap: 56px;
  margin: 0 auto;
  max-width: 1176px;
}
.team__header {
  gap: 24px;
}
.team__heading {
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
  color: #0070df;
}
@media (max-width: 767.98px) {
  .team__heading {
    font-size: 25px;
    line-height: 29px;
  }
}
.team__text {
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #273a53;
}
@media (max-width: 767.98px) {
  .team__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
}
.team__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-auto-flow: dense;
  gap: 26px;
  transition: all 0.3s ease;
  row-gap: 56px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.team__grid--2 {
  grid-template-columns: repeat(2, 1fr);
}
.team__grid--2 .team__card--active {
  grid-column: span 2;
}
.team__grid--3 {
  grid-template-columns: repeat(3, 1fr);
}
.team__grid--3 .team__card--active {
  grid-column: span 3;
}
.team__grid--4 {
  grid-template-columns: repeat(4, 1fr);
}
.team__grid--4 .team__card--active {
  grid-column: span 4;
}
.team__grid--5 {
  grid-template-columns: repeat(5, 1fr);
}
.team__grid--5 .team__card--active {
  grid-column: span 5;
}
.team__grid--6 {
  grid-template-columns: repeat(6, 1fr);
}
.team__grid--6 .team__card--active {
  grid-column: span 6;
}
@media only screen and (max-width: 645px) {
  .team__grid {
    padding-inline: 25px;
  }
}
@media (max-width: 524.98px) {
  .team__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.team__close {
  display: none;
  position: absolute;
  inset-block-start: 38px;
  inset-inline-end: 36px;
  cursor: pointer;
}
@media only screen and (max-width: 645px) {
  .team__close {
    inset-block-start: 20px;
    inset-inline-end: 22px;
  }
}
.team__close:hover .team__close-icon, .team__close:focus .team__close-icon {
  color: #273a53;
}
.team__close-icon {
  font-size: 30px;
  color: #808082;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.team__card {
  overflow: hidden;
  gap: 13px;
  position: relative;
  cursor: pointer;
}
.team__card--active {
  background-color: #fff;
  padding: 56px 40px;
  grid-column: span 3;
  cursor: default;
}
@media only screen and (max-width: 945px) {
  .team__card--active {
    padding: 0;
    grid-column: span 2;
  }
}
@media only screen and (max-width: 645px) {
  .team__card--active {
    grid-column: span 1;
  }
}
.team__card--active .team__info, .team__card--active .team__link {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.team__card--active .team__content-wrapper {
  padding: 30px 25px;
  padding-inline-end: 67px;
}
@media (max-width: 991.98px) {
  .team__card--active .team__content-wrapper {
    flex: 1;
    padding: 30px;
  }
}
.team__card--active .team__image-container {
  width: 448px;
  min-width: 448px;
  height: auto;
}
@media (max-width: 991.98px) {
  .team__card--active .team__image-container {
    width: 100%;
    min-width: 248px;
    flex: 1;
  }
}
.team__card--active .team__close {
  display: block;
}
.team__card--active .team__title {
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.5px;
  margin-block-start: 0;
}
@media (max-width: 767.98px) {
  .team__card--active .team__title {
    font-size: 25px;
    line-height: 29px;
  }
}
.team__card--active .team__subtitle {
  margin-block-start: 8px;
  margin-block-end: 16px;
}
.team__card--active .team__info {
  font-size: 17px;
  color: #273a53;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
@media (max-width: 767.98px) {
  .team__card--active .team__info {
    font-size: 14px;
    line-height: 22px;
  }
}
.team__card--active .team__card-inner {
  flex-direction: row;
}
@media (max-width: 767.98px) {
  .team__card--active .team__card-inner {
    flex-direction: column;
  }
}
.team__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.team__image-container {
  min-height: 255px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
}
.team__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: #273a53;
  margin-top: 12px;
}
@media (max-width: 767.98px) {
  .team__title {
    color: #0070df;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}
.team__subtitle {
  color: #85939E;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
@media (max-width: 767.98px) {
  .team__subtitle {
    color: #000;
    font-weight: 500;
    letter-spacing: 0.5px;
    height: auto;
    margin-bottom: 18px;
  }
}
.team__info, .team__link {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.team__link {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  padding: 12px 30px;
  gap: 11px;
  border-radius: 60px;
  border: 1px solid #DBDBDB;
  min-width: max-content;
  margin-top: 12px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.team__link:hover, .team__link:focus {
  border-color: #0070df;
}
@media (max-width: 767.98px) {
  .team__link {
    min-width: 100%;
  }
}
.team__link-icon {
  height: 24px;
  width: 24px;
  display: inline;
}

html[dir=rtl] .team__text {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
}
html[dir=rtl] .team__card--active .team__title {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
}
html[dir=rtl] .team__card--active .team__subtitle, html[dir=rtl] .team__card--active .team__info {
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
}
html[dir=rtl] .team__card--active .team__subtitle {
  margin-block-start: 16px;
}
html[dir=rtl] .team__card--active .team__info {
  font-weight: 400;
}

/*=====  End of Team Component  ======*/
.video-player {
  width: 100%;
  aspect-ratio: 2.35/1;
}
@media (max-width: 767.98px) {
  .video-player {
    aspect-ratio: 16/9;
    margin-block-end: 87px;
  }
}
.video-player__container {
  max-width: 1150px;
  padding-inline: 24px;
}
.video-player__container .plain-text {
  padding-block-end: 40px;
}
@media (max-width: 767.98px) {
  .video-player__container .plain-text {
    padding-inline: 10px;
  }
}
.video-player__container .plain-text a {
  color: #0070F9;
}
@media (max-width: 767.98px) {
  .video-player__container .plain-text h2.big-h2 {
    font-size: 24px;
  }
}
@media (max-width: 767.98px) {
  .video-player__container .plain-text h2.mb-8 {
    margin-bottom: 16px;
  }
}

html[dir=rtl] .video-player__container .plain-text p {
  font-size: 17px;
  font-weight: 500;
  line-height: 24.2px;
}
html[dir=rtl] .video-player__container .plain-text h2.big-h2 {
  font-size: 43.27px;
  font-weight: 700;
  line-height: 57px;
  margin-block-end: 10px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .video-player__container .plain-text h2.big-h2 {
    font-size: 24px;
    line-height: 38px;
  }
}

.video-player-two {
  padding-block: 56px;
  gap: 32px;
  max-width: 993px;
}
@media (max-width: 1249.98px) {
  .video-player-two {
    padding-inline: 24px;
  }
}
@media (max-width: 767.98px) {
  .video-player-two {
    gap: 24px;
  }
}
.video-player-two__title {
  text-align: center;
  font-size: 32px;
  line-height: 38.4px;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  .video-player-two__title {
    font-size: 24px;
    line-height: 28.8px;
  }
}
html[dir=rtl] .video-player-two__title {
  line-height: 47.04px;
}
@media (max-width: 767.98px) {
  html[dir=rtl] .video-player-two__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 35.28px;
  }
}
.video-player-two__video {
  width: 100%;
  aspect-ratio: 2.12/1;
  border-radius: 30px;
}
@media (max-width: 767.98px) {
  .video-player-two__video {
    aspect-ratio: 16/9;
    border: 1.66px solid #d0d0d0;
    border-radius: 10.6px;
  }
}
.video-player-two__video-container {
  background: linear-gradient(to bottom, #d0d0d0 80%, #f0f0f0);
  padding: 5px;
  border-radius: 32px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .video-player-two__video-container {
    padding: 0;
    background: none;
    border-radius: 0;
  }
}

#launcher {
  min-width: 60px !important;
  min-height: 80px !important;
}

#launcher, #webWidget {
  left: initial !important;
  right: 20px !important;
}

html[dir=rtl] #launcher,
html[dir=rtl] #webWidget {
  right: initial !important;
  left: 20px !important;
}

/*=============================================
=            Contact Form            =
=============================================*/
.contact {
  padding: 0 26px 54px;
}
.contact-wrapper {
  background-color: #F7F9FB;
}
@media (max-width: 767.98px) {
  .contact__inner {
    flex-direction: column;
  }
}
.contact__heading {
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
  color: #0070df;
  padding: 56px 26px 28px;
}
@media (max-width: 479.98px) {
  .contact__heading {
    display: none;
  }
}
.contact__heading-mobile {
  font-size: 25px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0.5px;
  background-color: #fff;
  color: #0070df;
  padding: 22px 26px 0;
  margin-top: 27px;
}
@media (min-width: 480px) {
  .contact__heading-mobile {
    display: none;
  }
}
.contact__right {
  flex: 0.74;
  padding: 32px 32px 40px;
  background-color: #fff;
}
.contact__right-title {
  font-size: 29px;
  font-weight: 500;
  line-height: 41px;
  color: #273a53;
  margin-bottom: 4px;
}
@media (max-width: 479.98px) {
  .contact__right-title {
    color: #0070df;
  }
}
.contact__right p {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.contact__overlay__failure {
  width: 100%;
  padding: 15px 24px;
  display: flex;
  align-items: center;
  border: 1.5px solid var(--secondary-500, #F55C5C);
  border-radius: 12px;
  background: #FFF5F5;
}
.contact__overlay__failure-image {
  width: 34px;
  height: 34px;
  margin-right: 14px;
}
.contact__overlay__failure-text {
  color: var(--secondary-600, #E54E4E);
  font-family: Go;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.contact__overlay__success {
  z-index: 99999;
  position: absolute;
  padding: 50px;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
}
.contact__overlay__success-cross {
  width: 20px;
  position: absolute;
  inset-inline-end: 35px;
  inset-block-start: 25px;
  cursor: pointer;
}
.contact__overlay__success-image {
  width: 150px;
  margin-block-start: 150px;
  margin-inline: auto;
  margin-block-end: 25px;
}
.contact__overlay__success-title {
  color: #35475E;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-block-end: 18px;
}
.contact__overlay__success-text {
  color: #273a53;
  max-width: 450px;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.contact .umbraco-forms-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.contact__left {
  position: relative;
  flex: 1;
  padding: 32px;
  background-color: #fff;
  margin-right: 33px;
  height: fit-content;
}
@media (max-width: 767.98px) {
  .contact__left {
    margin-right: 0;
    margin-bottom: 33px;
  }
}
.contact__left p {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.contact label {
  color: #808082;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  inset-block-start: 14px;
  inset-inline-start: 20px;
}
.contact .dataconsent label {
  position: relative;
  top: unset;
  left: unset;
}
.contact .form-group {
  position: relative;
}
.contact input[type=text],
.contact input[type=number],
.contact input[type=email],
.contact input[type=tel],
.contact select,
.contact textarea {
  color: #808082;
  border-radius: 12px;
  border: 1px solid #CFCFCF;
  padding: 14px 20px;
  padding-top: 33px;
  width: 100%;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  resize: none;
}
.contact input[type=text]::-ms-input-placeholder,
.contact input[type=number]::-ms-input-placeholder,
.contact input[type=email]::-ms-input-placeholder,
.contact input[type=tel]::-ms-input-placeholder,
.contact select::-ms-input-placeholder,
.contact textarea::-ms-input-placeholder {
  color: #808082;
}
.contact input[type=text]::placeholder,
.contact input[type=number]::placeholder,
.contact input[type=email]::placeholder,
.contact input[type=tel]::placeholder,
.contact select::placeholder,
.contact textarea::placeholder {
  color: #808082;
}
.contact input[type=submit] {
  padding: 18px 54px;
  background-color: #0070df;
  color: #fff;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  margin-top: 24px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .contact input[type=submit] {
    width: 100%;
  }
}
.contact input[type=submit]:hover, .contact input[type=submit]:focus {
  background-color: #273a53;
}
.contact select {
  position: relative;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/chevron-down-active.svg");
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  z-index: 9999;
  cursor: pointer;
}
.contact ::placeholder {
  color: #808082;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.contact :-ms-input-placeholder {
  color: #808082;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.contact ::-ms-input-placeholder {
  color: #808082;
  font-family: "Go", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.contact input::-webkit-inner-spin-button {
  display: none !important;
}
.contact .field-validation-error {
  color: #E54E4E;
  margin-top: 11px;
  display: block;
}
.contact .form-control.input-validation-error {
  border-color: #E54E4E;
}

html[dir=rtl] .contact ::placeholder {
  font-family: "Din", sans-serif;
}
html[dir=rtl] .contact input[type=text],
html[dir=rtl] .contact input[type=number],
html[dir=rtl] .contact input[type=email],
html[dir=rtl] .contact input[type=tel],
html[dir=rtl] .contact select,
html[dir=rtl] .contact textarea {
  font-family: "Din", sans-serif;
  font-size: 14px;
}
html[dir=rtl] .contact input[type=submit] {
  font-family: "Din", sans-serif;
  font-weight: 700;
  line-height: 22px;
}

/*=====  End of Contact Form  ======*/
/*=============================================
=            Feedback Form            =
=============================================*/
.feedback {
  padding: 56px 26px 56px;
}
@media (max-width: 767.98px) {
  .feedback {
    padding: 32px 26px;
  }
}
.feedback-wrapper {
  background-color: #F7F9FB;
}
.feedback__inner {
  flex-direction: row-reverse;
  gap: 48px;
}
@media (max-width: 767.98px) {
  .feedback__inner {
    flex-direction: column-reverse;
  }
}
.feedback .rte p {
  padding-bottom: 24px;
}
.feedback .rte p:last-child {
  padding-bottom: 0;
}
.feedback__heading {
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
  color: #0070df;
  padding-bottom: 20px;
}
@media (max-width: 767.98px) {
  .feedback__heading {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;
  }
}
.feedback__overlay__failure {
  width: 100%;
  padding: 15px 24px;
  display: flex;
  align-items: center;
  border: 1.5px solid var(--secondary-500, #F55C5C);
  border-radius: 12px;
  background: #FFF5F5;
}
.feedback__overlay__failure-image {
  width: 34px;
  height: 34px;
  margin-right: 14px;
}
.feedback__overlay__failure-text {
  color: var(--secondary-600, #E54E4E);
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.feedback__overlay__success {
  z-index: 99999;
  position: absolute;
  padding: 50px;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 13px;
  grid-area: 14px;
  grid-area: 26px;
}
.feedback__overlay__success-cross {
  width: 20px;
  position: absolute;
  inset-inline-end: 35px;
  inset-block-start: 25px;
  cursor: pointer;
}
.feedback__overlay__success-image {
  width: 150px;
}
.feedback__overlay__success-title {
  color: #35475E;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.feedback__overlay__success-text {
  color: #273a53;
  max-width: 450px;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.feedback__right {
  min-height: 240px;
  flex: 1;
  position: relative;
  height: fit-content;
}
.feedback__left {
  color: #273a53;
  flex: 0.8;
}
.feedback__left-title {
  font-size: 29px;
  font-weight: 500;
  line-height: 41px;
  margin-bottom: 4px;
}
@media (max-width: 479.98px) {
  .feedback__left-title {
    color: #0070df;
  }
}
.feedback__left p {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.feedback__left a {
  text-underline-offset: 5px;
  text-decoration: underline;
}
.feedback__list {
  gap: 24px;
  margin-top: 28px;
  padding-bottom: 24px;
}
.feedback__list ol, .feedback__list ul {
  list-style: auto;
}
.feedback__list-item {
  font-style: normal;
  letter-spacing: 0.5px;
  padding-inline: 5px;
}
.feedback__list-index {
  line-height: 25px !important;
}
.feedback__list-content {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 25px !important;
}
.feedback__caption {
  margin-top: 20px;
}
.feedback .umbraco-forms-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.feedback .umbraco-forms-page {
  flex: 1;
  padding: 56px 32px;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .feedback .umbraco-forms-page {
    margin-right: 0;
    padding: 14px 20px;
  }
}
.feedback legend {
  margin-block-end: 14px;
}
.feedback label {
  color: #808082;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  inset-block-start: 14px;
  inset-inline-start: 20px;
}
.feedback .form-group {
  position: relative;
}
.feedback input[type=text], .feedback input[type=email], .feedback select, .feedback textarea {
  border-radius: 12px;
  border: 1px solid #CFCFCF;
  padding: 14px 20px;
  padding-top: 33px;
  width: 100%;
  color: #808082;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  resize: none;
}
.feedback input[type=submit] {
  padding: 18px 54px;
  background-color: #0070df;
  color: #fff;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  margin-top: 24px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .feedback input[type=submit] {
    width: 100%;
  }
}
.feedback input[type=submit]:hover, .feedback input[type=submit]:focus {
  background-color: #273a53;
}
.feedback select {
  width: 100%;
  position: relative;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/chevron-down-active.svg");
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  z-index: 9999;
  cursor: pointer;
  padding-inline-end: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.feedback ::placeholder {
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.feedback :-ms-input-placeholder {
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.feedback ::-ms-input-placeholder {
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.feedback .umbraco-forms-form {
  flex: 1;
}
.feedback .singlechoice label {
  position: relative;
  inset-block-start: unset;
  inset-inline-start: unset;
}
.feedback .singlechoice .radiobuttonlist {
  display: flex;
  gap: 5px;
}
.feedback .phone-number-xl {
  color: #0070DF;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 2px;
}
.feedback .phone-number-xl:before {
  content: url("/assets/img/img/phone-icon-2.svg");
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
}
html[dir=rtl] .feedback .phone-number-xl::before {
  margin-right: -25px;
  margin-left: 10px;
  transform: rotate(245deg);
}
.feedback .field-validation-error {
  color: #E54E4E;
  margin-top: 11px;
  display: block;
}
.feedback .form-control.input-validation-error {
  border-color: #E54E4E;
}

html[dir=rtl] .feedback input[type=text],
html[dir=rtl] .feedback input[type=number],
html[dir=rtl] .feedback input[type=email],
html[dir=rtl] .feedback input[type=tel],
html[dir=rtl] .feedback select,
html[dir=rtl] .feedback textarea {
  font-family: "Din", sans-serif;
  font-size: 14px;
}
html[dir=rtl] .feedback ::placeholder {
  font-family: "Din", sans-serif;
  font-size: 14px;
}
html[dir=rtl] .feedback input[type=text] {
  font-weight: 700;
}
html[dir=rtl] .feedback input[type=submit] {
  font-family: "Din", sans-serif;
  font-weight: 700;
  line-height: 22px;
}

/*=====  End of Feedback Form  ======*/
html[dir=rtl] .form-group label {
  font-family: "Din", sans-serif;
}
html[dir=rtl] .form-group input {
  direction: rtl;
}

.field-validation-error {
  color: #E54E4E;
  margin-top: 11px;
  display: block;
}

.form-control.input-validation-error {
  border-color: #E54E4E;
}

.product-inquiry {
  background-color: #f7f9fb;
  gap: 22px;
}
.product-inquiry__wrapper {
  padding-block: 57px 32px;
}
.product-inquiry__heading {
  color: #0070df;
  font-size: 26px;
  font-weight: 900;
  line-height: 35px;
  letter-spacing: 0.5px;
}
@media (max-width: 1249.98px) {
  .product-inquiry__heading {
    padding-inline: 16px;
  }
}
@media (max-width: 767.98px) {
  .product-inquiry__heading {
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.5px;
  }
}
.product-inquiry__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.product-inquiry__image-container {
  width: 200px;
  margin: auto;
}
.product-inquiry__icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.product-inquiry__icon-container {
  width: 148px;
  margin: 0 auto;
  margin-block-end: 25px;
}
.product-inquiry__content {
  position: relative;
  width: 100%;
  background-color: white;
  padding-block: 48px 24px;
  padding-inline: 224px 216px;
  gap: 38px;
}
@media (max-width: 991.98px) {
  .product-inquiry__content {
    padding-inline: 16px;
  }
}
.product-inquiry__top {
  gap: 32px;
}
.product-inquiry__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(10px);
  z-index: 20000;
  padding-block-start: 168px;
  display: none;
}
.product-inquiry__overlay--active {
  display: flex;
}
.product-inquiry__overlay .rte {
  margin-block-end: 32px;
  max-width: 535px;
  text-align: center;
}
.product-inquiry__submit-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: center;
  margin-block-end: 16px;
  max-width: 535px;
}
.product-inquiry__close {
  position: absolute;
  top: 32px;
  right: 33px;
  font-size: 34px;
  color: #808082;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.product-inquiry__close:hover, .product-inquiry__close:focus {
  opacity: 0.7;
}
.product-inquiry .umbraco-forms-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.product-inquiry .umbraco-forms-navigation {
  display: flex;
  justify-content: flex-end;
}
.product-inquiry input[type=text],
.product-inquiry input[type=tel],
.product-inquiry input[type=email],
.product-inquiry select,
.product-inquiry textarea {
  color: #a3a9b0;
  border-radius: 10px;
  border: 2px solid #ebedef;
  padding: 14px 20px;
  padding-top: 33px;
  width: 100%;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  resize: none;
}
.product-inquiry input[type=text]::-ms-input-placeholder,
.product-inquiry input[type=tel]::-ms-input-placeholder,
.product-inquiry input[type=email]::-ms-input-placeholder,
.product-inquiry select::-ms-input-placeholder,
.product-inquiry textarea::-ms-input-placeholder {
  color: #a3a9b0;
}
.product-inquiry input[type=text]::placeholder,
.product-inquiry input[type=tel]::placeholder,
.product-inquiry input[type=email]::placeholder,
.product-inquiry select::placeholder,
.product-inquiry textarea::placeholder {
  color: #a3a9b0;
}
.product-inquiry select {
  position: relative;
  padding-inline-end: 50px;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/chevron-down-active.svg");
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  z-index: 9999;
  cursor: pointer;
}
.product-inquiry input[type=submit] {
  padding: 19px 62px;
  background-color: #0070df;
  color: #fff;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  margin-top: 24px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .product-inquiry input[type=submit] {
    width: 100%;
  }
}
.product-inquiry input[type=submit]:hover, .product-inquiry input[type=submit]:focus {
  background-color: #273a53;
}
.product-inquiry label {
  color: #a3a9b0;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  inset-block-start: 14px;
  inset-inline-start: 20px;
}
.product-inquiry .dataconsent label {
  position: relative;
  top: unset;
  left: unset;
}
.product-inquiry .form-group {
  position: relative;
}
.product-inquiry .field-validation-valid {
  color: #e54e4e;
  margin-top: 11px;
  display: block;
}
.product-inquiry .field-validation-valid:empty {
  margin-top: 0;
}
.product-inquiry .form-control.input-validation-error {
  border-color: #e54e4e;
}

html[dir=rtl] .product-inquiry ::placeholder {
  font-family: "Din", sans-serif;
}
html[dir=rtl] .product-inquiry input[type=text],
html[dir=rtl] .product-inquiry input[type=tel],
html[dir=rtl] .product-inquiry input[type=email],
html[dir=rtl] .product-inquiry select,
html[dir=rtl] .product-inquiry textarea {
  font-family: "Din", sans-serif;
  font-size: 14px;
}
html[dir=rtl] .product-inquiry input[type=submit] {
  font-family: "Din", sans-serif;
  font-weight: 700;
  line-height: 22px;
}
html[dir=rtl] .product-inquiry select {
  background-position-x: 20px;
}

/*=============================================
=            Whistleblower Component            =
=============================================*/
.whistleblower {
  background-color: transparent;
}
.whistleblower ::placeholder {
  color: #808082 !important;
}
.whistleblower__right {
  flex: 1;
  position: relative;
  padding-block: 12px;
}
.whistleblower__overlay {
  z-index: 99999;
  position: absolute;
  padding: 50px;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}
.whistleblower__overlay-cross {
  width: 20px;
  position: absolute;
  inset-inline-end: 35px;
  inset-block-start: 25px;
  cursor: pointer;
}
.whistleblower__overlay-image {
  width: 150px;
}
.whistleblower__overlay-title {
  color: #35475E;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.whistleblower__overlay-text {
  color: #273a53;
  max-width: 450px;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.whistleblower__top {
  padding-block: 56px;
  padding-inline: 26px;
  gap: 32px;
  margin: 0 auto;
  max-width: 1176px;
}
@media (max-width: 767.98px) {
  .whistleblower__top {
    padding: 32px 26px 56px;
  }
}
.whistleblower__content ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
  max-width: 80%;
  gap: 20px;
  margin-inline-start: 0;
  margin-block: 28px;
}
.whistleblower__content ul li {
  color: #273A53;
  list-style: none;
  padding-inline-start: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: auto 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.5px;
  border-inline-start: 3px solid #0070df;
}
.whistleblower__content h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.5px;
  margin-bottom: 28px;
  color: #0070df;
}
.whistleblower__content h4 {
  color: #0070df;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.whistleblower__content p {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.whistleblower__bottom {
  background-color: #fff;
}
.whistleblower__inner {
  background-color: #fff;
  flex-direction: row-reverse;
  gap: 94px;
  padding-block: 56px;
  padding-inline: 30px;
}
@media (max-width: 767.98px) {
  .whistleblower__inner {
    flex-direction: column-reverse;
    padding-block: 54px;
    gap: 54px;
  }
}
.whistleblower__heading {
  font-size: 50px;
  font-weight: 500;
  line-height: 59px;
  color: #0070df;
  margin-bottom: 70px;
}
@media (max-width: 767.98px) {
  .whistleblower__heading {
    font-size: 36px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.5px;
    margin-bottom: 39px;
  }
}
.whistleblower__left {
  flex: 1;
  padding-inline-start: 40px;
  max-width: 400px;
}
@media (max-width: 767.98px) {
  .whistleblower__left {
    padding-inline: 32px;
  }
}
.whistleblower__link {
  color: #273a53;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.whistleblower__link:hover, .whistleblower__link:focus {
  color: #0070df;
}
.whistleblower__link-icon {
  height: 36px;
  width: 36px;
  margin-inline-end: 13px;
  margin-block-start: 5px;
}
.whistleblower__link-number {
  font-family: Lato;
  color: #0070df;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 2px;
}
.whistleblower__link-text {
  margin-block-start: 6px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.whistleblower .umbraco-forms-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.whistleblower .umbraco-forms-container .radiobuttonlist {
  margin-block-start: 16px;
  display: flex;
  gap: 5px;
}
.whistleblower .umbraco-forms-container .singlechoice label {
  color: #000;
}
.whistleblower .umbraco-forms-container .shouldsharecontactinfo,
.whistleblower .umbraco-forms-container .multiplechoice {
  inset-block-start: 10px;
}
.whistleblower .umbraco-forms-container .shouldsharecontactinfo label,
.whistleblower .umbraco-forms-container .multiplechoice label,
.whistleblower .umbraco-forms-container .singlechoice label {
  inset-block-start: unset;
  inset-inline-start: unset;
  position: relative;
}
.whistleblower .umbraco-forms-page {
  flex: 1;
  padding-inline: 32px;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .whistleblower .umbraco-forms-page {
    margin-inline-end: 0;
  }
}
.whistleblower label {
  color: #808082;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  inset-block-start: 14px;
  inset-inline-start: 20px;
}
.whistleblower .form-group {
  position: relative;
}
.whistleblower .form-group__title {
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  color: #273a53;
  margin-bottom: 16px;
}
.whistleblower .form-group-radio label {
  position: static;
  margin-inline-start: 7px;
  margin-inline-end: 15px;
}
.whistleblower .form-group-radio input[type=radio] {
  cursor: pointer;
}
.whistleblower .form-group-radio .umbraco-forms-field-wrapper {
  display: inline;
}
.whistleblower input[type=text],
.whistleblower input[type=number],
.whistleblower input[type=email],
.whistleblower input[type=tel],
.whistleblower select,
.whistleblower textarea {
  border-radius: 12px;
  border: 1px solid #CFCFCF;
  padding: 14px 20px;
  padding-top: 33px;
  width: 100%;
  color: #808082;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  resize: none;
}
.whistleblower input[type=submit] {
  padding: 18px 54px;
  background-color: #0070df;
  color: #fff;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  margin-top: 24px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .whistleblower input[type=submit] {
    width: 100%;
  }
}
.whistleblower input[type=submit]:hover, .whistleblower input[type=submit]:focus {
  background-color: #273a53;
}
.whistleblower select {
  position: relative;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/chevron-down-active.svg");
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  z-index: 9999;
  cursor: pointer;
}
.whistleblower ::placeholder {
  color: #000;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.whistleblower :-ms-input-placeholder {
  color: #000;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.whistleblower ::-ms-input-placeholder {
  color: #000;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.whistleblower .field-validation-error {
  color: #E54E4E;
  margin-top: 11px;
  display: block;
}
.whistleblower .form-control.input-validation-error {
  border-color: #E54E4E;
}

html[dir=rtl] .whistleblower__link-icon {
  margin-inline-end: 30px;
  transform: scaleX(-1);
}
html[dir=rtl] .whistleblower__content ul li {
  padding-block-start: 0;
  padding-block-end: 16px;
}
html[dir=rtl] .whistleblower__content p {
  font-family: "Din", sans-serif;
}
html[dir=rtl] .whistleblower__left {
  flex: none;
  max-width: 360px;
}
html[dir=rtl] .whistleblower .umbraco-forms-bootstrap3-horizontal {
  width: 100%;
}
html[dir=rtl] .whistleblower input[type=text],
html[dir=rtl] .whistleblower input[type=number],
html[dir=rtl] .whistleblower input[type=email],
html[dir=rtl] .whistleblower input[type=tel],
html[dir=rtl] .whistleblower select,
html[dir=rtl] .whistleblower textarea {
  font-family: "Din", sans-serif;
  font-size: 14px;
}
html[dir=rtl] .whistleblower ::placeholder {
  font-family: "Din", sans-serif;
  font-size: 14px;
}
html[dir=rtl] .whistleblower input[type=text] {
  font-weight: 700;
}
html[dir=rtl] .whistleblower input[type=submit] {
  font-family: "Din", sans-serif;
  font-weight: 700;
  line-height: 22px;
}
html[dir=rtl] select {
  font-family: "Din", sans-serif;
  position: relative;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/chevron-down-active.svg");
  background-position-x: 20px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  z-index: 9999;
  cursor: pointer;
}

/*=====  End of Whistleblower Component  ======*/
.sitemap-page {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 27px;
    row-gap: 28px;
    align-items: start;
    font-family: $font-family--base;

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
    }

    &-container {
        padding: 56px 32px;
        background-color: #f7f9fb;

        @include media-breakpoint-down(sm) {
            padding: 32px 26px;
            font-size: 12px;
        }
    }

    &__title {
        color: $text--secondary;
        font-size: 26px;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0.5px;
        margin-block-end: 32px;
    }

    &__item {
        max-height: fit-content;
        padding: 20px;
        background-color: $bg--white;

        &-title {
            color: $text--secondary;
            font-size: 14px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0.5px;
        }

        &-subtitle {
            font-size: 15px;
            font-weight: 600;
            line-height: 24px;
            margin-block-start: 5px;
            margin-block-end: 12px;
            color: $text--secondary;
            font-family: 'Neue Haas Grotesk Display Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }

        &-accordion-title {
            font-family: Lato;
            font-size: 14px;
            font-weight: 900;
            line-height: 25px;
            letter-spacing: 0.5px;
        }

        &-link {
            margin-block-end: 8px;
        }
    }

    summary::-webkit-details-marker {
        display: none;
    }

    summary {
        cursor: pointer;
        list-style: none;
        padding-inline-start: 30px;
        position: relative;
        margin-block-end: 8px;
    
        &[data-direction="ltr"]::after {
            content: '';
            border-right: 1px solid;
            border-bottom: 1px solid;
            position: absolute;
            left: 5px;
            top: 10px;
            height: 8px;
            width: 8px;
            transform: rotate(-45deg) translatey(-2px);
        }
    
        &[data-direction="rtl"]::before {
            content: '';
            border-right: 1px solid;
            border-bottom: 1px solid;
            position: absolute;
            right: 5px;
            top: 8px;
            height: 8px;
            width: 8px;
            transform: rotate(135deg) translatey(-2px);
        }
    }    

    details[open] > summary::after {
        transform: rotate(45deg) translatey(-6px);
        left: 0px;
    }

    details > div {
        padding-inline-start: 30px;
    }
}

html[dir='rtl'] {
    .sitemap-page {
        &-subtitle,
        &-accordion-title {
            font-family: $font-family--ar;
        }
    }
}

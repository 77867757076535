html,
body {
    font-family: $font-family--base;
    font-weight: 400;
    overflow-x: hidden;
    color: $text--black;
    scroll-behavior: smooth;
    background-color: #F7F9FB;
}
  
[dir="rtl"] body {
    font-family: $font-family--ar;
}

.container {
    margin: 0 auto;
    max-width: 1124px;

    &--full {
        width: 100%;
        margin: 0 auto;
    }

    &--awkward {
        margin: 0 auto;
        max-width: $container-width--default;
        padding: 0 200px;

        @include media-breakpoint-down(lg) {
            padding: 0 16px;
        }
    }

    &--has-margin {
        padding-top: 60px;
        padding-bottom: 60px;

        @include media-breakpoint-down(md) {
            padding-bottom: 30px;
            padding-top: 30px;
        }
    }
}

.background--grey.background--grey {
    background-color: #f7f9fb;
}

.hidden {
    display: none;
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.rich-text {
    h1,
    h2,
    h3,
    h4,
    h5 {
        display: block;
        margin-block: 24px;
        font-weight: 400;

        &:first-child {
            margin-block-start: 0;
        }
    }

    h2 {
        color: $text--primary;
        font-size: 26px;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0.5px;
    }

    h3 {
        color: $secondary;
        font-size: 26px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0.5px;
    }

    p,
    ol,
    ul {
        font-size: 17px;
        color: #35475E;
        display: block;
        margin-block-end: 5px;
        font-weight: 500;
        line-height: 25px; 
        letter-spacing: 0.5px;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
            line-height: 20px;
        }
    }

    ul,
    ol,
    li {
        margin-inline-start: 16px;
    }

    ul,
    ol {
        margin-block-start: 20px;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    strong {
        font-weight: 700;
    }

    a.underline {
        text-decoration: underline;
    }

    table {
        margin-block-start: 24px;
        border-collapse: collapse;
        width: 100%;
        color: #273A53;
        font-family: DM Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.5px;
    }

    th {
        font-size: 13px;
        color: $white;
        background-color: #91a1b6;
    }

    tbody tr {
        background-color: $white;
    }

    tbody tr:nth-child(odd) {
        background-color: #F1F3F4;
    }

    th,
    td {
        padding: 15px 8px;
        text-align: start;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }

        &:first-child {
            padding-inline-start: 20px;
        }

        &:last-child {
            padding-inline-end: 5px;
        }
    }

    &--blue {
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        a,
        ol,
        ul,
        table,
        th {
            color: $white;
        }

        th {
            background-color: $blue;
        }

        tbody tr {
            background-color: $secondary;
        }

        tbody tr:nth-child(odd) {
            background-color: $light-blue;
        }
    }
}

/* Branding on cookie banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none;
}

.rtl {
    direction: rtl;
}

.icon-saudi_riyal {
    &::after {
        content: '';
        display: block;
        height: 37px;
        width: 33px;
        background: url('/assets/img/img/riyal.svg') no-repeat;
        background-size: contain;
        filter: brightness(0) saturate(100%) invert(31%) sepia(77%) saturate(4755%) hue-rotate(198deg) brightness(95%) contrast(101%);
    }

    &--s {
        &::after {
            height: 12px;
            width: 10.74px;
            filter: none;
            margin-bottom: 2px;
        }
    }

    &--m {
        &::after {
            height: 13px;
            width: 11.63px;
            filter: none;
            margin-bottom: 2px;
        }
    }
}
.plain-text {
    padding: 56px 32px;
    background-color: #f7f9fb;

    @include media-breakpoint-down(sm) {
        padding: 32px;
        font-size: 12px;
    }

    &--inline {
        margin: 0;
        max-width: none;
    }

    &--white {
        color: $text--white;
    }

    &__title {
        margin-block-end: 10px;
    }

    &__subtitle {
        margin-block-end: 56px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        display: block;
        margin-block: 24px;
        font-weight: 400;

        &:first-child {
            margin-block-start: 0;
        }
    }

    h2 {
        color: $text--primary;
        font-size: 26px;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0.5px;
    }

    h3 {
        color: $secondary;
        font-size: 26px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0.5px;
    }

    p,
    ol,
    ul {
        font-size: 17px;
        color: #35475E;
        display: block;
        margin-block-end: 5px;
        font-weight: 500;
        line-height: 25px; 
        letter-spacing: 0.5px;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
            line-height: 20px;
        }
    }

    ul,
    ol,
    li {
        margin-inline-start: 16px;
    }

    ul,
    ol {
        margin-block-start: 20px;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    strong {
        font-weight: 700;
    }

    a.underline {
        text-decoration: underline;
    }

    table {
        margin-block-start: 24px;
        border-collapse: collapse;
        width: 100%;
        color: #273A53;
        font-family: DM Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.5px;
    }

    th {
        font-size: 13px;
        color: $white;
        background-color: #91a1b6;
    }

    tbody tr {
        background-color: $white;
    }

    tbody tr:nth-child(odd) {
        background-color: #F1F3F4;
    }

    th,
    td {
        padding: 15px 8px;
        text-align: start;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }

        &:first-child {
            padding-inline-start: 20px;
        }

        &:last-child {
            padding-inline-end: 5px;
        }
    }
}

html[dir="rtl"] {
    .plain-text {
        table {
            font-family: $font-family--ar;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
        }

        p {
            font-size: 15px;
            font-weight: 400;
            line-height: 28px; 
        }
    }
}

/*=====  End of RTE  ======*/

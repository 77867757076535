$black: #000;
$white: #fff;

// primary colours

$primary: #273a53;
$secondary: #0070df;
$dark-blue: #141c28;
$light-blue: #207dda;
$blue: #35475E;
$grey: #91a1b6;
$light-grey: #e4e4e4;
$off-grey: #f7f9fb;
$slate-grey: #a3a9b0;
$green: #18cdb7;
$yellow: #ffe000;

// secondary colours

$success-color: #3a9b39;
$warning-color: #edad31;
$error-color: #d32929;

// background colours

$bg--black: $black;
$bg--white: $white;

$bg--primary: $primary;
$bg--secondary: $secondary;
$bg--dark-blue: $dark-blue;
$bg--grey: $grey;
$bg--light-grey: $light-grey;
$bg--off-grey: $off-grey;
$bg--slate-grey: $slate-grey;
$bg--green: $green;
$bg--yellow: $yellow;

// text colours

$text--black: $black;
$text--white: $white;
$text--primary: $primary;
$text--secondary: $secondary;
$text--dark-blue: $dark-blue;
$text--grey: $grey;
$text--light-grey: $light-grey;
$text--off-grey: $off-grey;
$text--slate-grey: $slate-grey;
$text--green: $green;
$text--yellow: $yellow;

/*=============================================
=            Not Found            =
=============================================*/

.not-found {
  min-height: 1051px;

  @include media-breakpoint-down(sm) {
    min-height: 745px;
  }

  &__inner {
    padding: 0 26px;
    color: $text--primary;
    min-height: inherit;
    padding-top: 208px;
    text-align: center;
    gap: 28px;

    @include media-breakpoint-down(sm) {
      padding-top: 172px;
    }
  }

  &__image {
    height: 100%;
    width: 100%;

    &-container {
      max-width: 182px;

      @include media-breakpoint-down(sm) {
        max-width: 130px;
    }
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 500;
    line-height: 32px; 

    @include media-breakpoint-down(sm) {
      font-size: 25px;
      line-height: 29px;
      letter-spacing: 0.5px;
    }
  }

  &__text {
    color: #808082;
    font-family: Lato;
    font-size: 17px;
    font-weight: 400;
    line-height: normal;

    @include media-breakpoint-down(sm) {
      font-family: $font-family--base;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.5px;
  }
  
    a {
      border-bottom: 1px solid #808082;

      &:hover, &:focus {
        color: $text--secondary;
        border-color: $secondary;
      }
    }
  }
  
  .link {
    font-family: 'Go', sans-serif;
    padding: 18px 54px;
    font-size: 15px;
    line-height: 22px;

    @include media-breakpoint-down(sm) {
      width: 100%;
  }
  }
}

/*=====  End of Not Found  ======*/

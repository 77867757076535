.lhs-tab {
    display: flex;
    flex-direction: column;
    gap: 56px;
    font-family: $font-family--base;

    &__tabs {
        gap: 10px;
        border: 1px solid #E4EBF2;
        border-radius: 6px;
        width: fit-content;
        padding: 8px 10px;
        display: inline-flex;
        flex-direction: row;
        background-color: #fff;
        flex-wrap: wrap;
        box-sizing: border-box;
    }

    &__card {
        display: flex;
        flex-direction: column;
        gap: 56px;

        &-content {
            color: #273A53;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.5px;

            .container {
                margin: 0;
            }
        }
    }

    &__label {
        cursor: pointer;
        text-align: center;
        width: fit-content;
        min-width: 150px;
        transition: background-color, border-color 0.2s ease-in-out;
    }

    &__radio {
        width: 0;
        height: 0;
        position: absolute;
        left: -9999px;
    }

    &__radio+&__label {
        margin: 0;
        padding: 17px 22px;
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        border-radius: 6px;
        font-size: 17px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;
        border: 1px solid transparent;
    }

    &__radio:hover+&__label {
        border-color: $secondary;
    }

    &__radio:checked+&__label {
        background-color: $bg--secondary;
        color: #FFF;
        z-index: 1;
        font-size: 17px;
        line-height: 25px;
        letter-spacing: 0.5px;
        font-weight: 900;
    }

    &__tick {
        width: 24px;
        height: 24px;
    }

    &__ticklist {
        &-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 28px;
        }

        &-title {
            color: $bg--secondary;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
            letter-spacing: 0.5px;
        }

        &-bullets {
            gap: 21px;
            display: flex;
            flex-direction: column;
        }

        &-bullet {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 13px;
            color: #273A53;
            font-family: $font-family--base;
            font-size: 14px;
            font-style: normal;

            p {
                font-family: $font-family--base;
                font-size: 14px;
                font-style: normal;
            }

            ul {
                list-style-type: disc;

                li {
                    margin-left: 20px;
                    line-height: 24px;
                    font-size: 13px;
                }
            }
        }

        &-bullet:last-child {
            padding-bottom: 0;
        }
    }

    &__bottom {
        &-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 28px;
        }

        &-title {
            color: $bg--secondary;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
            letter-spacing: 0.5px;
        }

        &-image {
            width: 32px;
            height: 32px;
        }

        &-items {
            &-container {
                display: flex;
                flex-direction: column;
                gap: 28px;
                padding-bottom: 0;
            }
        }

        &-item {
            display: flex;
            flex-direction: row;
            gap: 13px;

            &-title {
                color: $bg--secondary;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0.5px;
            }

            &-image {
                width: 32px;
                height: 32px;
            }

            &-content {
                color: #273A53;
                font-family: $font-family--base;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.5px;
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    .lhs-tab {
        padding: 32px 26px;
        font-size: 12px;

        &__tabs {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &__radio+&__label {
            width: 100%;
            text-align: center;
            padding: 10px 16px;
        }

        &__containers {
            flex-direction: column;
            gap: 32px;
        }

        &__card {
            gap: 32px;
        }

        &__rhs {
            &-items {
                &-container {
                    gap: 16px;
                }
            }

            &-item {
                gap: 10px;
            }
        }
    }
}

html[dir="rtl"] {
    .lhs-tab {
      font-family: DIN;

      &__ticklist {
        &-bullet {
            font-family: DIN;

            p {
                font-family: DIN;
            }
        }
      }

      &__bottom {
        &-item {
            &-content {
                font-family: DIN;
            }
        }
      }
    }
  }
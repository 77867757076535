/*=============================================
=            App Download CTA            =
=============================================*/

.media-with-text {
  max-width: 1150px;

  &__inner {
    color: $text--white;
    margin-inline: 24px;
  
    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-block-end: 0;
    }
  }

  &__left {
    min-height: 100%;
    flex: .94;

    img {
      height: 100%;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        max-height: 206px;
      }
    }
  }

  &__right {
    flex: 1;
    background-color: $bg--secondary;
    padding: 56px 40px;

    @include media-breakpoint-down(md) {
      padding: 24px;
    }
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.5px;
    margin-block-end: 8px;

    @include media-breakpoint-down(md) {
      max-width: 265px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
    }
  }

  &__sub-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px; 
    margin-block-end: 24px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
    }
  }

  &__rte {
    max-width: 450px;

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
  }

  &__button-title {
    max-width: 400px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-block: 32px 24px;

    @include media-breakpoint-down(md) {
      max-width: 270px;
      text-align: center;
    }
  }

  &__download {
    &--desktop {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &--mobile {
      background-color: $bg--secondary;
      margin-block-start: 40px;
      padding-block-end: 32px;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

html[dir='rtl'] {
  .media-with-text {
    &__title {
      font-size: 36px;
      font-weight: 500;
      line-height: 38.21px;
      margin-block-end: 8px;
    }

    &__sub-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 38.21px; 
      margin-block-end: 12px;
  
      @include media-breakpoint-down(md) {
        font-size: 14px;
        font-weight: 500;
        line-height: 38.21px;
        margin-block-end: 14px;
      }
    }

    &__button-title {
      max-width: 430px;
      font-size: 24px;
      font-weight: 500;
      line-height: 38px;
      margin-block: 18px 68px;

      @include media-breakpoint-down(md) {
        margin-block: 48px 24px;
      }
    }

    &__right {
      @include media-breakpoint-down(md) {
        padding-block: 48px;
      }
    }

    &__rte {
      max-width: 450px;
  
      p {
        line-height: 28px;
      }

      br {
        height: 12px;
      }
    }

    &__download--mobile {
      @include media-breakpoint-down(md) {
        padding-block-end: 48px;
      }
    }
  }
}
/*=====  End of App Download CTA  ======*/

/*=============================================
=            link            =
=============================================*/

.link {
  cursor: pointer;
  padding: 12px 27px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  gap: 8px;
  min-width: fit-content;

  @include transition; 

  &--underline .link__label{
    text-decoration: underline;
  }

  &--primary {
    background-color: $bg--primary;
    color: $text--white;
  }

  &--white-rounded {
    background-color: $bg--white;
    color: $text--secondary;
    border-radius: 12px;

    &:hover, &:focus {
      background-color: $bg--primary;
    }
  }

  &--secondary {
    background-color: $bg--secondary;
    color: $text--white;

    &-large {
      background-color: $bg--secondary;
      color: $text--white;
      padding: 19px 55.5px;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;

      &:hover, &:focus {
        background-color: $bg--primary;
        color: $text--white;
      }

      @include media-breakpoint-down(xl) {
        padding: 12px 27px; 
        font-size: 14px;
      }

      &-white {
        background-color: $bg--white;
        color: $text--secondary;
        padding: 19px 55.5px;
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
  
        &:hover, &:focus {
          background-color: $bg--primary;
          color: $text--white;
        }
  
        @include media-breakpoint-down(xl) {
          padding: 12px 27px; 
          font-size: 14px;
        }
      }
    }

    &:hover, &:focus {
      background-color: $bg--primary;
      color: $text--white;
    }
  }

  &--outline {
    background-color: $bg--white;
    color: $text--primary;
    border: 2px solid $primary;

    &:hover, &:focus {
      color: $text--secondary;
      border-color: $secondary;
    }
  }

  &--simple-primary {
    color: $text--primary;
    gap: 10px;

    &:hover, &:focus {
      color: $text--secondary;
    }
  }

  &--simple-secondary {
    color: $text--secondary;
    gap: 10px;

    &:hover, &:focus {
      color: $text--primary;
    }
  }

  &--simple-white {
    color: $text--white;
    gap: 10px;

    &:hover, &:focus {
      color: $text--secondary;
    }
  }
}

html[dir="rtl"] {
  .link {
    font-size: 18px;
    font-weight: 500;
    line-height: 49px;
    padding-block: 0;
  
    .zmdi-arrow-right, .zmdi-arrow-left{
      transform: scaleX(-1) translateY(3px);
    }
  
    &--secondary-large-white {
      line-height: 49px;
    }

    &__label {
      transform: translateY(-3px);
    }
  }
}

/*=====  End of link  ======*/

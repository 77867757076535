#launcher {
    min-width: 60px !important;
    min-height: 80px !important;
}

#launcher, #webWidget {
    left: initial !important;
    right: 20px !important;
}

html[dir='rtl'] {
    #launcher,
    #webWidget {
        right: initial !important;
        left: 20px !important;
    }
}

/*=============================================
=            Contact Form            =
=============================================*/

.contact {
  padding: 0 26px 54px;

  &-wrapper {
    background-color: #F7F9FB;
  }

  &__inner {
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.5px;
    color: $text--secondary;
    padding: 56px 26px 28px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &-mobile {
      font-size: 25px;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: 0.5px;
      background-color: $bg--white;
      color: $text--secondary;
      padding: 22px 26px 0;
      margin-top: 27px;

      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }

  &__right {
    flex: 0.74;
    padding: 32px 32px 40px;
    background-color: $bg--white;

    &-title {
      font-size: 29px;
      font-weight: 500;
      line-height: 41px;
      color: $text--primary;
      margin-bottom: 4px;

      @include media-breakpoint-down(sm) {
        color: $text--secondary;
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.5px;
    }
  }

  &__overlay {
    &__failure {
      width: 100%;
      padding: 15px 24px;
      display: flex;
      align-items: center;
      border: 1.5px solid var(--secondary-500, #F55C5C);
      border-radius: 12px;
      background: #FFF5F5;

      &-image {
        width: 34px;
        height: 34px;
        margin-right: 14px;
      }

      &-text {
        color: var(--secondary-600, #E54E4E);
        font-family: Go;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    &__success {
    z-index: 99999;
    position: absolute;
    padding: 50px;
    inset-inline-start: 0;
    inset-block-start: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(10px);

    &-cross {
      width: 20px;
      position: absolute;
      inset-inline-end: 35px;
      inset-block-start: 25px;
      cursor:pointer;
    }

    &-image {
      width: 150px;
      margin-block-start: 150px;
      margin-inline: auto;
      margin-block-end: 25px;
    }

    &-title {
      color: #35475E;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      margin-block-end: 18px;
    }

    &-text {
      color: $primary;
      max-width: 450px;
      text-align: center;
      font-family: Neue Haas Grotesk Display Pro;
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.5px;
    }
  }
}

  // umbraco form styles
  .umbraco-forms-container {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  &__left {
    position: relative;
    flex:1;
    padding: 32px;
    background-color: $bg--white;
    margin-right: 33px;
    height: fit-content;

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 33px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.5px;
    }
  }

  label {    
    color: #808082;
    font-family: $font-family--base;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    position: absolute;
    inset-block-start: 14px;
    inset-inline-start: 20px;
  }

  .dataconsent label {
    position: relative;
    top: unset;
    left: unset;
  }

  .form-group {
    position: relative;
  }

  input[type="text"],
  input[type="number"],
  input[type="email"], 
  input[type="tel"],
  select, 
  textarea {
    color: #808082;
    border-radius: 12px;
    border: 1px solid #CFCFCF;
    padding: 14px 20px;
    padding-top: 33px;
    width: 100%;
    font-family: $font-family--base;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    resize: none;

    &::-ms-input-placeholder {
      color: #808082;
    }

    &::placeholder {
      color: #808082;
    }
  }

  input[type="submit"] {
    padding: 18px 54px;
    background-color: $bg--secondary;
    color: $text--white;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
    margin-top: 24px;

    @include transition;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:hover, &:focus {
      background-color: $bg--primary;
    }
  }

  select {
    position: relative;
    -webkit-appearance: none;
    background-image: url('/assets/img/img/chevron-down-active.svg');
    background-position-x: calc($container-width--wide - 20px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 24px;
    z-index: 9999;
    cursor: pointer;
  }

  ::placeholder { 
    color: #808082;
    font-family: $font-family--base;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  :-ms-input-placeholder {
    color: #808082;
    font-family: $font-family--base;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  ::-ms-input-placeholder { 
    color: #808082;
    font-family: 'Go', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  input::-webkit-inner-spin-button {
    display: none !important;
  }

  .field-validation-error
  {
    color: #E54E4E;
      margin-top: 11px;
      display: block;
  }
  .form-control.input-validation-error
  {
      border-color: #E54E4E;
  }
}

html[dir="rtl"] {
  .contact {
    ::placeholder { 
      font-family: $font-family--ar;
    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    select,
    textarea {
      font-family: $font-family--ar;
      font-size: 14px;
    }

    input[type="submit"] {
      font-family: $font-family--ar;
      font-weight: 700;
      line-height: 22px;
    }
  }
}

/*=====  End of Contact Form  ======*/

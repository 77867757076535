/*=============================================
=            Feature Hero Item Full            =
=============================================*/

.feature-hero {
  &__item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 553px;
    color: $text--secondary;

    &-inner {
      width: 100%;
      height: 100%;
      max-width: 1178px;

      @include media-breakpoint-down(md){
        flex-direction: column;
      }
    }

    &-left {
      height: 100%;
      width: 100%;
      flex: 1;
      margin: 0 auto;

      h1, 
      h2 {
        font-size: 55px;
        font-weight: 700;
        line-height: 58px; 

        @include media-breakpoint-down(md){
          font-size: 33px;
          line-height: 39px;
        }
      }

      .link {
        margin-bottom: 21px;
      }

      @include media-breakpoint-down(md){
        text-align: center;
        align-items: center;
        justify-content: center;
        height: auto;
        flex: none;
      }
      
      @include media-breakpoint-down(md) {
        flex: 0;
        width: 100%;
  
        .link {
          display: none;
        }
      }
    }
  
    &-right {
      flex: 1;
      height: 100%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(md) {
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      min-height: 517px;
    }
  }
}



/*=====  End of Feature Hero  ======*/

/*=============================================
=            News Detail            =
=============================================*/

.news-detail {
  padding: 0 26px;
  position: relative;

  &__heading {
    color: $text--secondary;
    font-size: 26px;
    font-weight: 700;
    line-height: 35px; 
    letter-spacing: 0.5px;
    padding-block-start: 56px;
    padding-block-end: 24px;

    @include media-breakpoint-down(md) {
      font-size: 25px;
      font-weight: 500;
      line-height: 29px;
    }
  }

  &__wrapper {
    padding-bottom: 76px;
    background-color: #F7F9FB;

    @include media-breakpoint-down(md) {
      padding-bottom: 50px;
    }
  }

  &__close {
    position: absolute;
    top: 21px;
    right: 43px;
    cursor: pointer;

    &-icon {
      font-size: 24px;
      color: #808082;

      @include transition;
    }

    &:hover, &:focus {
      .news-detail__close-icon {
        color: $text--secondary;
      }
    }
  }
  
  &__inner {
    gap: 24px;
  }

  &__headline {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px; 
    letter-spacing: 0.5px;
    color: $text--primary;
    margin-bottom: 8px;
  }

  &__date {
    color: $text--secondary;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px; 
    letter-spacing: 0.5px;
    margin-bottom: 13px;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;

    &-container {
      height: 100%;
      width: 100%;
      max-height: 337px;
      overflow: hidden;
    }
  }

  &__item {
    background-color: $bg--white;

    &-inner {
      gap: 20px;
      padding-bottom: 100px;
      padding-inline: 24px 40px;
      
      @include media-breakpoint-down(md) {
        padding-bottom: 30px;
        padding-inline: 30px;
      }
    }

    &-top {
      width: 100%;
      padding: 53px 30px 36px;
    }
  }

  .rte {
    font-size: 17px;
    font-weight: 500;
    line-height: 25px; 
    letter-spacing: 0.5px;
    color: $text--primary;
  }
}

/*=====  End of News Detail  ======*/

/*=============================================
=            Overview Tiles            =
=============================================*/

.overview-tiles {
  background-color: $bg--white;

  &__inner {
    padding-block: 56px;
    gap: 64px;

    @include media-breakpoint-down(xl) {
      padding-inline: 24px;
    }

    @include media-breakpoint-down(md) {
      gap: 24px;
    }
  }

  &__heading {
    gap: 16px;
    text-align: center;

    @include media-breakpoint-down(md) {
      gap: 8px;
    }
  }

  &__header {
    color: $text--black;
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;

    @include rtl {
      font-family: "Din", sans-serif;
      font-size: 44px;
      line-height: 64.68px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 35.28px;
      }
    }

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 28.8px;
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 24px;
    line-height: 28.8px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 21.6px;
    }

    @include rtl {
      font-family: "Din", sans-serif;
      line-height: 35.28px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 23.52px;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 21px;

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    background-color: $off-grey;
    border-radius: 24px;
    min-height: 303px;
    padding: 32px;
    gap: 31px;

    @include media-breakpoint-down(md) {
      min-height: 225px;
    }

    &-two {
      padding-bottom: 0;

      .overview-tiles__item-image {
        max-width: 332px;
        object-fit: contain;
        margin-top: auto;

        @include media-breakpoint-down(md) {
          max-width: 221px;
        }
      }
    }

    &-three {
      position: relative;
      padding: 48px 40px 40px;
      background-color: $bg--secondary;
      color: $text--white;
      gap: 70px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url("/assets/img/img/overview-tiles-pattern.png");
        background-repeat: no-repeat;
        background-size: cover;

        @include rtl {
          transform: scaleX(-1);
        }
      }

      @include media-breakpoint-down(md) {
        gap: 23px;
        padding: 19px 38px 28px;
        background-position: 100%;
      }

      .overview-tiles__item-title {
        max-width: 206px;
        text-align: start;

        @include rtl {
          max-width: none;
        }

        @include media-breakpoint-down(lg) {
          text-align: center;
          max-width: none;
        }
      }

      .overview-tiles__item-image {
        object-fit: contain;
        height: 100%;
        width: 100%;

        &-container {
          height: 92px;
          width: 92px;
          min-height: 92px;
          min-width: 92px;
          border-radius: 28.58px;
          box-shadow: 0px 3.57px 15.18px 4.18px #00000033;
          background-color: $bg--white;
          padding: 19px;

          @include media-breakpoint-down(md) {
            height: 59px;
            width: 59px;
            min-height: 59px;
            min-width: 59px;
            padding: 12px;
            border-radius: 18.33px;
          }
        }
      }
    }

    &-four {
      padding-bottom: 30px;

      @include media-breakpoint-down(md) {
        padding-bottom: 27.46px;
        gap: 24px;
      }
    }

    &-content {
      gap: 8px;
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      text-align: center;

      @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 21.6px;
      }

      @include rtl {
        font-family: "Din", sans-serif;
        line-height: 35.28px;

        @include media-breakpoint-down(md) {
          font-size: 16.35px;
          line-height: 24.04px;
        }
      }
    }

    &-text {
      font-size: 20px;
      line-height: 24px;

      @include media-breakpoint-down(lg) {
        text-align: center;
      }

      @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 19.2px;
      }

      @include rtl {
        font-family: "Din", sans-serif;
        font-size: 19px;
        line-height: 27.93px;

        @include media-breakpoint-down(md) {
          font-size: 12.94px;
          line-height: 19.03px;
        }
      }
    }

    &-store {
      width: auto;
      height: 51px;
      object-fit: contain;

      @include transition;

      &:hover,
      &:focus {
        opacity: 0.7;
      }

      @include media-breakpoint-down(md) {
        height: 38px;
      }
    }

    &-top {
      width: 100%;
      gap: 24px;
      z-index: 1;

      @include media-breakpoint-down(lg) {
        flex-direction: column-reverse;
        align-items: center;
      }
    }

    &-bottom {
      gap: 24px;
      z-index: 1;

      @include media-breakpoint-down(lg) {
        justify-content: center;
      }

      @include media-breakpoint-down(md) {
        gap: 18px;
      }
    }

    &-rows {
      gap: 11px;
      width: 100%;

      @include media-breakpoint-down(md) {
        gap: 8.88px;
      }
    }

    &-row {
      box-shadow: 0px 3.57px 15.18px 4.18px #0000000d;
      padding: 13px 31px 13px 13px;
      gap: 8px;
      border-radius: 16px;
      width: 100%;
      max-width: 238px;

      @include media-breakpoint-down(md) {
        gap: 5.46px;
        max-width: 162px;
        padding: 9px 21.8px 9px 11px;
      }

      &:nth-child(2) {
        margin-left: 120px;

        @include media-breakpoint-down(md) {
          margin-left: 80px;
        }
      }

      &:nth-child(3) {
        margin-right: 90px;

        @include media-breakpoint-down(md) {
          margin-right: 60px;
        }
      }

      &-icon {
        height: 24px;
        width: 24px;

        @include media-breakpoint-down(md) {
          height: 16.39px;
          width: 16.39px;
        }
      }

      &-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 19.2px;
        color: #2c2c2e;

        @include media-breakpoint-down(md) {
          font-size: 10.93px;
          line-height: 13.12px;
        }

        @include rtl {
          font-family: "Din", sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 23.52px;

          @include media-breakpoint-down(md) {
            font-size: 10.09px;
            line-height: 16.02px;
          }
        }
      }

      &-dash {
        background-color: #e7e7e7;
        border-radius: 1000px;
        height: 13px;
        width: 100%;
        max-width: 146px;

        @include media-breakpoint-down(md) {
          max-width: 99.73px;
          height: 8.88px;
        }

        &-short {
          min-width: 66px;
          max-width: 66px;

          @include media-breakpoint-down(md) {
            min-width: 45.09px;
            max-width: 45.09px;
          }
        }
      }
    }
  }

  &__application {
    background-color: $bg--white;
    box-shadow: 0px 3.57px 15.18px 4.18px #0000000d;
    border-radius: 16px;
    padding: 14px 17px;
    width: 100%;
    max-width: 352px;
    gap: 18px;

    @include media-breakpoint-down(md) {
      gap: 11.71px;
      padding: 9.11px 11px 14.96px;
    }

    &-top {
      width: 100%;

      @include rtl {
        flex-direction: row-reverse;
      }
    }

    &-bottom {
      gap: 8px;
    }

    &-circle {
      border-radius: 50%;
      background-color: #e7e7e7;
      border: 4px solid $white;
      height: 41px;
      width: 41px;

      @include media-breakpoint-down(md) {
        height: 26.67px;
        width: 26.67px;
      }

      &-tick {
        box-shadow: 0px 3.57px 15.18px 4.18px #0000000d;
        border: none;
        background-color: $bg--white;

        @include media-breakpoint-down(md) {
          svg {
            height: 15.61px;
            width: 15.61px;
          }
        }
      }
    }

    &-dash {
      display: flex;
      background-color: #f4f4f4;
      flex: 1;
      height: 5px;
      width: 100%;
      min-width: 20px;

      @include media-breakpoint-down(md) {
        height: 3.5px;
      }
    }

    &-line {
      height: 12px;
      border-radius: 1000px;
      background-color: #e7e7e7;
      width: 110px;

      &-long.overview-tiles__application-line-long {
        width: 146px;

        @include media-breakpoint-down(md) {
          height: 8.46px;
          width: 94.98px;
        }
      }

      @include media-breakpoint-down(md) {
        height: 8.46px;
        width: 71.56px;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      color: #141414;

      @include media-breakpoint-down(md) {
        font-size: 10.41px;
        line-height: 12.49px;
      }

      @include rtl {
        font-family: "Din", sans-serif;
        line-height: 23.52px;

        @include media-breakpoint-down(md) {
          font-size: 10.9px;
          line-height: 16.02px;
        }
      }
    }
  }
}

/*=====  End of Overview Tiles  ======*/

.video-player-two {
  padding-block: 56px;
  gap: 32px;
  max-width: 993px;

  @include media-breakpoint-down(xl) {
    padding-inline: 24px;
  }

  @include media-breakpoint-down(md) {
    gap: 24px;
  }

  &__title {
    text-align: center;
    font-size: 32px;
    line-height: 38.4px;
    font-weight: 700;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 28.8px;
    }

    @include rtl {
      line-height: 47.04px;

      @include media-breakpoint-down(md) {
        font-weight: 500;
        font-size: 24px;
        line-height: 35.28px;
      }
    }
  }

  &__video {
    width: 100%;
    aspect-ratio: 2.12/1;
    border-radius: 30px;

    @include media-breakpoint-down(md) {
      aspect-ratio: 16 / 9;
      border: 1.66px solid #d0d0d0;
      border-radius: 10.6px;
    }

    &-container {
      background: linear-gradient(to bottom, #d0d0d0 80%, #f0f0f0);
      padding: 5px;
      border-radius: 32px;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        padding: 0;
        background: none;
        border-radius: 0;
      }
    }
  }
}

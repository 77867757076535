/*=============================================
=            navbar            =
=============================================*/

.navbar {
  color: $text--primary;
  position: relative;
  padding: 20px 10px;
  font-family: Lato;

  @include media-breakpoint-down(xl) {
    justify-content: space-between;
    padding-inline-start: 17px;
    padding-inline-end: 24px;
  }
  
  @include media-breakpoint-down(sm) {
    padding: 11.5px 24px 11.5px 17px;
  }

  &-wrapper {
    background-color: $bg--white;
  }

  &__breadcrumbs {
    color: $text--white;
    padding: 13.5px 6px;
    width: 100%;
    flex-wrap: wrap;

    &-wrapper {
      background-color: $bg--secondary;

      &--visible {
        display: flex;
      }
    }
  }

  &__breadcrumb {
    @include transition;

    color: rgb(155, 205, 255, 0.5);
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.5px;
    margin-inline-start: 20px;
    padding-inline-start: 20px;
    border-inline-start: 1px solid $white;
    position: relative;

    @include media-breakpoint-down(md) {
      display: none;
      padding-inline-start: 10px;
    }

    &:first-child,
    &:last-child {
      @include media-breakpoint-down(md) {
        display: inline-block;
      }
    }

    &:last-child {
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.5px;
      opacity: 1;
      color: $text--white;

      @include media-breakpoint-down(md) {
        border: none;

        &::before {
          @include media-breakpoint-down(md) {
            position: absolute;
            inset-inline-start: 0px;
            content: "|";
            font-size: 16px;
            color: rgb(155, 205, 255, 0.5);
          }
        }
      } 
    }

    &:first-child {
      border: none;
      margin-inline-start: 0;
      padding-inline-start: 0;

      @include media-breakpoint-down(md) {
        padding-inline-end: 10px;
      }

      &::before {
        @include media-breakpoint-down(md) {
          position: absolute;
          inset-inline-end: 0px;
          content: "|";
          font-size: 16px;
        }
      }

      &::after {
        @include media-breakpoint-down(md) {
          position: absolute;
          inset-inline-end: -15px;
          content: "...";
          font-size: 16px;
        }
      }
    }
  }

  &__links {
    width: 100%;
    gap: 42px;
    font-size: 15px;
    font-weight: 700;
    line-height: 62px;
    margin: 0;
    margin-inline-start: 64px;

    @include media-breakpoint-down(xl) {
      display: none;
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__inner {
    padding: 11.5px 26px;

    &-links {
      gap: 30px;
      width: 210px;
      opacity: 0;
      visibility: hidden;
      background-color: $bg--white;
      position: absolute;
      z-index: 2;
      color:$text--primary;
      padding: 30px 20px;
      padding-block-start: 15px;
      box-shadow: 5px 12px 24px -8px rgba(149,157,165,.3);

      &:focus-within{
        opacity: 1;
        visibility: visible;
      }

      @include transition;
    }

    &-link {
      font-weight: 500;
      line-height: 16px;
      width: 100%;

      @include transition;

      &:hover, &:focus {
        color: $text--secondary;
        text-shadow: 0 0 1px $text--secondary;
      }
    }
  }

  &__link {
    cursor: pointer;
    min-width: fit-content;

    &-icon {
      margin-inline-start: 5px;
    }

    &:hover .navbar__inner-links{
      opacity: 1;
      visibility: visible;
    }

    &:focus .navbar__inner-links{
      opacity: 1; 
      visibility: visible;
    }

    &-icon {
      transform: translateY(2px);
      color: $secondary;
    }
  }

  &__logo {
    height: 100%;
    width: 100%;
    min-width: fit-content;
  }

  &__left {
    height: 62px;
    width: 95px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      height: 69px;
      width: 104px;
    }
  }

  &__language {
    cursor: default;

    @include media-breakpoint-down(xl) {
      margin-inline-end: 10px;
    }

    &--desktop {
      min-width: 62px;
      color: #6B798F;
      font-size: 12px;
      margin-inline-start: auto;

      @include media-breakpoint-down(xl) {
        display: none;
      }
    }

    &--mobile {
      text-align: center;
      margin-block-start: 50px;
      background-color: #f7f9fb;
      width: 100%;
      padding: 8px 10px;

      @include media-breakpoint-up(xl) {
        display: none;
      }

      .navbar__language-dropdown {
        top: unset;
        transform: translateY(5px) translateX(-8%);
      }

      &-inner {
        display: block;
        width: 100%;
        font-family: $font-family--ar;
        font-size: 18px;
        font-weight: 400;
      }
    }

    &-selected {
      font-weight: 600;
      font-size: 13px;
      pointer-events: none;
    }

    &-icon {
      pointer-events: none;
      color: $grey;
      transform: translateY(2px);
      margin-inline-start: 3px;

      &--active {
        transform: translateY(2px) rotate(180deg);
      }
    }

    &-dropdown {
      font-size: 13px;
      gap: 15px;
      max-width: 180px;
      opacity: 0;
      visibility: hidden;
      background-color: $bg--white;
      position: absolute;
      width: max-content;
      z-index: 2;
      color:$text--primary;
      padding: 15px 12px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      padding-inline-end: 50px;
      line-height: 30px;

      li[lan-code="ar"] {
        font-family: "DIN";
      }

      li:hover {
        color: $secondary;
      }

      &--active {
        opacity: 1;
        visibility: visible;
      }
  
      &-title {
        font-weight: 700;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  &__search {
    position: relative;
    cursor: pointer;
    margin: auto;
    margin-right: 7px;

    &-label  {
      cursor: pointer;
      margin: auto;

      &--hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-icon {
      transform: translateY(3px);

      &--hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-input {
      width: 0px;
      height: 36px;
      opacity: 0;
      font-family: inherit;
      color: $text--primary;
      padding: 5px 0;
      letter-spacing: 1px;
      margin-inline: 5px;
      border-radius: 4px;
      transition: opacity .5s ease-in-out;
      
      &--active {
        position: absolute;
        z-index: 1;
        right: 0;
        width: 285px;
        transform: translateY(1px);
        opacity: 1;
        padding: 5px 10px;
        border: 1px solid #cdcdcd;

        @include media-breakpoint-down(sm) {
          width: 115px;
        }
      }
    }

    &-cross {
      display: none;
      opacity: 0;
      position: absolute;
      inset-inline-end: 10px;
      inset-block-start: 50%;
      transform: translateY(-45%);
      width: 20px;

      @include transition;

      &--active {
        display: block;
        opacity: 1;
      }
    }
  }

  &__button {
    min-width: fit-content;
    margin-inline-end: 16px;
    
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  
  &__menu-button {
    cursor: pointer;

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

#nav-bottom {
  height: 5px;
  background-color: $secondary;
  position: fixed; 
  inset-block-start: 0; 
  inset-inline-start: 0;
  inset-inline-end: 0;
  opacity: 0; 
  z-index: 999999; 
}

html[dir="rtl"] {
  .navbar {
    font-family: $font-family--ar;

    &__language {
      &--mobile {
        &-inner {
          font-family: Go;
        }
      }
    }
  }
}

/*=====  End of navbar  ======*/

/*=============================================
=            Tabbed Component            =
=============================================*/

.tabbed-calculators {
  background-color: $bg--white;

  &__tabs {
    box-sizing: border-box;
    width: 100%;

    &-inner {
      width: 100%;
      z-index: 2;
    }

    &-left {
      width: 100%;
      flex: 1;
      padding-block: 56px;
      background-color: $bg--secondary;

      @include media-breakpoint-down(lg) {
        padding-inline: 0;
        padding-block-start: 40px;
        padding-block-end: 24px;
        justify-content: center;
      }

      &-inner {
        border: 1px solid $white;
        border-radius: 6px;
        padding: 8px 7px;
        gap: 8px;
        margin-inline-end: 64px;

        @include media-breakpoint-down(lg) {
          margin-inline-end: 0;
          padding: 5px;
          width: 100%;
          max-width: 520px;
          margin-inline: 22px;
        }
      }
    }

    &-right {
      flex: 1;
      z-index: -1;

      @include media-breakpoint-down(lg) {
        display: none;
        padding: 32px 0;
        padding-inline: 26px;
      }
    }
  }

  &__label {
    cursor: pointer;
    min-width: fit-content;
    transition: background-color, border-color 0.2s ease-in-out ;

    &:focus {
      border-color: $black;
    }

    @include media-breakpoint-down(lg) {
      justify-content: center;
      align-items: center;
    }
  }

  &__radio {
    width: 0; 
    height: 0; 
    position: absolute;
    z-index: -1;
  }

  &__radio + &__label {
    margin: 0; 
    padding: 17px 55px; 
    box-sizing: border-box;
    position: relative; 
    display: inline-block;
    border-radius: 6px;
    font-size: 17px;
    font-weight: 600;
    line-height: 25px; 
    letter-spacing: 0.5px;
    border: 1px solid transparent;
    color: $text--white;

    @include media-breakpoint-down(xl) {
      padding: 17px 36px; 
    }

    @include media-breakpoint-down(lg) {
      padding: 0;
      display: flex;
      font-size: 13px;
      line-height: 25px;
      width: 100%;
      height: 40px;
    }
  }

  &__radio:hover + &__label {
    border-color: $white;    
  }

  &__radio:checked + &__label {
    background-color: $bg--white;
    color: $text--secondary;
    z-index: 1;
    font-size: 17px;
    font-weight: 600;
    line-height: 25px; 
    letter-spacing: 0.5px;
    border-color: $white;

    @include media-breakpoint-down(lg) {
      font-size: 13px;
    }
  }

  &__cards {
    display: none;
  }
}

.tabbed-calc-card {
  z-index: 2;
  top: -191px;
  margin-bottom: -191px;

  @include media-breakpoint-down(lg) {
    top: initial;
    margin-bottom: 0;
  }
}

/*=====  End of Tabbed Component  ======*/
/*=============================================
=            Accordion            =
=============================================*/

.accordion {
  padding: 0;

  &__heading {
    padding: 0;
    color: $text--secondary;
    font-size: 26px;
    font-weight: 700;
    line-height: 35px; 
    letter-spacing: 0.5px;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      font-size: 25px;
      font-weight: 500;
      line-height: 29px;
    }
  }

  &-wrapper {
    padding: 56px 24px;
    background-color: #F7F9FB;

    @include media-breakpoint-down(md) {
      padding-block: 40px;
    }

    &-tabbed {
      padding-block: 0;
    }
  }
  
  &__inner {
    gap: 24px;
  }

  &__item {
    background-color: $bg--white;

    &-inner {
      gap: 20px;
      padding-bottom: 21px;
      padding-inline: 39px 37px;
      display: none;

      
      @include media-breakpoint-down(md) {
        padding-block-end: 20px;
        padding-inline: 20px 20px;
      }

      &--active {
        display: block;
        max-width: 855px;
      }
    }

    &-top {
      width: 100%;
      font-family: 'Lato', sans-serif;
      padding: 21px 0 18px;
      padding-inline: 36px 60px;
      position: relative;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      line-height: 25px; 
      letter-spacing: 0.5px;
      color: $text--primary;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        padding: 20px 0 20px;
        padding-inline: 20px 50px;
      }

      &::after {
        content: url('/assets/img/img/chevron-down.svg');
        position: absolute;
        right: 37px;
        top: 20px;

        @include media-breakpoint-down(md) {
          right: 24px;
        }
      }

      &--active {
        padding-bottom: 10px;
        color: $text--secondary;

        @include media-breakpoint-down(md) {
          padding-bottom: 13px;
        }

        &::after {
          content: url('/assets/img/img/chevron-up-active.svg');
        }
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px; 
      letter-spacing: 0.5px;
      color: $text--primary;
    }
  }

  .rte {
    p {
      font-family: 'Lato', sans-serif;
      line-height: 22px;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

html[dir="rtl"] {
  .accordion {
    &__item {
      &-top {
        font-family: DIN;

        &::after {
          right: auto;
          left: 37px;
      
          @include media-breakpoint-down(md) {
            left: 24px;
          }
        }
      }
    }

    .rte {
      p {
        font-family: DIN;
      }
    }
  }
}

/*=====  End of Accordion  ======*/

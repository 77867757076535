/*=============================================
=            Tabbed Component            =
=============================================*/

.tabbed-component {
  background-color: #F7F9FB;
  margin: 0 auto;
  padding: 56px 0;
  display: flex;
  flex-direction: column;
  gap: 56px;
  font-family: $font-family--base;

  &__tabs {
    gap: 10px;
    box-sizing: border-box;
    border: 1px solid #E4EBF2;
    border-radius: 6px;
    width: fit-content;
    padding: 8px;
    background-color: #FFF;
  }

  &__card {
    display: none;
    flex-direction: column;
    gap: 56px;

    &-content {
      color: #273A53;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.5px;

      .container {
        margin: 0;
      }
    }
  }

  &__rhs {
    &-container {
      flex: 1;
    }

    &-title {
      color: $bg--secondary;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 0.5px;
    }

    &-image {
      width: 32px;
      height: 32px;
    }

    &-items{
      &-container {
        padding-block: 28px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 0;
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      gap: 13px;

      &-title {
        color: $bg--secondary;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.5px;
      }
  
      &-image {
        width: 32px;
        height: 32px;
      }

      &-content {
        color: #273A53;
        font-family: $font-family--base;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px; 
        letter-spacing: 0.5px;
      }
    }
  }

  &__ticklist {
    &-container {
      flex: 1;
    }

    &-title {
      color: $bg--secondary;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 0.5px;
    }

    &-bullets {
      padding-block: 28px;
      padding-bottom: 0;
      gap: 20px;
    }

    &-bullet {
      display: flex;
      flex-direction: row;
      gap: 13px;
      
      margin-block-end: 18px;

      color: #273A53;
      font-family: $font-family--base;
      font-size: 14px;
      font-style: normal;

      p {
        font-family: $font-family--base;
        font-size: 14px;
        font-style: normal;
      }
      ul {
        list-style-type: disc;

        li {
            margin-left: 20px;
            line-height: 24px;
            font-size: 13px;
        }
      }
    }
    
    &-bullet:last-child {
      padding-bottom: 0;      
    }
  }

  &__tick {
    width: 24px;
    height: 24px;
  }

  &__containers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 56px;
  }

  &__label {
    cursor: pointer;
    text-align: center;
    transition: background-color, border-color 0.2s ease-in-out;
  }

  &__radio {
    width: 0;
    height: 0;
    position: absolute;
    left: -9999px;
  }

  &__radio+&__label {
    margin: 0;
    padding: 17px 10px;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border-radius: 6px;
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.5px;
    border: 1px solid transparent;
  }

  &__radio:hover+&__label {
    border-color: $secondary;
  }

  &__radio:checked+&__label {
    background-color: $bg--secondary;
    color: #FFF;
    z-index: 1;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.5px;
    font-weight: 900;
  }
}

@include media-breakpoint-down(md) {
  .tabbed-component {
    padding: 48px 24px;
    font-size: 12px;
    gap: 48px;

    &__tabs {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    &__radio+&__label {
      width: 100%;
      text-align: center;
      padding: 10px 16px;
    }

    &__containers {
      flex-direction: column;
      gap: 32px;
    }

    &__card {
      gap: 48px;
    }

    &__ticklist, &__rhs {
      &-title {
        font-weight: 900;
      }
    }

    &__ticklist {
      &-bullets {
        padding-block-start: 24px;
      }
    }

    &__rhs {
      &-items {
        &-container {
          gap: 16px;
          padding-block-start: 24px;
        }
      }

      &-item {
        gap: 10px;

        &-title {
          font-size: 15px;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .tabbed-component {
    font-family: $font-family--ar;

    p,
    &__ticklist-bullet ul li{
      font-family: $font-family--ar;
    }
  }
}

/*=====  End of Tabbed Component  ======*/
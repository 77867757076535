/*=============================================
=            Card Grid            =
=============================================*/

.card-grid {
  max-width: 1178px;
  margin: 0 auto;
  padding: 56px 26px 41px;

  @include media-breakpoint-down(md) {
    padding: 48px 26px 59px;
  }

  &__header {
    color: $text--primary;
    font-size: 35px;
    font-weight: 700;
    line-height: 38px; 
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      font-size: 25px;
      font-weight: 600;
      line-height: 29px; 
      letter-spacing: 0.5px;
      margin-bottom: 16px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 26px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__card {
    background-color: $bg--primary;
    transition: all 0.5s ease 0s;

    &:hover {
      box-shadow: 0px 8px 16px 2px rgba(0, 0, 0, 0.15);
      transform: translateY(-3px);

      .card-grid__image {
        scale: 1.07;
      }
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;

    @include transition;

    &-container {
      overflow: hidden;
      height: 276px;

      @include media-breakpoint-down(md) {
        height: 206px;
      }
    }
  }

  &__title {
    font-size: 19px;
    font-weight: 600;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    opacity: 0.7;
    text-align: justify;
  }

  &__content {
    color: $text--white;
    padding: 28px 40px;
    display: flex;
    flex-direction: column;
    gap: 28px;

    @include media-breakpoint-down(md) {
      padding: 20.5px 37px 20px 20px;
    }
  }

  .link{
    padding-block: 0px;
    margin-inline-start: auto;
    font-weight: 500;

    .zmdi {
      transform: translateY(3px);
    }
  }
}

html[dir="rtl"] {
  .card-grid__header {
    font-size: 44px;
    font-weight: 500;
    line-height: 38px;
  }

  .card-grid .link {
    padding-block-end: 5px;
  }

  .card-grid .link .zmdi-arrow-right{
    transform: scaleX(-1) translateY(17px);
  }
}

/*=====  End of Card Grid  ======*/

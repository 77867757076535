.about-us-page {
    padding: 56px 32px;
    background-color: #f7f9fb;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @include media-breakpoint-down(sm) {
        padding: 32px 26px;
        font-size: 12px;
    }

    h2,
    h3 {
        margin-bottom: 24px !important;
        color: $text--primary;
        font-size: 26px;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0.5px;
    }

    p {
        color: $text--primary;
        font-size: 17px;
        font-weight: 500;
        line-height: 25px; 
        letter-spacing: 0.5px;
    }

    &__full-width,
    &__half-width {
        background-color: $white;
        padding: 56px 40px;

        @include media-breakpoint-down(md) {
            padding: 24px;
        }
    }

    &__full-width {
        grid-column: span 2;
    }

    &__half-width {
        grid-column: span 1;

        @include media-breakpoint-down(md) {
            grid-column: span 2;
        }
    }

    &__partners-grid {
        .logo-grid {
            display: flex;
            justify-content: center;
            gap: 70px;
            padding: 0;

            @include media-breakpoint-down(md) {
                margin-block-start: 32px;
            }

            &__image {
                max-width: 120px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
    }

    &__awards-grid {
        .logo-grid {
            grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
            gap: 50px;
            padding: 0;

            @include media-breakpoint-down(sm) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-self: center;
                flex-direction: column;
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
                gap: 40px;
            }

            &__item {
                max-width: 175px;
            }

            &__image {
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    height: 110px;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
    }
}

html[dir="rtl"] {
    .about-us-page {
        h2,
        h3 {
            font-size: 24px;
            line-height: 49px;
            margin-bottom: 8px;
        }
    
        p {
            font-size: 15px;
            font-weight: 400;
            line-height: 28px; 
        }
    }
}

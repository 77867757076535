/*=============================================
=            Features Component            =
=============================================*/

.features {
  &__inner {
    gap: 171px;
    padding-block-start: 101px;
    padding-block-end: 56px;

    @include media-breakpoint-down(xl) {
      padding-inline: 56px;
      gap: 60px;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse;
    }

    @include media-breakpoint-down(md) {
      padding-block-start: 48px;
      padding-block-end: 48px;
      padding-inline: 48px;
      gap: 32px;
    }
  }

  &__header {
    color: $text--black;
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;

    @include rtl {
      font-family: "Din", sans-serif;
      font-weight: 500;
      font-size: 45px;
      line-height: 66px;

      @include media-breakpoint-down(md) {
        text-align: center;
        font-size: 24px;
        line-height: 35px;
      }
    }

    @include media-breakpoint-down(md) {
      text-align: center;
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__left {
    min-width: 394px;

    @include media-breakpoint-down(md) {
      min-width: auto;
      width: 100%;
    }
  }

  &__right {
    gap: 40px;
  }

  &__items {
    gap: 60px;

    @include media-breakpoint-down(md) {
      gap: 24px;
    }
  }

  &__item {
    gap: 16px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &-icon {
      height: 40px;
      width: 40px;
    }

    &-text {
      font-size: 32px;
      line-height: 38.4px;
      font-weight: 300;

      @include rtl {
        font-family: "Din", sans-serif;
        font-size: 30px;
        line-height: 44px;

        @include media-breakpoint-down(md) {
          font-size: 18px;
          line-height: 26px;
        }
      }

      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  &__bullets {
    width: 100%;
  }

  &__bullet {
    width: 100%;
    gap: 46px;
    padding-top: 19px;
    padding-bottom: 19px;
    padding-inline-start: 7px;
    background: linear-gradient(to bottom, #dfe1e3 50%) no-repeat;
    background-size: 4px 100%;
    background-position: 20px 0;
    padding-inline-start: 7px;

    @include media-breakpoint-down(md) {
      gap: 32px;
      padding-inline-start: 5px;
      background-position: 17px 0;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    @include rtl {
      background-position: calc(100% - 21px) 0;

      @include media-breakpoint-down(md) {
        background-position: calc(100% - 18px) 0;
      }
    }

    &:first-of-type {
      padding-top: 0;
      background: linear-gradient(to bottom, transparent 50%, #dfe1e3 50%)
        no-repeat;
      background-size: 4px 100%;
      background-position: 20px 0;

      @include media-breakpoint-down(md) {
        background-position: 17px 0;
      }

      @include rtl {
        background-position: calc(100% - 21px) 0;

        @include media-breakpoint-down(md) {
          background-position: calc(100% - 18px) 0;
        }
      }
    }

    &:last-of-type {
      gap: 37px;
      padding-inline-start: 0;
      padding-bottom: 0;
      background: linear-gradient(to bottom, #dfe1e3 50%, transparent 50%)
        no-repeat;
      background-size: 4px 100%;
      background-position: 20px 0;

      @include media-breakpoint-down(md) {
        background-position: 17px 0;
      }

      @include rtl {
        background-position: calc(100% - 21px) 0;

        @include media-breakpoint-down(md) {
          background-position: calc(100% - 18px) 0;
        }
      }

      .features__bullet-dot-container::after {
        content: none;
      }

      .features__bullet-dot {
        background-color: $secondary;
        height: 47px;
        width: 47px;

        @include media-breakpoint-down(md) {
          height: 38px;
          width: 38px;
        }
      }
    }

    &:nth-child(1) {
      .features__box-gradient {
        width: 82px;

        @include media-breakpoint-down(md) {
          width: 42px;
        }
      }
    }

    &:nth-child(2) {
      .features__box-gradient {
        width: 122.75px;

        @include media-breakpoint-down(md) {
          width: 65px;
        }
      }
    }

    &:nth-child(3) {
      .features__box-gradient {
        width: 133px;

        @include media-breakpoint-down(md) {
          width: 79px;
        }
      }
    }

    &:nth-child(4) {
      .features__box-gradient {
        width: 162px;

        @include media-breakpoint-down(md) {
          width: 91px;
        }
      }
    }

    &-dot {
      border: 2px solid $secondary;
      background-color: $white;
      color: $secondary;
      border-radius: 50%;
      height: 31px;
      width: 31px;
      min-height: 31px;
      min-width: 31px;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;

      @include media-breakpoint-down(md) {
        height: 29px;
        width: 29px;
        min-height: 29px;
        min-width: 29px;
        font-size: 14px;
        line-height: 16.8px;
        font-weight: 500;
      }

      &-container {
        position: relative;
      }

      &-inner {
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }
    }

    &-icon {
      @include media-breakpoint-down(md) {
        height: 20px;
        width: 20px;
      }
    }
  }

  &__box {
    gap: 8px;
    width: 100%;
    padding: 19px 23px;
    border-radius: 13px;
    color: #4b5563;
    box-shadow: 0px 3.24px 14.9px 0px #00000021;

    @include media-breakpoint-down(md) {
      gap: 5px;
      padding: 8px 15px 9px;
    }

    &-text {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #2c2c2e;

      @include rtl {
        font-family: "Din", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20.58px;

        @include media-breakpoint-down(md) {
          font-size: 10px;
          line-height: 14.7px;
          font-weight: 400;
        }
      }

      @include media-breakpoint-down(md) {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
      }
    }

    &-bottom {
      gap: 7px;
    }

    &-gradient {
      border-radius: 693px;
      height: 20.81px;
      width: 120px;

      @include media-breakpoint-down(md) {
        height: 13px;
      }
    }

    &-currency {
      font-size: 13.87px;
      line-height: 11px;
      color: $slate-grey;

      @include rtl {
        font-family: "Din", sans-serif;
        font-size: 14px;
        line-height: 19.63px;

        @include media-breakpoint-down(md) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      @include media-breakpoint-down(md) {
        font-size: 8.92px;
        line-height: 7px;
      }
    }
  }
}

/*=====  End of Features Component  ======*/

/*=============================================
=            Hero            =
=============================================*/

.hero {
  position: relative;
  background-color: #F7F9FB;
  min-height: 380px;

  @include media-breakpoint-down(xl) {
    justify-content: flex-end;
    align-items: end;
    margin-block-end: 120px;
    min-height: 343px;
  }

  &__inner {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-550px,-50%);

    @include media-breakpoint-down(xl) {
      width: calc(100% - 52px);
      inset-block-start: initial;
      inset-block-end: 0%;
      transform: translate(-50%, 50%);
      z-index: $above;
    }
  }

  &__background {
    height: 380px;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
  }

  &__picture {
    width: 100vw;
  }

  &__title {
    font-size: 35px;
    font-weight: 700;
    line-height: 38px;
    color: $text--secondary;
    max-width: 573px;
    
    @include media-breakpoint-down(xl) {
      text-align: center;
      font-size: 26px;
      line-height: 32px;
    }
  }

  &__text {
    &-container {
      background-color: #ffffff;
      width: 450px;
      padding: 32px;
      padding-block-start: 36px;
      padding-block-end: 41px;

      @include media-breakpoint-down(xl) {
        max-width: 100%;
        padding: 57px 63px;
      }
    }
  }

  &__content {
    max-width: 370px;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #35475E;
    max-width: 573px;
    margin-block-start: 8px;
    
    @include media-breakpoint-down(xl) {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.5px;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
  }

  &--wide {
    height: 539px;

    @include media-breakpoint-down(sml) {
      min-height: 210px;
      height: 210px;
      margin-block-end: 148px;
    }

    .hero {
      &__title {
        color: #0070F9;
      }

      &__inner {
        @include media-breakpoint-down(xl) {
          width: calc(100% - 48px);
        }
      }

      &__background {
        height: 539px;

        @include media-breakpoint-down(sml) {
          height: 210px;
        }
      }

      &__text-container {
        width: 575px;
        padding: 56px 40px;

        @include media-breakpoint-down(sml) {
          margin: auto;
          padding: 48px 24px;
          gap: 15px;
        }
      }

      &__title {
        font-weight: 900;

        @include media-breakpoint-down(sml) {
          font-size: 17px;
          line-height: 20.4px;
        }
      }

      &__content {
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;

        @include media-breakpoint-down(sml) {
          margin-inline: auto;
          max-width: 250px;
        }
      }
    }
  }
}

html[dir='rtl'] {
  .hero {
    &__inner {
      transform: translate(550px,-50%);
  
      @include media-breakpoint-down(xl) {
        transform: translate(50%, 50%);
      }
    }
  
    &__title {
      font-family: $font-family--ar;
      font-size: 27px;
      font-weight: 700;
      line-height: 34px
    }
  
    &__content {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }

    &--wide {
      @include media-breakpoint-down(xl) {
        margin-block-end: 0px;
      }
      
      @include media-breakpoint-down(sml) {
        margin-block-end: 50px;
        min-height: 325px;
        height: 325px;
      }

      .hero {  
        &__inner {
          @include media-breakpoint-down(xl) {
            transform: translate(50%, 5%);
          }
        }

        &__text-container {
          padding-inline-end: 70px;
  
          @include media-breakpoint-down(sml) {
            gap: 8px;
            padding: 48px 24px;
          }
        }

        &__background {
          @include media-breakpoint-down(sml) {
            min-height: 325px;
            height: 325px;
          }
        }
  
        &__title {
          font-size: 35.15px;
          font-weight: 700;
          line-height: 44.18px;
  
          @include media-breakpoint-down(sml) {
            font-size: 17px;
            line-height: 25px;
          }
        }
  
        &__content {
          font-size: 17.07px;
          font-weight: 500;
          line-height: 38.37px;
  
          @include media-breakpoint-down(sml) {
            font-size: 15px;
            font-weight: 500;
            line-height: 22.05px;
            margin-block-start: 0;
          }
        }
      }
    }
  }
}
/*=====  End of Hero  ======*/

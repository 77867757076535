/*=============================================
=            Small App Download CTA            =
=============================================*/

.small-download {
  background-color: #F7F9FB;
  padding: 0px 32px;

  @include media-breakpoint-down(md) {
    padding: 0px 24px;
  }

  &__inner {
    color: $text--white;
  }

  &__container {
    background-color: #18CDB7;
    padding: 26px 37px 21px 54px;
    gap: 30px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: start;
      padding: 44px 19px 46px 24px;
    }
  }

  &__left {
    max-width: 670px;

    @include media-breakpoint-down(md) {
      max-width: none;
    }
  }

  &__right {
    align-self: flex-start;
    padding: 20px;
    border-radius: 12px;
    background-color: $bg--white;
    height: 100%;

    @include media-breakpoint-down(md) {
      background-color: #18CDB7;
      padding: 0;
    }
  }

  &__subtitle {
    color: #FFE000;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 2px;

    @include media-breakpoint-down(md) {
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 1.7px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px; 
    margin-top: 7px;
    margin-bottom: 1px;

    @include media-breakpoint-down(md) {
      font-size: 23px;
      font-weight: 400;
      line-height: 38px;
    }
  }

  &__content {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;

    @include media-breakpoint-down(md) {
      font-size: 15px;
      line-height: 22px; 
      letter-spacing: 0.5px;
    }
  }

  &__image {
    height: 180px;
    width: 180px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__stores {
    gap: 12px;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__store {
    height: 100%;
    width: 125px;
  }
}

html[dir="rtl"] {
  .small-download { 
    &__title {
      font-family: $font-family--ar;
      font-size: 38px;
      font-weight: 700;
      line-height: 38px;
      margin-block-end: 10px;
  
      @include media-breakpoint-down(md) {
        font-size: 23px;
      }
    }
  
    &__content {
      font-family: $font-family--ar;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
  
      @include media-breakpoint-down(md) {
        line-height: 21px; 
      }
    }
  }
}

/*=====  End of App Download CTA  ======*/

/*=============================================
=            Shareholder Logo Grid            =
=============================================*/

.shareholder-logo-grid {
    margin-bottom: 56px;
    padding: 56px 40px;
    background-color: $white;

    &__header {
        h2,
        h3 {
            margin-bottom: 24px;
            color: $text--primary;
            font-size: 26px;
            font-weight: 700;
            line-height: 35px;
            letter-spacing: 0.5px;
        }

        p {
            color: $text--primary;
            font-size: 17px;
            font-weight: 500;
            line-height: 25px; 
            letter-spacing: 0.5px;
        }
    }

    &__grid {
        display: flex;
        justify-content: center;
        gap: 70px;
        padding: 0;

        @include media-breakpoint-down(md) {
            margin-block-start: 32px;
        }

        &__image {
            max-width: 120px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
  }

  html[dir="rtl"] {
    .shareholder-logo-grid__header {
        font-family: $font-family--ar;
        line-height: 33px;

        h2,
        h3 {
            font-size: 24px;
            line-height: 49px;
            margin-bottom: 8px;
        }
    
        p {
            font-size: 15px;
            font-weight: 400;
            line-height: 28px; 
        }
    }
}
  
  /*=====  End of Shareholder Logo Grid  ======*/
  
#embedContainer {
    max-width: 1440px !important;
    margin: 0 auto !important;
    width: 100% !important;
    padding: 60px !important;
}

// Layers
$base: 0;
$above: 1; // use this for all values above the base
$below: -1; // and this for all values below the base

// Page Layout
$zOverlay: $above + $base;
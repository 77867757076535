@include media-breakpoint-down(sm) {
  .hidden--sm-down {
    display: none !important;
  }
}

@include media-breakpoint-down(md) {
  .hidden--md-down {
    display: none !important;
  }
}

@include media-breakpoint-up(lg) {
  .hidden--lg-up {
    display: none !important;
  }
}

// .sr-only {
//   @include sr-only();
// }

.hidden-element {
  display: none;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

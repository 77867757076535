*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: currentColor;
  border: none;
  background: none;

  &:hover {
    text-decoration: none;
  }
}

ul,
ol {
  list-style: none;
}

address {
  font-style: normal;
}

a {
  text-decoration: none;
  opacity: 1;

  @include transition;

  &:hover,
  &:focus {
    color: currentColor;
    opacity: 1;
  }
}

img {
  display: block;
  width: 100%;

  &.inline {
    width: unset;
  }
}

.finance-component {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin: 0 auto;
    max-width: 1181px;
    padding-block: 56px;

    &__left, &__right {
        flex: 1;
        min-width: 0;
    }

    &__right {
        .personal-calc, .home-calc {
            &__inner {
                display: flex;
                flex-direction: column-reverse;
                background-color: transparent;
            }

            &__right {
                &-inner {
                    width: 100%;
                }
            }

            &__left {
                &-inner {
                    width: 100%;
                    max-width: none;
                    padding: 56px 40px;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .finance-component {
        &__right {
            .personal-calc, .home-calc {
                &__payment {
                    &-containers {
                        width: 100%;
                    }
                }

                &__right {
                    padding-block: 0px;

                    &-inner {
                        max-width: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .finance-component {
        flex-direction: column;
        gap: 0;
        padding-block: 0;
        padding-block-end: 56px;

        &__left, &__right {
            flex: none;
            width: 100%;
        }

        &__right {
            .personal-calc, .home-calc {
                &__inner {
                    flex-direction: column-reverse;
                }

                &__right {
                    margin-block: 0px;

                    &-inner {
                        width: 100%;
                    }
                }

                &__left {
                    &-inner {
                        width: 100%;
                        max-width: none;
                        padding-inline: 20px;
                    }
                }
            }
        }
    }
}
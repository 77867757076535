/*=============================================
=            Mobile Menu            =
=============================================*/

.menu-mobile {
  &__icon {
    cursor: pointer;
  }

  &__inner {
    &-li {
      text-decoration: underline;
      
      &-title{
        cursor: none;
        text-decoration: none;
        font-weight: 600;
      }
    }
  }

  &__overlay {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: -3000px;
    background-color: $white;
    background-color: rgba(0, 0, 0, 0.15);

    @include transition;

    &--active {
      opacity: 1;
      visibility: visible;
      right: 0;
      top: 100%;
      width: 100%;
      height: 100vh;
      z-index: 99999;
    }
  }

  &__column {
    &-trigger {
      cursor: pointer;
      list-style: none;
      position: relative;

      summary::-webkit-details-marker {
        display: none;
      }
      
      &::after {
          content: '';
          color: $secondary;
          border-right: 1px solid;
          border-bottom: 1px solid;
          position: absolute;
          inset-inline-end: -16px;
          inset-block-start: 23px;
          height: 8px;
          width: 8px;
          transform: rotate(45deg) translatex(-2px) translatey(0px);
      }
    }
  }

  &__container {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__menu {
    overflow: auto;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $white;
    margin-left: auto;
    padding: 40px 42px;
    gap: 16px;

    &--divider {
      width: $container-width--wide;
      height: 1px;
      background: rgba(38, 25, 27, 0.1);
      background: $bg--white;
    }
  }

  &__main {
    gap: 5px;
    letter-spacing: .5px;

    details[open] > summary::after {
      transform: rotate(225deg);
    }
    
    &-link {
      position: relative;
      width: fit-content;
      color: $text--primary;
      font-family: 'go';
      font-size: 15px;
      font-weight: 600;
      line-height: 53px;
      cursor: pointer;
      
      &-alt {
        &::after {
          content: '';
        }
      }
    }

    &-li {
      padding-inline-start: 5px;
      font-family: 'go';
      text-underline-offset: 2px;
      text-decoration-thickness: .5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 46px;
      cursor: pointer;
    }

    .zmdi-chevron-down {
      &:before {
        margin-inline-start: 8px;
        color: $secondary;
        font-size: 20px;
      }
    }

    .zmdi-chevron-left {
      &:before {
        margin-inline-end: 5px;
        color: $secondary;
      }
    }
  }

    &__column {
      gap: 5px;

      &-li {
        padding: 10px 0;
      }

      &-link {
        color: $text--primary;
        display: inline-block;
        width: $container-width--wide;
      }
    }

    &__icon {
      width: 24px;
      height: 20px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;

      &-bar {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: black;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }

      &-bar:nth-child(1) {
        top: 0px;
        width: 60%;
        right: 0;
        left: auto;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      
      &-rt:nth-child(1) {
        top: 0px;
        width: 60%;
        right: auto;
        left: 0;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &-bar:nth-child(2) {
        top: 9px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &-bar:nth-child(3) {
        top: 18px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &--open .menu-mobile__icon-bar:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 1px;
        left: 8px;
        right: auto;
        width: 100%;
        height: 2px;
        background-color: $bg--secondary;
      }

      &--open .menu-mobile__icon-bar:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &--open .menu-mobile__icon-bar:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 18px;
        left: 8px;
        height: 2px;
        background-color: $bg--secondary;
      }
    }
}

html[dir="rtl"]{
  .menu-mobile__main .zmdi-chevron-left {
    transform: rotate(180deg);
    &:before {
      margin-inline-start: 5px;
      margin-inline-end: initial;
    }
  }

  .menu-mobile__menu-back-icon {
    transform: scale(-1);
  }

  .menu-mobile__inner-li,
  .menu-mobile__main-link {
    font-family: $font-family--ar;
  }
}

/*=====  End of Mobile Menu  ======*/

/*=============================================
=            Feature List            =
=============================================*/

.feature-list {
  max-width: 1150px;

  &__inner {
    margin-inline: 24px;
    color: $text--white;
  
    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-block-end: 0;
    }
  }

  &__left {
    background-color: $bg--white;
    padding-block: 56px;
    padding-inline: 40px 98px;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding: 48px 24px;
    }
  }

  &__right {
    width: 100%;
    background-color: $bg--secondary;
    padding: 56px 40px;
    text-align: center;

    @include media-breakpoint-down(md) {
      padding: 40px 20px;
    }

    &-image {
      width: 131px;
      height: 131px;
      margin-block-end: 40px;
    }

    &-title {
      max-width: 360px;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      margin-block-end: 17px;
      
      @include media-breakpoint-down(md) {
        max-width: 265px;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
      }
    }

    &-content {
      max-width: 430px;
      font-size: 18px;
      font-weight: 900;
      line-height: 27px;
      margin-block-end: 40px;
    }
  
    &-button-title {
      max-width: 400px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-block: 32px 24px;
  
      @include media-breakpoint-down(md) {
        max-width: 270px;
        text-align: center;
      }
    }
  }

  &__ticks {
    &-container {
      gap: 32px;
    }

    &-tick {
      width: 24px;
      height: 24px;
    }

    &-title {
      color: $bg--secondary;
      font-size: 26px;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 0.5px;
    }

    &-bullets {
      gap: 24px;

      @include media-breakpoint-down(md) {
        padding-inline-end: 10px;
      }
    }

    &-bullet {
      gap: 10px;
      color: #273A53;
      font-size: 14px;
    }

    &-bullet:last-child {
      padding-bottom: 40px;

      @include media-breakpoint-down(md) {
        padding-bottom: 48px;
      }
    }
  }

  &__icons {
    &-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 28px;
    }

    &-title {
      color: $bg--secondary;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 0.5px;
    }

    &-text {
      position: relative;
      top: -3px;
    }

    &-icon {
      width: 22px;
      height: 100%;
    }

    &-items {
      &-container {
        display: flex;
        flex-direction: column;
        gap: 28px;
        padding-bottom: 0;

        @include media-breakpoint-down(md) {
          padding-inline-end: 20px;
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      gap: 10px;

      &-title {
        color: $bg--secondary;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.5px;
      }

      &-content {
        color: #273A53;
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;
      }
    }
  }

  .rte {
    position: relative;
    inset-block-start: -3px;
  }
}

html[dir='rtl'] {
  .feature-list {
    &__icons {
      &-item {
        padding-inline-end: 85px;

        &:nth-child(2) {
          padding-inline-end: 120px;
        }

        @include media-breakpoint-down(md) {
          padding-inline-end: 40px;
        }

        &-title {
          margin-block-end: 8px;
        }
      }

      &-items-container {
        gap: 5px;
      }

      &-icon {
        position: relative;
        top: -3px;
      }
    }
  }
}

/*=====  End of Feature List  ======*/

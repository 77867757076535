/*=============================================
=            Logo Grid            =
=============================================*/

.logo-grid {
  max-width: 1178px;
  margin: 0 auto;
  padding: 56px 26px;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 37px;

  @include media-breakpoint-down(md) {
    padding: 48px 26px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr))
  }

  &__image {
    width: fit-content;
    object-fit: contain;
    vertical-align: middle;
    margin: auto;
  }
}

/*=====  End of Logo Grid  ======*/

.product-inquiry {
    background-color: $bg--off-grey;
    gap: 22px;

    &__wrapper {
        padding-block: 57px 32px;
    }

    &__heading {
        color: $text--secondary;
        font-size: 26px;
        font-weight: 900;
        line-height: 35px;
        letter-spacing: 0.5px;

        @include media-breakpoint-down(xl) {
            padding-inline: 16px;
        }

        @include media-breakpoint-down(md) {
            font-size: 25px;
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0.5px;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: contain;

        &-container {
            width: 200px;
            margin: auto;
        }
    }

    &__icon {
        height: 100%;
        width: 100%;
        object-fit: contain;

        &-container {
            width: 148px;
            margin: 0 auto;
            margin-block-end: 25px;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        background-color: white;
        padding-block: 48px 24px;
        padding-inline: 224px 216px;
        gap: 38px;

        @include media-breakpoint-down(lg) {
            padding-inline: 16px;
        }
    }

    &__top {
        gap: 32px;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(10px);
        z-index: 20000;
        padding-block-start: 168px;
        display: none;

        &--active {
            display: flex;
        }

        .rte {
            margin-block-end: 32px;
            max-width: 535px;
            text-align: center;
        }
    }

    &__submit {
        &-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 28.8px;
            text-align: center;
            margin-block-end: 16px;
            max-width: 535px;
        }
    }

    &__close {
        position: absolute;
        top: 32px;
        right: 33px;
        font-size: 34px;
        color: #808082;
        cursor: pointer;

        @include transition;

        &:hover,
        &:focus {
            opacity: 0.7;
        }
    }

    //Umbraco Forms
    .umbraco-forms-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .umbraco-forms-navigation {
        display: flex;
        justify-content: flex-end;
    }

    input[type='text'],
    input[type='tel'],
    input[type='email'],
    select,
    textarea {
        color: $text--slate-grey;
        border-radius: 10px;
        border: 2px solid #ebedef;
        padding: 14px 20px;
        padding-top: 33px;
        width: 100%;
        font-family: $font-family--base;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        resize: none;

        &::-ms-input-placeholder {
            color: $text--slate-grey;
        }

        &::placeholder {
            color: $text--slate-grey;
        }
    }

    select {
        position: relative;
        padding-inline-end: 50px;
        -webkit-appearance: none;
        background-image: url('/assets/img/img/chevron-down-active.svg');
        background-position-x: calc($container-width--wide - 20px);
        background-position-y: 50%;
        background-repeat: no-repeat;
        background-size: 24px;
        z-index: 9999;
        cursor: pointer;
    }

    input[type='submit'] {
        padding: 19px 62px;
        background-color: $bg--secondary;
        color: $text--white;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
        margin-top: 24px;

        @include transition;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        &:hover,
        &:focus {
            background-color: $bg--primary;
        }
    }

    label {
        color: $text--slate-grey;
        font-family: $font-family--base;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        position: absolute;
        inset-block-start: 14px;
        inset-inline-start: 20px;
    }

    .dataconsent label {
        position: relative;
        top: unset;
        left: unset;
    }

    .form-group {
        position: relative;
    }

    .field-validation-valid {
        color: #e54e4e;
        margin-top: 11px;
        display: block;

        &:empty {
            margin-top: 0;
        }
    }

    .form-control.input-validation-error {
        border-color: #e54e4e;
    }
}

html[dir='rtl'] {
    .product-inquiry {
        ::placeholder {
            font-family: $font-family--ar;
        }

        input[type='text'],
        input[type='tel'],
        input[type='email'],
        select,
        textarea {
            font-family: $font-family--ar;
            font-size: 14px;
        }

        input[type='submit'] {
            font-family: $font-family--ar;
            font-weight: 700;
            line-height: 22px;
        }

        select {
            background-position-x: 20px;
        }
    }
}

/*=============================================
=            Whistleblower Component            =
=============================================*/

.whistleblower {
  background-color: transparent;

  & ::placeholder {
    color: #808082 !important;
  }

  &__right {
    flex: 1;
    position: relative;
    padding-block: 12px;
  }

  &__overlay {
    z-index: 99999;
    position: absolute;
    padding: 50px;
    inset-inline-start: 0;
    inset-block-start: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;

    &-cross {
      width: 20px;
      position: absolute;
      inset-inline-end: 35px;
      inset-block-start: 25px;
      cursor:pointer;
    }

    &-image {
      width: 150px;
    }

    &-title {
      color: #35475E;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }

    &-text {
      color: $primary;
      max-width: 450px;
      text-align: center;
      font-family: Neue Haas Grotesk Display Pro;
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.5px;
    }
  }

  &__top {
    padding-block: 56px;
    padding-inline: 26px;
    gap: 32px;
    margin: 0 auto;
    max-width: 1176px;

    @include media-breakpoint-down(md) {
      padding: 32px 26px 56px;
    }
  }

  &__content {
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
      max-width: 80%;
      gap: 20px;
      margin-inline-start: 0;
      margin-block: 28px;
    }

    ul li {
      color: #273A53;
      list-style: none;
      padding-inline-start: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin: auto 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0.5px;
      border-inline-start: 3px solid $secondary;
    }

    h2 {
      font-size: 26px;
      font-weight: 700;
      line-height: 35px; 
      letter-spacing: 0.5px;
      margin-bottom: 28px;
      color: $text--secondary;
    }

    h4 {
      color: $text--secondary;
      font-size: 20px;
      font-weight: 500;
      line-height: 25px; 
      letter-spacing: 0.5px;
    }

    p {
      font-family: $font-family--base;
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.5px;
    }
  }

  &__bottom{
    background-color: $bg--white;
  }

  &__inner {
    background-color: $bg--white;
    flex-direction: row-reverse;
    gap: 94px;
    padding-block: 56px;
    padding-inline: 30px;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
      padding-block: 54px;
      gap: 54px;
    }
  }

  &__heading {
    font-size: 50px;
    font-weight: 500;
    line-height: 59px; 
    color: $text--secondary;
    margin-bottom: 70px;

    @include media-breakpoint-down(md) {
      font-size: 36px;
      font-weight: 500;
      line-height: 35px; 
      letter-spacing: 0.5px;
      margin-bottom: 39px;
    }
  }

  &__left {
    flex: 1;
    padding-inline-start: 40px;
    max-width: 400px;

    @include media-breakpoint-down(md) {
      padding-inline: 32px;
    }
  }

  &__link {
    color: $text--primary;

    @include transition;

    &:hover, &:focus {
      color: $text--secondary;
    }

    &-icon {
      height: 36px;
      width: 36px;
      margin-inline-end: 13px;
      margin-block-start: 5px;
    }

    &-number {
      font-family: Lato;
      color: $text--secondary;
      font-size: 36px;
      font-weight: 700;
      letter-spacing: 2px;
    }

    &-text {
      margin-block-start: 6px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: .5px;
    }
  }

  // umbraco form styles
  .umbraco-forms-container {
    display: flex;
    flex-direction: column;
    gap: 28px;

    .radiobuttonlist {
      margin-block-start: 16px;
      display: flex;
      gap: 5px;
    }

    .singlechoice label{
      color: $text--black;
    }

    .shouldsharecontactinfo,
    .multiplechoice{
      inset-block-start: 10px;
    }

    .shouldsharecontactinfo label,
    .multiplechoice label,
    .singlechoice label {
      inset-block-start: unset;
      inset-inline-start: unset;
      position: relative;
    }    
  }

  .umbraco-forms-page {
    flex:1;
    padding-inline: 32px;
    background-color: $bg--white;

    @include media-breakpoint-down(md) {
      margin-inline-end: 0;
    }
  }

  label {    
    color: #808082;
    font-family: $font-family--base;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    position: absolute;
    inset-block-start: 14px;
    inset-inline-start: 20px;
  }

  .form-group {
    position: relative;

    &__title {
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      color: $text--primary;
      margin-bottom: 16px;
    }
  }

  .form-group-radio {
    label {
      position: static;
      margin-inline-start: 7px;
      margin-inline-end: 15px;
    }

    input[type="radio"] {
      cursor: pointer;
    }

    .umbraco-forms-field-wrapper {
      display: inline;
    }
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    border-radius: 12px;
    border: 1px solid #CFCFCF;
    padding: 14px 20px;
    padding-top: 33px;
    width: 100%;
    color: #808082;
    font-family: $font-family--base;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    resize: none;
  }

  input[type="submit"] {
    padding: 18px 54px;
    background-color: $bg--secondary;
    color: $text--white;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
    margin-top: 24px;

    @include transition;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:hover, &:focus {
      background-color: $bg--primary;
    }
  }

  select {
    position: relative;
    -webkit-appearance: none;
    background-image: url('/assets/img/img/chevron-down-active.svg');
    background-position-x: calc($container-width--wide - 20px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 24px;
    z-index: 9999;
    cursor: pointer;
  }

  ::placeholder { 
    color: #000;
    font-family: $font-family--base;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  :-ms-input-placeholder {
    color: #000;
    font-family: $font-family--base;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  ::-ms-input-placeholder { 
    color: #000;
    font-family: $font-family--base;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .field-validation-error
  {
    color: #E54E4E;
      margin-top: 11px;
      display: block;
  }

  .form-control.input-validation-error
    {
        border-color: #E54E4E;
    }
  
}

html[dir="rtl"] {
  .whistleblower {
    &__link-icon {
      margin-inline-end: 30px;
      transform: scaleX(-1);
    }
  
    &__content ul li {
      padding-block-start: 0;
      padding-block-end: 16px;
    }

    &__content p {
      font-family: $font-family--ar;
    }
  
    &__left {
      flex: none;
      max-width: 360px;
    }

    .umbraco-forms-bootstrap3-horizontal {
      width: 100%;
    }

    input[type="text"],
    input[type="number"],
    input[type="email"], 
    input[type="tel"],
    select, 
    textarea {
      font-family: $font-family--ar;
      font-size: 14px;
    }

    ::placeholder {
      font-family: $font-family--ar;
      font-size: 14px;
    }

    input[type="text"] {
      font-weight: 700;
    }
    
    input[type="submit"] {
      font-family: $font-family--ar;
      font-weight: 700;
      line-height: 22px;
    }
  }

  select {
    font-family: $font-family--ar;
    position: relative;
    -webkit-appearance: none;
    background-image: url('/assets/img/img/chevron-down-active.svg');
    background-position-x: 20px;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 24px;
    z-index: 9999;
    cursor: pointer;
  }
}

/*=====  End of Whistleblower Component  ======*/

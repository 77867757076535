/*=============================================
=            Prompt Tiles            =
=============================================*/

.prompt-tiles {
  padding-block: 56px;
  gap: 64px;

  @include media-breakpoint-down(xl) {
    padding-inline: 26px;
  }

  @include media-breakpoint-down(md) {
    gap: 24px;
  }

  &__header {
    color: $text--black;
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: center;

    @include rtl {
      font-family: "Din", sans-serif;
      line-height: 70.56px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 35.28px;
      }
    }

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 28.8px;
    }
  }

  &__tiles {
    gap: 24px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__tile {
    flex: 1;
    border-radius: 32px;
    padding: 56px 29px;
    background-color: $bg--white;
    gap: 30px;
    align-self: normal;

    @include media-breakpoint-down(md) {
      gap: 12px;
      padding: 37px 29px;
    }

    &-icon {
      height: 60px;
      width: 60px;

      @include media-breakpoint-down(md) {
        height: 46px;
        width: 46px;
      }
    }

    &-title {
      font-size: 24px;
      line-height: 28.8px;
      text-align: center;

      @include rtl {
        font-family: "Din", sans-serif;
        line-height: 35px;

        @include media-breakpoint-down(md) {
          line-height: 23px;
        }
      }

      @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

/*=====  End of Prompt Tiles  ======*/

.video-player {
    width: 100%;
    aspect-ratio: 2.35 / 1;

    @include media-breakpoint-down(md) {
        aspect-ratio: 16 / 9;
        margin-block-end: 87px;
    }
    
    &__container {
        max-width: 1150px;
        padding-inline: 24px;

        .plain-text {
            padding-block-end: 40px;

            @include media-breakpoint-down(md) {
                padding-inline: 10px;
            }

            a {
                color: #0070F9;
            }

            h2.big-h2 {
                @include media-breakpoint-down(md) {
                    font-size: 24px;
                }
            }

            h2.mb-8 {
                @include media-breakpoint-down(md) {
                    margin-bottom: 16px;
                }
            }
        }
    }
}

html[dir='rtl'] {
    .video-player__container {
        .plain-text {
            p {
                font-size: 17px;
                font-weight: 500;
                line-height: 24.2px;
            }

            h2.big-h2 {
                font-size: 43.27px;
                font-weight: 700;
                line-height: 57px;
                margin-block-end: 10px;

                @include media-breakpoint-down(md) {
                    font-size: 24px;
                    line-height: 38px;
                }
            }
        }
    }
}

@mixin transition($property: all, $duration: .2s, $timing-function: ease, $delay: 0s) {
    -webkit-transition: $property $duration $timing-function $delay;
    -moz-transition: $property $duration $timing-function $delay;
    -o-transition: $property $duration $timing-function $delay;
    transition: $property $duration $timing-function $delay;
}

.fade-in {
    animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.fade-out {
    animation: fadeOut 1s;
}

@keyframes fadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}

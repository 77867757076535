/*=============================================
=            App Download CTA            =
=============================================*/

.download-cta {
  background: linear-gradient(180deg, transparent 19%, rgba(24,205,183,1) 16.5%);
  color:$text--white;
  margin-top: 13px;
  margin-bottom: 110px;
  height: 598px;

  @include media-breakpoint-down(md) {
    height: auto;
    margin-bottom: 0;
    background: linear-gradient(180deg, transparent 27.1%, rgba(24,205,183,1) 27.1%);
  }

  &__inner {
    padding: 0 26px;
    margin: 0 auto;
    width: 100%;
    gap: 40px;

  @include media-breakpoint-down(lg) {
    gap: 32px;
    min-height: 565px;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  }

  &__left {
    position: relative;
    height: 600px;

    @include media-breakpoint-down(lg) {
      height: 565px;
    }

    @include media-breakpoint-down(sm) {
      transform: translateY(-47px);
    }
  }

  &__image {
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 2s, transform 2s ease-out;
    height: 100%;
    width: 100%;
    height: 600px;
    object-fit: contain;

    @include media-breakpoint-down(lg) {
      height: 565px;
      min-width: 282px;
    }

    &--visible {
      opacity: 1;
      transform: translateY(65px);
    }
  }

  &__right {
    padding-block-start: 41px;
    padding-block-end: 36px;
    max-width: 520px;
    margin-top: 113px;

    @include media-breakpoint-down(md) {
      padding-block-start: 0px;
      padding-block-end: 26px;
      margin-top: 0px;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px; 
    letter-spacing: 2px;
    color: #FFE000;
    margin-bottom: 5px;
  }

  &__title {
    max-width: 440px;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px; 
    margin-bottom: 17px;

    @include media-breakpoint-down(lg) {
      font-size: 36px;
      line-height: 41px; 
    }
  }

  &__text {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 27px ;
  }

  &__code {
    height: 100%;
    width: 100%;
    object-fit: contain;
    vertical-align: middle;

    &-container {
      border-radius: 24px;
      background-color: $bg--white;
      overflow: hidden;
      width: 163px;
      height: 155px;
      padding: 11px;

      @include media-breakpoint-down(md) {
        display: none; 
      }
    }
  }

  &__stores {
    flex-wrap: wrap;
    gap: 15px;
    padding-inline: 8px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__store {
    height: 100%;
    width: 145px;
  }
}

html[dir="rtl"] {
  .download-cta {
    &__text {
      line-height: 24px;
    }
  }
}

/*=====  End of App Download CTA  ======*/

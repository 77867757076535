.plain-text-page {
    padding: 56px 32px;
    background-color: #f7f9fb;

    @include media-breakpoint-down(sm) {
        padding: 32px 26px;
        font-size: 12px;
    }

    &__link {
        width: 262px;
        gap: 10px;
        padding: 30px 20px;
        background-color: $white;
        cursor: pointer;
        transition: transform 0.5s ease;

        @include media-breakpoint-down(sml) {
            width: 100%;
        }

        &-container {
            margin-block-start: 24px;
            gap: 28px;
        }

        &-title {
            max-width: 181px;
            color: #273a53;
            font-family: $font-family--base;
            font-size: 16px;
            font-weight: 600;
            line-height: 23px;
            letter-spacing: 0.5px;
            text-transform: capitalize;

            @include media-breakpoint-down(sm) {
                font-size: 15px;
                line-height: 20px;
            }
        }

        &-icon {
            width: 24px;
            height: 26px;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    .plain-text.container {
        padding: 0;
    }
}

html[dir="rtl"] {
    .plain-text-page {
        &__link-title {
            font-family: $font-family--ar;
        }
    }
}
/*=============================================
=            App Download CTA            =
=============================================*/

.cta-with-text {
  max-width: 1150px;
  margin-block-end: 56px;

  @include media-breakpoint-down(sml) {
    flex-direction: column-reverse;
    position: relative;
    inset-block-start: -42px;
    margin-block-end: 0px;
  }

  &__top {
    padding-block-start: 56px;
    padding-inline-end: 145px;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: $blue;
    margin-inline: 24px;

    @include media-breakpoint-down(md) {
      padding-block: 48px 8px;
      padding-inline-end: 20px;
      margin-block-end: 24px;
    }

    @include media-breakpoint-down(sm) {
      margin-block-end: 0px;
    }

    @include media-breakpoint-down(sml) {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
  }

  &__bottom {
    margin-block-start: 40px;
    background-color: $bg--green;
    padding: 27px 40px;
    margin-inline: 24px;
    gap: 24px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 48px 28px;
      margin-block-start: 0px;
    }

    @include media-breakpoint-down(sml) {
      max-width: 450px;
    }
  }

  &__title {
    max-width: 450px;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: $text--white;

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}

html[dir='rtl'] {
  .cta-with-text{
    &__top {
      font-weight: 400;
      padding-inline-end: 40px;
    }

    &__title {
      font-weight: 500;
      max-width: 502px;
    }
  }
}

/*=====  End of App Download CTA  ======*/

/*=============================================
=            button            =
=============================================*/

.button {
  padding: 14px 20px;
  cursor: pointer;
  padding: 14px 20px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px; 
  gap: 8px;

  @include transition; 

  &--underline .button__label{
    text-decoration: underline;
  }

  &--primary {
    background-color: $bg--primary;
    color: $text--white;

    &:hover, &:focus {
      background-color: #F26101;
    }
  }

  &--secondary {
    background-color: $bg--secondary;
    color: $text--white;

    &:hover, &:focus {
      background-color: #F26101;
    }
  }

  &--white {
    display: block;
    font-family: $font-family--base;
    background-color: $bg--white;
    width: fit-content;
    color: $text--secondary;
    padding: 17px 76px;
    border-radius: 12px;
    font-size: 17px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-align: center;

    &:hover, &:focus {
      background-color: $bg--primary;
      color: $text--white;
    }
  }

  &--outline {
    background-color: $bg--white;
    color: $text--primary;
    border: 2px solid $primary;

    &:hover, &:focus {
      color: $text--secondary;
      border-color: $secondary;
    }
  }
}

html[dir='rtl'] {
  .button--white {
    font-family: $font-family--ar;
    font-weight: 500;
    line-height: 24px;
  }
}

/*=====  End of button  ======*/

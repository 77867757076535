/*=============================================
=            Team Component            =
=============================================*/

.team {
  background-color: #F7F9FB;
  padding-inline: 26px;

  &__inner {
    padding-block: 56px;
    gap: 56px;
    margin: 0 auto;
    max-width: 1176px;
  }

  &__header {
    gap: 24px;
  }

  &__heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 35px; 
    letter-spacing: 0.5px;
    color: $text--secondary;

    @include media-breakpoint-down(md) {
      font-size: 25px;
      line-height: 29px;
    }
  }
  
  &__text {
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: $text--primary;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px; 
    }
  }
  
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    grid-auto-flow: dense;
    gap: 26px;
    transition: all 0.3s ease;
    row-gap: 56px;
    
    &--2 {
      grid-template-columns: repeat(2, 1fr);

      .team__card--active {
        grid-column: span 2;
      }
    }

    &--3 {
      grid-template-columns: repeat(3, 1fr);

      .team__card--active {
        grid-column: span 3;
      }
    }

    &--4 {
      grid-template-columns: repeat(4, 1fr);

      .team__card--active {
        grid-column: span 4;
      }
    }

    &--5 {
      grid-template-columns: repeat(5, 1fr);

      .team__card--active {
        grid-column: span 5;
      }
    }

    &--6 {
      grid-template-columns: repeat(6, 1fr);

      .team__card--active {
        grid-column: span 6;
      }
    }

    @media only screen and (max-width: 645px) {
      padding-inline: 25px;
    }

    @include media-breakpoint-down(sml) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include transition;
  }

  &__close {
    display: none;
    position: absolute;
    inset-block-start: 38px;
    inset-inline-end: 36px;
    cursor: pointer;

    @media only screen and (max-width: 645px) {
      inset-block-start: 20px;
      inset-inline-end: 22px;
    }

    &:hover, &:focus {
      .team__close-icon {
        color: $text--primary;
      }
    }

    &-icon {
      font-size: 30px;
      color: #808082;

      @include transition;
    }
  }

  &__card {
    overflow: hidden;
    gap: 13px;
    position: relative;
    cursor: pointer;

    &--active {
      background-color: $bg--white;
      padding: 56px 40px;
      grid-column: span 3;
      cursor: default;

      @media only screen and (max-width: 945px) {
        padding: 0;
        grid-column: span 2;
      }

      @media only screen and (max-width: 645px) {
        grid-column: span 1;
      }

      .team__info, .team__link {
        display: flex;
        visibility: visible;
        opacity: 1;
      }

      .team__content-wrapper {
        padding: 30px 25px;
        padding-inline-end: 67px;

        @include media-breakpoint-down(lg) {
          flex:1;
          padding: 30px;
        }
      }

      .team__image-container {
        width: 448px;
        min-width: 448px;
        height: auto;

        @include media-breakpoint-down(lg) {
          width: 100%;
          min-width: 248px;
          flex:1;
        }
      }

      .team__close {
        display: block;
      }

      .team__title {
        font-size: 26px;
        font-weight: 500;
        line-height: 30px; 
        letter-spacing: 0.5px;
        margin-block-start: 0;

        @include media-breakpoint-down(md) {
          font-size: 25px;
          line-height: 29px;
        }
      }

      .team__subtitle {
        margin-block-start: 8px;
        margin-block-end: 16px;
      }


      .team__info {
        font-size: 17px;
        color: $text--primary;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;

        @include media-breakpoint-down(md) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .team__card-inner {
        flex-direction: row;
        
        @include media-breakpoint-down(md) {
          flex-direction: column;
        }
      }
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;

    &-container {
      min-height: 255px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFF;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 23px; 
    letter-spacing: 0.5px;
    color: $text--primary;
    margin-top: 12px;

    @include media-breakpoint-down(md) {
      color: $text--secondary;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px; 
      letter-spacing: 0.5px;
    }
  }

  &__subtitle {
    color: #85939E;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.5px;

    @include media-breakpoint-down(md) {
      color: $text--black;
      font-weight: 500;
      letter-spacing: 0.5px;
      height: auto;
      margin-bottom: 18px;
    }
  }

  &__info, &__link {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  &__link {
    font-size: 14px;
    font-weight: 500;
    line-height: 25px; 
    letter-spacing: 0.5px;
    padding: 12px 30px;
    gap: 11px;
    border-radius: 60px;
    border: 1px solid #DBDBDB;
    min-width: max-content;
    margin-top: 12px;

    @include transition;

    &:hover, &:focus {
      border-color: $text--secondary;
    }

    @include media-breakpoint-down(md) {
      min-width: 100%;
    }

    &-icon {
      height: 24px;
      width: 24px;
      display: inline;
    }
  }
}

html[dir="rtl"] {
  .team {
    &__text {
      font-size: 15px;
      font-weight: 400;
      line-height: 28px; 
    }

    &__card--active {
      .team {
        &__title {
          font-size: 24px;
          font-weight: 500;
          line-height: 34px;
        }

        &__subtitle,
        &__info {
          font-size: 17px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0.5px;
        }

        &__subtitle {
          margin-block-start: 16px;
        }

        &__info {
          font-weight: 400;
        }
      }
    }
  }
}

/*=====  End of Team Component  ======*/

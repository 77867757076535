@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/go');

@font-face {
  font-family: "Din";
  src: url('/assets/fonts/DIN-NEXT-ARABIC-LIGHT.otf') format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "Din";
  src: url('/assets/fonts/DIN-NEXT-ARABIC-REGULAR.otf') format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "Din";
  src: url('/assets/fonts/DIN-NEXT-ARABIC-BOLD.otf') format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-Black.ttf') format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-BlackItalic.ttf') format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-Bold.ttf') format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-BoldItalic.ttf') format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-Light.ttf') format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-LightItalic.ttf') format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-Regular.ttf') format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-Italic.ttf') format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-Thin.ttf') format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
}

@font-face {
  font-family: "Lato";
  src: url('/assets/fonts/Lato-ThinItalic.ttf') format("truetype");
  font-display: auto;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
}
.rte {

    &--inline {
        margin: 0;
        max-width: none;
    }

    &--white {
        color: $text--white;
    }

    &__title {
        margin-bottom: 10px;
    }

    &__subtitle {        
        margin-bottom: 56px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        display: block;
        margin-bottom: 18px;
    }

    p,
    ol,
    ul {
        display: block;
    }

    p {
        @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 22px;
          }
    }

    ul,
    ol,
    li {
        margin-inline-start: 10px;
    }

    ul,
    ol {
        margin-top: 10px;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    strong {
        font-weight: 700;
    }

    a.underline {
        text-decoration: underline;
    }
}

/*=====  End of RTE  ======*/

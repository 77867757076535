/*=============================================
=            Feature CTA            =
=============================================*/

.feature-cta {
  position: relative;
  padding: 120px 0 0;

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 0 48px;
  }

  &__left {
    width: 54.9%;

    @include media-breakpoint-down(lg) {
      width: 100%;
      height: 332px;
    }
  }

  &__image {
    height: 100%;
    max-height: 430px;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
  }

  &__right {
    color: $text--white;
    background-color: $bg--secondary;
    width: 51.45%;
    position: absolute;
    top: 0;
    right: 0;
    padding: 56px 40px;

    @include media-breakpoint-down(lg) {
      position: static;
      width: 100%;
      padding: 40px 26px;
    }
  }

  &__left, &__right {
    transition: transform 0.1s;
  }

  &__inner {
    max-width: 536px;
    gap: 30px;
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px; 

    @include media-breakpoint-down(lg) {
      font-size: 32px;
      font-weight: 700;
      line-height: 39px; 
    }
  }

  &__content {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin-block-end: 56px;
    text-align: justify;
  }
}

html[dir="rtl"]{
  .feature-cta {
    &__right {
      right: auto;
      left: 0;
    }
  
    &__title {
      font-size: 36px;
      font-weight: 500;
      line-height: 49px; 
    }
  
    &__content {
      line-height: 24px; 
    }
  }
}

/*=====  End of Feature CTA  ======*/

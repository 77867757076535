/*=============================================
=            Feature Hero            =
=============================================*/

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.feature-hero {
  color: $text--white;

  &__wrapper {
    position: relative;
  }

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  &__inner {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
  }

  &__item {
    height: 530px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-down(md) {
      position: relative;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__content {
    height: 100%;
    padding: 30px 0;

    @include media-breakpoint-down(xl){
      padding: 41px 0 0;
      padding-inline: 21px;
    }

    @include media-breakpoint-down(lg){
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    &-full {
      width: 100%;

      picture {
        width: 100%;
      }

      img {
        object-fit: cover;
        height: 538px;

        @include media-breakpoint-down(md){
          height: 650px;
        }
      }
    }
  }

  @include media-breakpoint-down(lg){
    padding: 0;
  }

  &__image {
    height: 538px;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;

    @include media-breakpoint-down(md){
      height: 550px;
    }
  }

  //  glide styles
  &__arrow {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    color: #CAD2DA;
    border: 1px solid #CAD2DA;
    z-index: 99;
    font-size: 25px;
    cursor: pointer;

    @include transition;

    &:hover, &:focus {
      color: $text--grey;
      border-color: $text--grey;
    }

    &-icon {
      transform: translateY(1px);
    }

    &-left {
      position: absolute;
      left: 77px;

    @include media-breakpoint-down(desktop) {
      left: 5px;
    }
    }

    &-right {
      position: absolute;
      right: 77px;

    @include media-breakpoint-down(desktop) {
      right: 5px;
    }
    }
  }

  &__controls {
    position: absolute !important;
    top: 50%;
    left: 0;
    width: 100%;

    @include media-breakpoint-down(desktop) {
      display: none;
    }
  }

  &__slides {
    white-space: normal !important;
  }

  .glide {
    position: static;
  }

  .glide__bullets {
    margin: 20px auto 41px;
    justify-self: flex-end;
    position: absolute !important;
    bottom: 0;
    left: 50% !important;
    transform: translateX(-50%);
    z-index: 200;

    @include media-breakpoint-down(xl) {
      bottom: 20px;
    }

    @include media-breakpoint-down(md) {
      position: absolute;
      left: 50%;
      bottom: 26px;
      margin-bottom: 0;
    }

    @include media-breakpoint-down(xs) {
      left: 10px;
    }

    .glide__bullet {
      width: 19px;
      height: 4px;
      border-radius: 10px;
      opacity: 0.8;
      margin-right: 6px;
      background: #d9d9d9;
      transform: matrix(1, 0, 0, -1, 0, 0);
      cursor: pointer;

      @include transition;

      &--active {
        width: 33px;
        background-color: #6E7C87;
      }
    }
  }
}



/*=====  End of Feature Hero  ======*/

/*=============================================
=            Feature Personal Calculator            =
=============================================*/

.personal-calc {
  gap: 28px;

  @include media-breakpoint-down(lg) {
    gap: 24px;
  }

  &__inner {
    margin: 0 auto;
    background-color: $bg--secondary;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      padding: 0;
    }
  }

  &__left {
    flex: 1;
    background-color: $bg--secondary;
    color:$text--white;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
      justify-content: center;
    }

      &-inner {
      padding-inline: 0 81px;
      max-width: 560px;

      @include media-breakpoint-down(lg) {
        padding-inline: 26px;
      }
    }
  }

  &__right {
    flex: 1;
    color:$text--primary;
    position: relative;
    background-color: $bg--secondary;

    @include media-breakpoint-down(lg) {
      padding: 32px 0;
      padding-inline: 0px;
      background-color: #f7f9fb;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-block: 20px;
      top: 0px !important;
    }

    &-inner {
      padding: 56px 40px;
      width: fit-content;
      background-color: #FFF;
      height: calc(100% + 185px);
      display: flex;
      flex-direction: column;
      gap: 56px;

      @include media-breakpoint-down(lg) {
        padding-inline: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        margin: auto;
        max-width: 400px;
        top: 0px !important;
        margin-bottom: 0px !important;
        padding-inline: 20px;
        gap: 36px;
      }
    }
  }

  &__title {
    font-size: 29px;
    font-weight: 700;
    line-height: 32px; 
    font-family: 'Lato', sans-serif;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      font-size: 31px;
      font-weight: 600;
      line-height: 34px;
    }

    &-label {
      margin-inline-end: auto;

      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }
    }
  }
  
  &__property-value, &__salary-value {
    max-width: 100px;
  }

  &__payment-amount {
    align-items: center;
    gap: 5.85px;
  }

  &__min-value,
  &__max-value {
      display: flex;
      gap: 7.22px;
      align-items: center;

      i {
          margin-top: 3px;
      }
  }

  &__number {
    width: 125px;
    font-family: inherit;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: end;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    margin-inline-end: 5px;
    border: 2px solid transparent;
    border-radius: 8px;
    transform: translateY(-2px);
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      font-size: 15px;
    }

    @include transition;

    &:hover, &:focus {
      border-color: #18CDB7;
    }

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button { 
      -webkit-appearance: none;
    }
  }

  &__text {
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px; 
    margin-block: 56px;
    opacity: 0.7;
    text-align: justify;

    @include media-breakpoint-down(xl) {
      font-size: 12px;
      margin-top: 24px;
      margin-bottom: 18px;
    }
  }

  &__field {
    width: 100%;

    &-inner {
      margin-top: 10px;
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 30px; 
      font-family: Lato;

      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }
    }
  }

  &__input[type="range"]{
    width: 100%;
    cursor: pointer;
  }

  &__input[type="radio"]{
    cursor: pointer;
  }

  &__label {
    margin-inline-end: 32px;

    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }

  &__range {
    &-top, &-bottom {
      width: 100%;
    }

    &-top {
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px; 

      @include media-breakpoint-down(lg) {
        font-size: 15px;
      }
    }

    &-bottom {
      font-size: 14px;
      font-weight: 500;
      line-height: 30px; 
      opacity: 0.6;

      @include media-breakpoint-down(lg) {
        font-size: 13px;
        line-height: 38px; 
      }
    }
  }

  input[type='range'] {
    background: linear-gradient(to right, #18CDB7 0%, #18CDB7 50%, #1B82E9 50%, #1B82E9 100%);
    border-radius: 8px;
    height: 6px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    position: relative;
    
    @include transition;

    &:focus {
      border-color: $white;
    }
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: pointer;
    background: #fff;
    background-clip: content-box !important;
    // padding: 2px !important; //leaves a blue line after thumb
    background-image: url("/assets/img/img/pin.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include media-breakpoint-down(xl) {
      width: 32px;
      height: 32px;
    }
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + .personal-calc__label {
      &:before {
        content: '';
        background: #0070DF;
        border-radius: 100%;
        border: 2px solid #fff;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-inline-end: 8px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
        opacity: 0.65;
      }
      
      cursor: pointer;
      opacity: 0.65;
    }
    
    &:checked {
      + .personal-calc__label {
        &:before {
          background-color: #fff;
          box-shadow: inset 0 0 0 3px #0070DF;
          opacity: 1;
        }
        opacity: 1;
      }
    }
    
    &:focus {
      + .personal-calc__label {
        &:before {
          border-color: #18CDB7;
        }
      }
    }
  }

  &__circle {
    height: 100%;
    width: 100%;
    object-fit: contain;
    vertical-align: middle;
    border: 17px solid #F2F7FB;
    border-radius: 50%;
    animation: spin 30s linear infinite;

    &-container {
      height: 385px;
      width: 385px;
      position: relative;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-down(lg) {
        height: 100%;
        width: 100%;
        max-width: 335px;
        margin: auto;
      }

      @include media-breakpoint-down(sm) {
        height: 275px;
        width: 275px;
      }
    }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  &__payment {
    display: flex;
    flex-direction: column;
    gap: 17px;

    &-containers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: 500px;

      @include media-breakpoint-down(xl) {
        min-width: 0;
        gap: 34px;
      }
    }

    &-upper {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: flex-end;

      @include media-breakpoint-down(lg) {
        align-items: center;
      }
    }

    &-lower {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;

      @include media-breakpoint-down(sm) {
        gap: 0;
      }
    }

    &-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 250px;

      @include media-breakpoint-down(lg) {
        gap: 5px;
        padding-block-end: 15px;
      }
    }

    &-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 40px; 

      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }
    }

    &-number {
      font-size: 49px;
      font-weight: 700;
      line-height: 70px;
      font-family: Neue Haas Grotesk Display Pro;
      color: $text--secondary;

      @include media-breakpoint-down(lg) {
        font-size: 45px;
        line-height: 36px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 47px;
        line-height: 36px;
      }
    }
    
    &-currency {
      font-size: 36px;
      font-weight: 500;
      line-height: 70px;
      color: $text--secondary;
      font-family: Neue Haas Grotesk Display Pro;
      margin-top: 4px;

      @include media-breakpoint-down(lg) {
        font-size: 20px;
        line-height: 35px;
      }
    }

    &-text, &-terms {
      max-width: 475px;

      @include media-breakpoint-down(lg) {
        margin: 0;
        max-width: none;
      }
    }

    &-text {
      line-height: 20px; 
      font-family: 'Lato', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    &-terms {
      color: $text--black;
      line-height: 30px; 
      font-size: 12px;
      font-weight: 500;
      color: $text--black;
      opacity: 0.3;
      margin-top: -50px;

      @include media-breakpoint-down(lg) {
        margin-top: -25px;
      }
    }

    &-max {
      margin-top: 56px;
      margin-bottom: 8px;
    }

    &-max-icon, &-rate-icon {
      height: 33px;
      width: 33px;
      margin-inline-end: 3px;

      @include media-breakpoint-down(lg) {
        height: 29px;
        width: 26px;
      }
    }

    &-max-amount, &-rate-amount {
      color: #273A53;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Neue Haas Grotesk Display Pro;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; 

      @include media-breakpoint-down(lg) {
        font-size: 30px;
      }
    }

    &-max-text, &-rate-text {
      color: #273A53;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $font-family--base;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; 

      @include media-breakpoint-down(lg) {
        font-size: 12px;
        font-weight: 700;
      }
    }

    &-symbol {
      color: #273A53;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Neue Haas Grotesk Display Pro;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      
      &:has(.icon-saudi_riyal){
        margin-bottom: 4px;
      }

      .icon-saudi_riyal {

        &::after {
          height: 28.12px;
          width: 24.27px;
          filter: brightness(0) saturate(100%) invert(17%) sepia(12%) saturate(2337%) hue-rotate(174deg) brightness(103%) contrast(86%);
        }
      }

      @include media-breakpoint-down(lg) {
        font-size: 16px;
        margin-top: 8px;
      }
    }
  }

  &__error {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px; 
    color: #6B798F;

    &-icon {
    width: 34px;
    height: 33px;
    }

    &-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      backdrop-filter: blur(10px);
      display: none;

      &--active {
        display: flex;
      }
    }

    &-container {
      gap: 8px;
      border: 1.5px solid #FBBC04;
      background-color: $bg--white;
      padding: 14px 35px 19px;
      border-radius: 12px;
      width: 500px;
      margin: 0 30px;
    }
  }
}

html[dir="rtl"] {
  .personal-calc {
    gap: 10px;

    &__title {
      @include media-breakpoint-down(lg) {
        font-size: 29px;
        margin-bottom: 40px;
      }
    }

    &__property-value, &__salary-value {
      &-label {
          margin-top: 8px;
      }
    }

    &__field-title,
    &__title-label,
    &__range-bottom,
    &__range-top,
    &__number {
      @include media-breakpoint-down(lg) {
        font-size: 17px;
      }
    }

    &__number {
      transform: translateY(2px);
    }

    &__title,
    &__title-label,
    &__label,
    &__field-title,
    &__range-top,
    &__text {
      font-family: $font-family--ar;
      line-height: 38px;
    }
  
    &__field-inner {
      margin-top: 0px;
    }
  
    &__text {
      font-size: 16px;
      line-height: 19px;

      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }
    }

    &__payment-max-amount,
    &__payment-rate-amount {
      font-size: 38px;
      line-height: 40px;
      font-weight: 700;

      @include media-breakpoint-down(sm) {
        font-size: 26px;
      }
    }

    &__payment-symbol {
      font-family: DIN;
      font-size: 24px;
      line-height: 38px;
    }
  
    &__payment-max-text,
    &__payment-rate-text {
      color: #273A53;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      font-family: DIN;
    }

    &__payment-currency {
      font-family: $font-family--ar;
      font-size: 24px;
      font-weight: 400;
    }

    &__payment-text {
      font-family: $font-family--ar;
      font-size: 13px;
    }

    &__payment-terms {
      font-size: 14px;
    }
  
    &__wrapper {
      background: linear-gradient(270deg, $bg--secondary 50%, transparent 50%);
      
      @include media-breakpoint-down(lg) {
        background: linear-gradient(180deg, $bg--secondary 50%, transparent 50%);
      }
    }

    &__payment {
      &-container {
        gap: 1px;
      }

      &-containers {
        min-width: 0px;
        gap: 42px;
      }

      @include media-breakpoint-up(sm) {
        width: 167px;
      }
    }

    &__salary-value-label,
    &__min-value,
    &__max-value {
      i {
        &::after{
          margin-bottom: 0px;
        }
      }
    }
  }

  input[type=radio]+.personal-calc__label:before {
    top: 10px;
  }

  input[type='range'] {
    background: linear-gradient(to left, #18CDB7 0%, #18CDB7 50%, #1B82E9 50%, #1B82E9 100%);
  }
}

/*=====  End of Feature Personal Calculator  ======*/

/*=============================================
=            Banner Component            =
=============================================*/

.banner {
  padding-block: 56px;

  @include media-breakpoint-down(xl) {
    padding-inline: 24px;
  }

  &__inner {
    gap: 24px;
    padding: 53px 60px 53px 77px;
    border-radius: 24px;
    background: linear-gradient(85.7deg, #421e84 8.9%, #0070f9 136.34%);
    color: $text--white;

    @include media-breakpoint-down(md) {
      justify-content: center;
      padding: 36px 22px 29px;
    }
  }

  &__right {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__left {
    gap: 53px;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      width: 100%;
      flex-direction: column;
      gap: 26px;
    }
  }

  &__content {
    gap: 32px;
    margin-top: auto;
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    @include media-breakpoint-down(md) {
      text-align: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 28.8px;
      max-width: 279px;
    }

    @include rtl {
      font-family: "Din", sans-serif;
      line-height: 58.8px;

      @include media-breakpoint-down(lg) {
        font-size: 21.75px;
        line-height: 31.98px;
      }
    }
  }

  &__image {
    object-fit: contain;
    height: 100%;
    width: 100%;

    &-container {
      height: 188.01px;
      width: 188.01px;
      min-height: 188.01px;
      min-width: 188.01px;
      border-radius: 44.41px;
      box-shadow: 0px 7.3px 31.03px 8.54px #00000033;
      background-color: $bg--white;
      padding: 38px;

      @include media-breakpoint-down(md) {
        height: 89px;
        width: 89px;
        min-height: 89px;
        min-width: 89px;
        padding: 18px;
        border-radius: 23px;
      }
    }
  }

  &__qr {
    height: 194px;
    width: 194px;
    object-fit: contain;
    aspect-ratio: 1/1;

    &-container {
      padding: 14px;
      border-radius: 24px;
      background-color: $bg--white;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  &__stores {
    gap: 24px;

    @include media-breakpoint-down(md) {
      justify-content: center;
      gap: 11px;
    }
  }

  &__store {
    height: 51px;
    min-width: fit-content;

    @include transition;

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    @include media-breakpoint-down(md) {
      height: 45px;
    }
  }
}

/*=====  End of Banner Component  ======*/
